<template>
    <div>
        <div class="container rounded checkout shadow-lg" style="max-width: 460px; background-color: #fff; padding-top: 20px;">
            <div class="text-center p-4" style="background: #7ec313; border-top-left-radius: 8px; border-top-right-radius: 8px;">
                <p class="h4 text-white m-0 font-weight-bold">Add Card Detail</p>
            </div>
            <b-card class="rounded-0 shadow-sm">
                    <div v-if="errors.length" class="alert alert-danger">
                        <ul>
                            <li v-for="(error, index) in errors" :key="index">
                                {{ error }}
                            </li>
                        </ul>
                    </div>
                    <b-form class="p-3" @submit.prevent="onSubmit">
                        <div class="form-group mb-3">
                            <label class="font-weight-bold text-dark" for="card-number">Card Number</label>
                            <div class="form-control" id="card-number"></div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-md-6 form-group mb-3">
                                <label class="font-weight-bold text-dark" for="card-expiry">Card Expiry</label>
                                <div class="form-control" id="card-expiry"></div>
                            </div>
                            <div class="col-xs-12 col-md-6 form-group mb-3">
                                <label class="font-weight-bold text-dark" for="card-cvc">Card CVC</label>
                                <div class="form-control" id="card-cvc"></div>
                            </div>
                        </div>
                        <ValidationProvider
                            name="name_of_card"
                            rules="required"
                            v-slot="{ passed, failed }"
                        >
                            <b-form-group
                                :invalid-feedback="'Name of card is required'"
                                :state="(failed ? false : (passed ? true : null))"
                                id="input-group-1"
                                label="Name on Card"
                                label-for="input-card"
                                label-class="font-weight-bold text-dark"
                                >
                                <b-form-input
                                    id="input-card"
                                    class="rounded-100 form-control"
                                    type="text"
                                    v-model="name_of_card"
                                    placeholder="Enter Name on Card"
                                    :state="(failed ? false : (passed ? true : null))"
                                />
                            </b-form-group>
                        </ValidationProvider>


                        <div class="d-flex justify-content-between mt-4">
                            <b-button
                                type="submit"
                                variant="primary"
                                class="rounded btn-block font-weight-bold"
                                :disabled="loading"
                            >
                                <span v-if="loading" class="fa fa-circle-o-notch fa-spin mr-2"></span>
                                <i v-else class="fa fa-credit-card mr-2"></i>
                                Purchase Plan!
                            </b-button>
                        </div>
                        <div>
                            <b-button
                                type="button"
                                variant="secondary"
                                class="rounded btn-block font-weight-bold "
                                @click = "goBackToSubscription"
                                :disabled="loading"
                            >
                                <i class="fa fa-arrow-left mr-2"></i>
                                Back
                            </b-button>
                        </div>
                    </b-form>
            </b-card>
        </div>
        <div>
            <b-modal
                id="confirm-modal"
                title="Confirmation"
                @ok="confirmCheckout"
                ok-title="Yes, Subscribe"
                cancel-title="Cancel"
                centered
                size="lg"
                class="text-center"
                body-class="p-4"
            >
                <div class="modal-body">
                    <p class="lead">
                        Are you sure that you want to purchase the <strong>{{ selectedPlanName }}</strong> plan?
                    </p>
                </div>


                <template #modal-footer>
                    <b-button variant="outline-secondary" @click="$bvModal.hide('confirm-modal')" size="lg">
                        Cancel
                    </b-button>
                    <b-button variant="success" @click="confirmCheckout" size="lg">
                        Yes, Purchase
                    </b-button>
                </template>
            </b-modal>
        </div>

    </div>
</template>

<script>
import { db } from '@/firebase';
import { from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {mapState} from 'vuex'
import firebase from 'firebase';




export default {
    computed: {
        stripeElements () {
            return this.$stripe.elements();
        },
        ...mapState(['user'])
    },


    data() {
        return {
            cardNumber: null,
            cardExpiry: null,
            name_of_card: null,
            errors: [],
            selectedPlan: null,
            loading: false,
            selectedPlanName: ''
        }
    },

    watch: {
        errors(newErrors) {
            if(newErrors.length > 0){
                setTimeout(() => {
                    this.errors = [];
                },5000);
            }
        }
    },

    mounted() {
        const vm = this;
        vm.name_of_card = `${vm.user.about.first} ${vm.user.about.last}`;
        vm.load();
    }, 

   
   
    methods: {
        async load(){
            const vm = this;
            // if(vm.user.subscription !== null);
            vm.loadScript('https://js.stripe.com/v3/').then(vm.onStripeLoaded).catch(vm.onScriptError);
            await vm.getSubscriptionDetails();
        },

        async goBackToSubscription(){
            this.$router.replace('/subscriptions')
        },

        loadScript (src) {
            return new Promise(function (resolve, reject) {
                if (document.querySelector('script[src="' + src + '"]')) {
                    resolve();
                    return;
                }
                const el = document.createElement('script')
                el.type = 'text/javascript'
                el.async = true
                el.src = src
                el.addEventListener('load', resolve)
                el.addEventListener('error', reject)
                el.addEventListener('abort', reject)
                document.head.appendChild(el)
            })
        },  

        async getSubscriptionDetails (){
            const vm = this;
            await from(db.collection('subscription_list').doc(vm.$route.params.id).get())
            .pipe(
                catchError((error) => {
                    console.error("Error fetching subscription details", error);
                    return [];
                })
            ).subscribe((data) => {
                vm.selectedPlan = data.data();
                vm.selectedPlanName = data.data().name;
            })
        },

        async onSubmit(){
            const vm = this;
            vm.errors = [];
            vm.loading=true;
            if (!vm.name_of_card.trim() || !vm.cardNumber){
                vm.errors.push("Please fill in all required fields.");
                vm.loading = false;
                return;
            }

            try{
                const result = await vm.$stripe.createToken(vm.cardNumber, {
                    name: vm.name_of_card,
                    email: vm.email,
                })

                vm.loading=false;
                if (result.token) {
                    vm.$bvModal.show('confirm-modal');
                } else if (result.error) {
                    vm.errors.push(result.error.message || "An error occurred while processing your payment.");
                }
            } catch (error){
                vm.handleError(error);
                vm.loading = false;
            } 
        },


        handleError(error) {
            const vm = this;
            if (error.response) {
                vm.errors.push("Payment processing failed. Please check your internet connection and try again.");
            } else if (error.message) {
                vm.errors.push(error.message);
            } else {
                vm.errors.push("An unexpected error occurred. Please try again later.");
            }

            console.error("Error during payment process:", error);
        },

        async confirmCheckout() {
            const vm = this;
            vm.errors = [];
            vm.$bvModal.hide('confirm-modal');
            vm.loading = true;

            try {
                const result = await vm.$stripe.createToken(vm.cardNumber, {
                name: vm.name_of_card,
                email: vm.email,
                });

                if (result.token) {
                await vm.saveToken(result.token); // Proceed with saving the token
                } else if (result.error) {
                vm.errors.push(result.error.message || "An error occurred while processing your payment.");
                }
            } catch (error) {
                vm.handleError(error);
            } finally {
                vm.loading = false;
            }
        },

        async saveToken(token){
            const  vm = this;
            vm.loading=true;

            const data = {
                stripeToken:token.id,
                type:token.type,
                userID:vm.user.uid,
                card:{...token.card}
            };
           
            try {
                const { uid } = vm.user;
                const { default_price } = vm.selectedPlan;

                const errorDocRef = db.collection('subscriptionCreationErrors').doc(uid);
                const subscriptionRef = db.collection("user_subscriptions").doc(uid);
                
                const errorSnapshot = await errorDocRef.get();
                if (errorSnapshot.exists) {
                    await errorDocRef.delete();
                }

                await Promise.all([
                    db.collection('user_accounts').add(data), 
                    (vm.user.subscription?.stripeSubscription?.status === "active")
                        ? subscriptionRef.update({
                            stripePriceID: default_price,
                            stripeToken: token.id,
                            updated: true,
                            emailSent: firebase.firestore.FieldValue.delete()
                        })
                        : subscriptionRef.set({
                            stripePriceID: default_price,
                            stripeToken: token.id,
                        }),
                ]);

                await vm.sleep(8000)
            
                const errorFound = await this.checkForError(uid);
                 
                if (errorFound) {
                            vm.errors.push(errorFound); 
                            vm.loading = false;
                            setTimeout(async () => {
                                const confirmSnapshot = await errorDocRef.get();
                                if (confirmSnapshot.exists) {
                                    await errorDocRef.delete();
                                }
                            }, 5000);
                        } else {
                            setTimeout(() => {
                                vm.loading = false;
                                window.location.href = '/';
                            }, 5000);
                }
            } catch (err) {
                vm.loading=false;
                console.error('Error adding user account:', err.message || err); 
                vm.$notify({
                    title: err,
                    message: 'Failed to process your request. Please try again later.',
                    type: 'error',
                    position: 'bottom right'
                });
            } finally {
                // vm.loading = false;
            }
        },

        async checkForError(uid) {
                const checkSnapshot = await db.collection('subscriptionCreationErrors').doc(uid).get();
                if (checkSnapshot.exists) {
                    const errorData = checkSnapshot.data();
                    if (errorData && errorData.message) {
                        return errorData.message;
                    }
                }
                return null;
        },

        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },

        onStripeLoaded () {
            const vm = this;
            const baseStyle = {
                color: 'black',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            };

            const invalidStyle = {
                color: '#fa755a',
                iconColor: '#fa755a',
            };

            // Combine base style and invalid style
            const style = {
                base: baseStyle,
                invalid: invalidStyle,
            };

            const createAndMountElement = (elementType, elementId, options = {}) => {
                try {
                    const element = vm.stripeElements.create(elementType, { style, ...options });
                    element.mount(elementId);
                    return element;
                } catch (error) {
                    console.error(`Error creating ${elementType} element:`, error);
                }
            };

            // Create and mount card elements
            vm.cardNumber = createAndMountElement('cardNumber', '#card-number', { showIcon: true, placeholder: 'Card Number', autocomplete: 'off' });
            vm.cardExpiry = createAndMountElement('cardExpiry', '#card-expiry');
            vm.cardCvc = createAndMountElement('cardCvc', '#card-cvc');
        },

    }, 
 
    beforeDestroy(){
        const vm = this;
        vm.cardNumber.destroy();
        vm.cardExpiry.destroy();
        vm.cardCvc.destroy();
    }
}

</script>

<style scoped>
/* #custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}
.StripeElement--focus{
    border-color: #7ec313;
}
.checkoutHeader{
    width: 150px;
    padding: 10px; border-radius: 15px; background: #333;
} */

.b-form-input {
  background-color: #f8f9fa;
  border-radius: 8px;
  font-size: 1rem;
}

.container {
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.checkout .b-button {
  border-radius: 0.375rem;
  font-weight: bold;
  padding: 12px;
  background-color: #7ec313;
  color: white;
  border: none;
  transition: all 0.3s ease;
}

.checkout .b-button:hover {
  background-color: #6ab00f;
}

.checkout .alert-danger{
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
}

.checkout .alert-danger ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.checkout .alert-danger li {
  list-style-type: disc;
}

.checkout .StripeElement--valid {
  border-color: #28a745;
  background-color: #e8f5e9;
}

.checkout input.is-invalid,
.checkout .StripeElement--invalid {
  border-color: #dc3545;
}

.checkout .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
}

.checkout .btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}

.Button .Button--pay, .Button .Button--save{
    display: none !important;
}

</style>
