<template>
    <div class="container">
        <div class="row">
            <div class="col-12 pb-2">
                <div class="bg-white rounded pr-2 pr-md-4 pl-2 pl-md-4 pt-2 pb-2">
                    <div><small class="display-inline mr-2">Filters:</small></div>
                    <div class="d-block d-md-flex productionContract-filters">
                        <Multiselect v-model="selectedProductionContractCategoryType" track-by="id" label="name" placeholder="Category" :options="productionContractFilters.category" :searchable="true" :allow-empty="true"  />
                        <Multiselect v-model="selectedProductionContractType" track-by="id" label="name" placeholder="Product" :options="productionContractFilters.commodity" :searchable="true" :allow-empty="true" class="ml-0 ml-md-2" />
                        <Multiselect v-model="selectedProductionContractSubType" track-by="id" label="name" placeholder="Type" :options="productionContractFilters.subtype" :searchable="true" :allow-empty="true"  class="ml-0 ml-md-2" />
                        <Multiselect v-model="selectedProductionContractSpecification" track-by="id" label="name" placeholder="Specification" :options="productionContractFilters.specification" :searchable="true" :allow-empty="true"  class="ml-0 ml-md-2" />
                        <Multiselect v-if="user.role.admin" v-model="selectedStatus" track-by="id" label="name" placeholder="Active" :options="[{id:'yes','name':'Yes'},{id:'no','name':'No'}]" :searchable="true" :allow-empty="true" class="ml-0 ml-md-2" />
                        <b-button @click="clearFilters" :disabled="!hasFilters" variant="primary" class="mt-2 mt-md-0 ml-0 ml-md-2">CLEAR</b-button>
                    </div>
                </div>
            </div>

            <div class="col-12 pb-2">
                <div class="bg-white rounded d-md-flex pl-2 pt-2 pb-1">
                    <div class="col-md-7">
                        <div class="">
                            <div class="filterOption">
                                <b-form-group v-slot="{ ariaDescribedby }">
                                    <b-form-radio-group
                                        id="btn-radios-1"
                                        v-model="filterOption"
                                        :options="filterOptions"
                                        :aria-describedby="ariaDescribedby"
                                        @change="changeFilterOption"
                                        name="radios-btn-default"
                                        buttons
                                    ></b-form-radio-group>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row" v-if="!filterOption">
                            <div class="col-md-6 pb-2">
                                <gmap-autocomplete 
                                    @place_changed="setStartPlace"
                                    placeholder="Please enter the pickup point"
                                    :enable-geolocation="true"
                                    :value="startPlace"
                                    id="toAddress"
                                    :required="true"
                                    :select-first-on-enter="true"
                                >
                                </gmap-autocomplete>
                            </div>
                            <div class="col-md-6">
                                <gmap-autocomplete @place_changed="setDestinationPlace"
                                    placeholder="Please enter the destination point"
                                    id="fromAddress"
                                    :value="destinationPlace"
                                    :enable-geolocation="true"
                                    :required="true"
                                    :select-first-on-enter="true"
                                >
                                </gmap-autocomplete>
                            </div>
                            <div class="invalid-feedback d-block pl-3" v-if="positionFailed">Please select the location pickup point and destination point.</div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-6 pb-2">
                                <gmap-autocomplete @place_changed="setPlaceSpecificLocation"
                                    placeholder="Enter location"
                                    id="locationAddress"
                                    :value="specificPlace"
                                    :enable-geolocation="true"
                                    :required="true"
                                    :select-first-on-enter="true"
                                ></gmap-autocomplete>
                                <!-- <b-button variant="info" @click="getCurrentLocation" title="Current Location"><i class="fa fa-location-arrow"></i></b-button>         -->
                            </div>
                            <div class="invalid-feedback d-block pl-3" v-if="positionFailed">Please select the location</div>
                        </div>
                        <div class="pt-2 d-flex flex-column">
                            <div class="mt-1 mb-2"> 
                                <ValidationProvider
                                    name="searchRadiusMiles"
                                    :rules="{required:true, decimal:0,minimum:10, maximum:maximumLimitOfMiles}"
                                    v-slot="{ passed, failed, errors }"
                                >
                                    <b-form-group
                                        :state="(failed?false:(passed?true:null))"  
                                        id="input-group-1"
                                        label="Range with search miles:"
                                        label-for="input-card" 
                                        label-class="font-weight-bold text-dark"
                                    >
                                        <b-form-input v-model.number="searchRadiusMiles" id="searchMilesRange" min="0" max="100" placeholder="Enter miles" :state="(failed?false:(passed?true:null))" style="max-width:100px;" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                                    {{ errors[0]=='Minimum'?'Enter minimum 10 miles': errors[0]=='Maximum'?'Enter maximum ' + maximumLimitOfMiles + ' miles':'Enter valid miles'}}
                                    </b-form-invalid-feedback>
                                </ValidationProvider>
                                <div class="invalid-feedback d-block pl-3" v-if="maximumLimitOfMilesFailed">Enter valid miles</div>
                            </div>
                            <!-- <label for="range-1">Range with min and max search miles</label> -->
                        </div>
                    </div>
                    <div class="col-md-5  d-flex flex-column justify-content-between">
                        <div class="d-flex justify-content-md-end">
                            <b-button variant="info" @click="searchWithLocation" class="mt-2 ml-0 ml-md-2">Search Location</b-button>
                            <b-button variant="warning" @click="clearLocationFilters" class="mt-2 ml-0 ml-md-2">Clear</b-button>
                        </div>
                       
                        <div class="mapOptions d-flex justify-content-md-end" >
                            <div class="mr-2"  v-if="!viewOption && user.role.admin">
                                <b-button @click="deleteSelectProductionContract" variant="danger">
                                    Delete
                                </b-button>
                            </div>
                            <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                id="btn-radios-1"
                                v-model="viewOption"
                                :options="options"
                                :aria-describedby="ariaDescribedby"
                                name="radios-btn-default"
                                buttons
                                ></b-form-radio-group>
                            </b-form-group>
                        </div>
                       
                    </div>
                    </div>
                </div>
            </div>
        <div v-if="loading||data.length==0" class="row mt-0 mt-md-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 v-if="loading" class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                <h4 v-else class="card-title mb-4">No Production Contract Found</h4>
                <p>Please change your filters.</p>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else>
            <div v-if="!viewOption" class="row">
                <div v-for="productionContract in getUniqueValueFromArray(data)" :key="productionContract.id" class="col-12 col-md-6 col-lg-4">
                    <b-card
                        class="card-commodity border fixed-card-body"
                         @click="viewProductionContract(productionContract.id)"
                    >
                        <template #header>
                            <h5 class="text-primary mt-0 mb-0 font-weight-bold d-flex">
                                <span class="w-100">{{productionContract.subtype}} {{ productionContract.type}}</span>
                                <span v-on:click.stop>
                                    <b-checkbox :disabled="!isSubscribe" size="is-large" :value="productionContract" v-model="selectDeleteProductionContract" v-on:click.stop></b-checkbox>
                                </span>
                            </h5>
                        </template>
                        <small v-if="productionContract.location" class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Location</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{productionContract.location}}</strong></small>
                        <small class="d-block d-md-flex mt-2">
                            <span class="d-block d-md-inline text-left text-muted flex-fill pr-4"> 
                                {{ productionContract.priceOption ? productionContract.priceOption : "" }} Price (Per {{productionContract.unit}})
                            </span>
                            <strong class="d-block d-md-inline flex-fill text-left text-md-right">
                                {{ productionContract.price !== null 
                                    ? (productionContract.price !== 0 
                                        ? (productionContract.price > 0 ? '$' : '-$') + numeral(Math.abs(productionContract.price)).format('0,000.00') 
                                        : '$0.00')
                                    : '-' 
                                }}
                            </strong>
                        </small>
                        <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Delivery Window</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{moment(productionContract.startDate.toDate()).format('ll')}} to {{moment(productionContract.endDate.toDate()).format('ll')}}</strong></small>
                        <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Number of Acres</span><strong class="d-block d-md-inline flex-fill text-left text-md-right"> {{ numeral( productionContract.accepted_full_fill_amount != undefined ? productionContract.accepted_full_fill_amount : productionContract.available_acres).format('0,000')}}</strong></small>
                        <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-mutes flex-fill pr-4">Variety</span><strong class="d-block d-md-inline flex-fill text-left text-md-right"> {{ productionContract.variety }} </strong></small>
                        <div :class="user.role.admin || productionContract.userID == user.uid ? 'buttonMargin' : ''">
                            <b-button @click="shareProductionContractURL(productionContract)" variant="info" size="sm" block class="mt-2 fixed-share-bottom" :class="[!user.role.admin && productionContract.userID !== user.uid ? 'mb-0': '']" v-on:click.stop>Share</b-button>
                            <b-button :disabled="!isSubscribe" @click="$router.push('/production/contract/edit/'+productionContract.id)" variant="primary" v-if="user.role.admin||user.uid==productionContract.userID" size="sm" block class="mt-4 fixed-bottom" v-on:click.stop>Edit</b-button>
                            <b-button :disabled="deleteButton" @click="deleteProductionContract(productionContract)" variant="danger" v-if="user.role.admin" size="sm" class="mt-4 fixed-delete-bottom" v-on:click.stop>Delete</b-button>
                        </div>
                    </b-card>
                </div>
            </div>
            <div class="col-12 productionContractShowMap p-0 mt-2" v-show="viewOption">
                <div class="p-2 bg-white" style="background: #fff;">
                    <div v-if="data.length > 0">
                        <gmap-map :zoom ="zoom" :options="{gestureHandling: 'greedy', streetViewControl: false}" :center="(productionContractPlace.length > 0) ? productionContractPlace[0].position : data[0].placePosition" style="width:100%; height: 520px;" v-if="data[0].placePosition != undefined" ref="productionContractMap" class="mt-0">
                            <gmap-cluster  :minimumClusterSize="4" :maxZoom = "5" :gridSize="90" :zoomOnClick="true"  :enableRetinaIcons="true">
                                <gmap-marker
                                    :position="productionContract.productionContractLocation.position"
                                    v-for="productionContract in getUniqueValueFromArray(data)"
                                    :key="productionContract.id"
                                    @click="openProductionContract(productionContract.id)"
                                    :clickable="true"
                                    @mouseover = "statusText = productionContract.id"
                                    @mouseout="statusText = null"
                                >
                                    <gmap-circle
                                        :center = "filterOption ? productionContractPlace[0].position : routesCenterPoints"
                                        :radius=" filterOption ? radiusMeter : routesPointRadius + radiusMeter"
                                        ref="circle"
                                        :options="{strokeColor: '#000000', strokeOpacity: 0.5, strokeWeight: 1, fillColor:'#FF6600', fillOpacity:0.1}"
                                        v-if="productionContractPlace.length > 0"
                                    >
                                    </gmap-circle>  
                                    <gmap-polyline
                                        :path.sync="routesPolylinePath"
                                        :editable = "false"
                                        ref="polyline"
                                        v-if="!filterOption"
                                        :options="{strokeColor: '#1967D2', strokeOpacity: 0.7}"
                                    >
                                    </gmap-polyline>
                                    <gmap-info-window
                                        :closeclick="true"
                                        @closeclick="openProductionContract(null)"
                                        :opened="openProductionContractId === productionContract.id"
                                        >
                                        <div>
                                            <!-- <b-img thumbnail fluid style="width:230px;  height: 150px;" v-bind:src="(primaryImage(productionContract.commodity.images).largeURL?primaryImage(productionContract.commodity.images).largeURL:'/img/NGF_NO_IMAGE.png')" />  -->
                                            <h5 class="text-primary mt-0 mb-0 font-weight-bold d-flex"><span class="w-100">{{productionContract.subtype}} {{productionContract.type}}</span></h5> 
                                            <span v-if="productionContract.location" class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Location</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{productionContract.location}}</strong></span>
                                            <span class="d-block d-md-flex mt-2">
                                                <span class="d-block d-md-inline text-left text-muted flex-fill pr-4">
                                                    Price (Per {{productionContract.unit}})
                                                </span>
                                                <strong class="d-block d-md-inline flex-fill text-left text-md-right">
                                                    {{ productionContract.price !== null 
                                                        ? (productionContract.price !== 0 
                                                            ? (productionContract.price > 0 ? '$' : '-$') + numeral(Math.abs(productionContract.price)).format('0,000.00') 
                                                            : '$0.00')
                                                        : '-' 
                                                    }}
                                                </strong>
                                            </span>
                                            <span class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Delivery Window</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{moment(productionContract.startDate.toDate()).format('ll')}} to {{moment(productionContract.endDate.toDate()).format('ll')}}</strong></span>
                                            <span class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Guaranteed Purchase</span><strong class="d-block d-md-inline flex-fill text-left text-md-right"> {{ productionContract.total }} {{productionContract.unit}} per acre</strong></span>
                                            <span class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-mutes flex-fill pr-4">AoG (Act Of God) </span><strong class="d-block d-md-inline flex-fill text-left text-md-right"> {{ productionContract.act_of_god }} </strong></span>
                                            <span class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-mutes flex-fill pr-4">Variety</span><strong class="d-block d-md-inline flex-fill text-left text-md-right"> {{ productionContract.variety }} </strong></span>
                                            <b-button variant="info" @click="viewProductionContract(productionContract.id)">View</b-button>
                                        </div>
                                    </gmap-info-window>
                                </gmap-marker>
                            </gmap-cluster>
                        </gmap-map>
                    </div>
                    <div v-else>
                        No Results 
                    </div>
                </div>
            </div>
        </div>
        <div>
            <b-modal
                id="subscribe-modal"
                title="Membership Required"
                visible
                hide-footer
                @hidden="modalVisible = false"
                v-model="subscribeOnModelVisible"
                centered
                aria-labelledby="membership-required-title" 
            >
                <template #modal-header class="align-items-center">
                    <h5 id="membership-required-title" class="modal-title">
                        <i class="fa fa-lock me-2"></i> Membership Required
                    </h5>
                    <b-button variant="link" size="sm" @click="subscribeOnModelVisible = false" aria-label="Close"  class="p-0 text-dark">
                        <i class="fa fa-times"></i>
                    </b-button>
                </template>

                <div class="text-center py-3">
                    <p class="mb-4 text-left">
                        You need an active membership to access this feature. Please become a member to continue.
                    </p>
                    <b-button
                        variant="primary"
                        size="lg"
                        @click="redirectToSubscription"
                        class="px-5"
                        aria-label="Become a Member"
                    >
                        <i class="bi bi-person-plus me-2"></i> Become a Member
                    </b-button>
                </div>
            </b-modal>
        </div>
        <div>
            <b-modal ref="model" centered v-model="copyLinkPopup">
                <template>
                <span><p class="h5 font-bold mb-0">Shareable link has been copied.</p></span>
                </template>
                <template #modal-footer="{ok,cancel}">
                <b-button size="xl" variant="success"  @click="cancel()">
                    Close
                </b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>


<script>

import {bus} from '@/vuex/bus.js';
import {db} from '@/firebase';
import { docData } from 'rxfire/firestore';
import { of, from, combineLatest } from 'rxjs';
import { map, switchMap} from 'rxjs/operators';
import {mapState} from 'vuex'
import Vue from "vue";
import moment from 'moment';
import * as VueGoogleMaps from "vue2-google-maps";
import * as geofire from 'geofire-common'; // this use for geo queries
import { gmapApi } from "vue2-google-maps";
import store from '@/vuex/store.js';
import Multiselect from 'vue-multiselect'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import { Clipboard } from "v-clipboard"
import firebase from 'firebase';




Vue.prototype.moment = moment;
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FIREBASE_CONFIG_GOOGLE_API_KEY,
    libraries: "places"
  }
});
export default {
    components: {
        Multiselect,
        GmapCluster
    },
    computed: {
        ...mapState(['user','productionContractFilters']),
        hasFilters(){
            return this.selectedProductionContractCategoryType || this.selectedProductionContractType || (this.user.role.admin&&this.selectedStatus) || this.selectedProductionContractSubType || this.selectedProductionContractSpecification;
        },
        google: gmapApi,
    },
    watch:{
        selectedStatus(){
            if(!this.mounting){
                (this.productionContractPlace.length > 0) ? this.searchWithLocation() : this.loadProductionContract();
            } 
        },
        async selectedProductionContractCategoryType(){
            let vm = this;
            localStorage.selectedProductionContractCategoryType=JSON.stringify(vm.selectedProductionContractCategoryType);
            if(!vm.mounting){
                await vm.loadTypes();
                (this.productionContractPlace.length > 0) ? vm.searchWithLocation() : vm.loadProductionContract();
            }
        },
        async selectedProductionContractType(){
            let vm = this;
            localStorage.selectedProductionContractType=JSON.stringify(vm.selectedProductionContractType);
            if(!vm.mounting) {
                await vm.loadSubTypes();
                (this.productionContractPlace.length > 0) ? vm.searchWithLocation() : vm.loadProductionContract();
            } 
        },
        selectedProductionContractSubType(){
            localStorage.selectedProductionContractSubType=JSON.stringify(this.selectedProductionContractSubType);
            if(!this.mounting) {
                (this.productionContractPlace.length > 0) ? this.searchWithLocation() : this.loadProductionContract();
            }    
        },
        selectedProductionContractSpecification(){
            localStorage.selectedProductionContractSpecification=JSON.stringify(this.selectedProductionContractSpecification);
            if(!this.mounting) {
                (this.productionContractPlace.length > 0) ? this.searchWithLocation() : this.loadProductionContract();
            } 
        },  
    },
    data() {
        return {
            data: [],
            loading: true,
            mounting: false,
            viewOption: true,
            filterOption: true,
            searchRadiusMiles: 50,
            zoom: 12,
            productionContractPlace: [],
            radiusMeter: '',
            place: [],
            getRouteAllCoordinate: [],
            specificPlace: '',
            startPlace: '',
            destinationPlace: '',
            positionFailed: null,
            maximumLimitOfMiles: null,
            maximumLimitOfMilesFailed: null, 
            selectedProductionContractCategoryType:null,
            selectedProductionContractType:null,
            selectedProductionContractSubType:null,
            selectedProductionContractSpecification:null,
            openProductionContractId: null,
            deleteButton: false,
            selectedStatus:{id:'yes',name:'Yes'},
            copyLinkPopup: false,
            selectDeleteProductionContract: [],
            options: [
                { text: 'Map', value: true, icon: "fa fa-map"},
                { text: 'List', value: false, icon: "fa fa-list"}
            ],
            filterOptions: [
                { text: 'Location', value: true },
                { text: 'Along Route', value: false}
            ],
            routesPolylinePath: [],
            routesCenterPoints: '',
            routesPointRadius:  '',
            isSubscribe: false,
            subscribeOnModelVisible: false,
        }
    },
    mounted(){
        const vm = this;
        vm.maximumLimitOfMiles = store.state.limitOfMiles?.limitMiles ?? 100;
        vm.load();
        vm.isSubscribe = !vm.user.role.admin && vm.user?.subscription?.stripeSubscription?.status === 'active';

        if(vm.user.role.admin){
            vm.isSubscribe = true;
        }
    },
    methods:{
        async load(){
            let vm = this;
            vm.mounting=true;
            if(localStorage.selectedProductionContractCategoryType){
                vm.selectedProductionContractCategoryType=JSON.parse(localStorage.selectedProductionContractCategoryType);
                await vm.loadTypes();
            }
            if(localStorage.selectedProductionContractType){
                vm.selectedProductionContractType=JSON.parse(localStorage.selectedProductionContractType);
                await vm.loadSubTypes();
            }
            if(localStorage.selectedProductionContractSubType) vm.selectedProductionContractSubType = JSON.parse(localStorage.selectedProductionContractSubType);
            if(localStorage.selectedProductionContractSpecification) vm.selectedProductionContractSpecification = JSON.parse(localStorage.selectedProductionContractSpecification);
            vm.loadProductionContract();
            bus.$emit('prepareTitle',null);
            vm.mounting=false;
        },
        openProductionContract(productionContractId){
            this.openProductionContractId = productionContractId
        },
        getUniqueValueFromArray(items){      
            return [
                ...new Map(
                    items.map((item) => [item['id'], item])
                ).values()
            ]
        },
        primaryImage(images){
            if(images !=  undefined){
                let arr = images.filter(i=>i.primary);
                if(arr.length==0) return images[0];
                else return arr[0]; 
            }
        },
        viewProductionContract(productionContractID){
            const vm = this;
            vm.subscribeOnModelVisible = false;
            if(!vm.isSubscribe){
                vm.subscribeOnModelVisible = true;
                return;
            }
            vm.$router.push('/production/contract/'+productionContractID)
        },
        choose(productionContractID){
            this.$router.push('/production/contract/'+productionContractID)
        },

        async shareProductionContractURL(productionContract){
            const vm = this;
            vm.subscribeOnModelVisible = false;
            if(!vm.isSubscribe){
                vm.subscribeOnModelVisible = true;
                return;
            }
            const URL = `${window.location.origin}/production/contract/${productionContract.slug ?? productionContract.id}`;
            Clipboard.copy(URL).then(() => {
                this.copyLinkPopup = true;
            });
        },

        async deleteProductionContract(productionContract){
            if(confirm('Are you sure you want to delete this Production contract?')){
                this.loading=true;
                this.deleteButton = true;
                let ref = await db.collection('production_contract').doc(productionContract.id);
                ref.update({is_delete: 1, deleted: firebase.firestore.FieldValue.serverTimestamp()}).then(() => {
                this.deleteButton = false;
                this.data = [];
                setTimeout(() => {
                    this.loadProductionContract();
                }, 7000);
                });
            }
        },
        loadProductionContract(){
            let vm = this;
            vm.loading=true;
            vm.data = [];
            let ref = db.collection('production_contract');
            ref = ref.where('is_delete', '==', 0);
            
            if(!vm.user.role.admin){
                ref = ref.where('active', '==', true)
            }
            
            if(this.selectedStatus != null){
                ref = ref.where('active','==',(this.selectedStatus.id=='yes'?true:false));
            }

            if(this.selectedProductionContractCategoryType != null){
                ref = ref.where('category','==',this.selectedProductionContractCategoryType.id);
            }

            if(this.selectedProductionContractType != null){
                ref = ref.where('type','==',this.selectedProductionContractType.id);
            }
            if(this.selectedProductionContractSubType != null){
                ref = ref.where('subtype','==',this.selectedProductionContractSubType.id);
            }
            if(this.selectedProductionContractSpecification != null){
                ref = ref.where('specification','array-contains',this.selectedProductionContractSpecification.id);
            }
            let ref$ = from(ref.get()).pipe(
            switchMap((productionContracts)=>{
                    if(productionContracts.empty) return of([]);
                    return combineLatest(...productionContracts.docs.map(c=>{
                        return docData(db.collection('units').doc(c.data().unit)).pipe(
                                map((t)=>{
                                    return {...c.data(),unit:t.name, unitId: c.unit}
                                })
                            )
                        }))
                    })
            ).pipe(
                switchMap(productionContracts=>{
                    if(productionContracts.length==0) return of([]);
                    return combineLatest(...productionContracts.map(c=>{
                        return docData(db.collection('commodity_types').doc(c.type)).pipe(
                            map(t=>{
                                return {...c,type:t.name, typeId: c.type}
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(productionContracts=>{
                    if(productionContracts.length==0) return of([]);
                    return combineLatest(...productionContracts.map(c=>{
                        return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
                        map(t=>{
                            return {...c,subtype:t.name, subtypeId: c.subtype}
                        })
                        )
                    }))
                })
            ).pipe(
                switchMap(productionContracts=>{
                  if(productionContracts.length==0) return of([]);
                    return combineLatest(...productionContracts.map(c=> {
                        return docData(db.collection('production_contract_location').doc(c.id)).pipe(
                            map(l => {
                                return {...c, productionContractLocation: l}
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(productionContract => {
                    if(productionContract.length==0) return of([]);
                        return combineLatest(...productionContract.map(c=>{
                            return docData(db.collection('price_options').doc(c.priceOption)).pipe(
                                map(p => {
                                    return {...c, priceOption: p.name}
                                })
                            )
                        }));
                })
            )
            ref$.subscribe((data)=>{
                Array.from(Array(data.length).keys()).map(async(x) => {                    
                    vm.data.push(data[x]);
                });
                if(vm.user.role.admin){
                    Array.from(Array(data.length).keys()).map(async(x) => {                    
                        if(data[x].slug == undefined){
                            await vm.updateSlugInProductionContract(data[x].subtypeId, data[x].typeId, data[x].id);
                        }
                    });
                }
                vm.loading = false;    
                    if(vm.data.length > 0){
                        vm.setMapsZoomLevel();
                    }
            })
        },


    generateRandomNumber(){
      return Math.floor(Math.random() * (99 - 10 + 1) + 10) ;
    }, 
    async updateSlugInProductionContract(subtypeId, typeId, productionContractId){
        let tempSlug = null; 

        let ref = await db.collection('commodity_subtypes').doc(subtypeId).get();
            if(!ref.empty) var subType = {...ref.data()}.name;

            ref = await db.collection('commodity_types').doc(typeId).get();
            if(!ref.empty) var type = {...ref.data()}.name;

            let productRef = await db.collection('commodities')
                                     .where('type', '==', typeId)
                                     .where('subtype', '==', subtypeId).get();

            if(!productRef.empty){
                let totalExistingProduct = productRef.docs.length + this.generateRandomNumber();
                tempSlug = (subType + "-" + type + "-" + totalExistingProduct).trim();
            } else {
                tempSlug = (subType + "-" + type).trim();
            }                      

        let slug = tempSlug.toLowerCase().replace(/\s+/g,'-');
        slug = slug.replace("/", "-");

        const updateProductRef = await db.collection('production_contract').doc(productionContractId);
        updateProductRef.update({
            slug:  slug
        });       
    },

        async getAllPossibleCoordinates(){
            try {
                let vm = this;
                vm.getRouteAllCoordinate = [];
                let directionService = new vm.google.maps.DirectionsService();
                let request = {
                    origin: this.startPlace,
                    destination: this.destinationPlace,
                    travelMode: 'DRIVING',
                }
                await directionService.route(request).then((response) =>{
                    let getDirectionData = JSON.parse(JSON.stringify(response));
                    const route =  getDirectionData.routes[0].legs[0];
                    const routeDistance = route.distance.value;
                    const startLocation = route.start_location;
                    const endLocation = route.end_location;
                    const centerPoint  = {
                        lat : (startLocation.lat + endLocation.lat) / 2,
                        lng : (startLocation.lng + endLocation.lng) / 2, 
                    } // calculate center point between alone routes

                    var legs = response.routes[0].legs;
                    for (var i = 0; i < legs.length; i++) {
                        var steps = legs[i].steps;
                        for (var j = 0; j < steps.length; j++) {
                        var nextSegment = steps[j].path;
                        if(nextSegment.length != 2){
                            for (var k = 0; k < nextSegment.length; k++) {
                            vm.routesPolylinePath.push(nextSegment[k]);
                            }
                        }
                        }
                    } // draw polyline between routes

                    vm.routesCenterPoints = centerPoint;
                    vm.routesPointRadius = (routeDistance / 2);
                    getDirectionData.routes.forEach((route) => {
                        route.legs.forEach((leg) => {
                            leg.steps.forEach((step) => {
                                if(step.start_location){
                                    vm.getRouteAllCoordinate.push(step.start_location);
                                }
                            });
                        });
                    });
                });
            } catch (error) {
                console.log(error.message);
            }
        },
        getProductionContractData(promises, center){
            let vm = this;
            const radiusInM = (vm.searchRadiusMiles * 1609.34);
            this.radiusMeter = radiusInM
            Promise.all(promises).then((snapshots) => {
                const matchingDocs =[];
                for (const snap of snapshots) {
                    for(const doc of snap.docs) {
                        const distanceInKm = geofire.distanceBetween([doc.get('position.lat'), doc.get('position.lng')], center);
                        const distanceInM = (distanceInKm) * 1000;
                        if (distanceInM <= radiusInM) {
                            matchingDocs.push(doc);
                        }
                    }
                }
                return matchingDocs
            }).then(async(matchingDocs) => {
                    for(const doc of matchingDocs) {
                        const vm = this;
                        const productionContractId = doc.id;
                        let ref = db.collection('production_contract').where('id', '==', productionContractId);
                        ref = ref.where('is_delete', '==', 0)
                        if(!vm.user.role.admin){
                            ref = ref.where('active', '==', true)
                        }
                        if(vm.selectedProductionContractCategoryType != null){
                            ref = ref.where('category','==',vm.selectedProductionContractCategoryType.id);
                        }
                        if(vm.selectedStatus != null){
                            ref = ref.where('active','==',(vm.selectedStatus.id=='yes'?true:false));
                        }
                        if(vm.selectedProductionContractType != null){
                            ref = ref.where('type','==',vm.selectedProductionContractType.id);
                        }
                        if(vm.selectedProductionContractSubType != null){
                            ref = ref.where('subtype','==',vm.selectedProductionContractSubType.id);
                        }
                        if(vm.selectedProductionContractSpecification != null){
                            ref = ref.where('specification','array-contains',vm.selectedProductionContractSpecification.id);
                        }
                        let ref$ = from(ref.get()).pipe(
                                switchMap((productionContracts)=>{
                                        if(productionContracts.empty) return of([]);
                                        return combineLatest(...productionContracts.docs.map(c=>{
                                            return docData(db.collection('units').doc(c.data().unit)).pipe(
                                                    map((t)=>{
                                                        return {...c.data(),unit:t.name, unitId: c.unit}
                                                    })
                                                )
                                            }))
                                        })
                                ).pipe(
                                    switchMap(productionContracts=>{
                                        if(productionContracts.length==0) return of([]);
                                        return combineLatest(...productionContracts.map(c=>{
                                            return docData(db.collection('commodity_types').doc(c.type)).pipe(
                                                map(t=>{
                                                    return {...c,type:t.name}
                                                })
                                            )
                                        }))
                                    })
                                ).pipe(
                                    switchMap(productionContracts=>{
                                        if(productionContracts.length==0) return of([]);
                                        return combineLatest(...productionContracts.map(c=>{
                                            return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
                                            map(t=>{
                                                return {...c,subtype:t.name}
                                            })
                                            )
                                        }))
                                    })
                                ).pipe(
                                    switchMap(productionContracts=>{
                                    if(productionContracts.length==0) return of([]);
                                        return combineLatest(...productionContracts.map(c=> {
                                            return docData(db.collection('production_contract_location').doc(c.id)).pipe(
                                                map(l => {
                                                    return {...c, productionContractLocation: l}
                                                })
                                            )
                                        }))
                                    })
                                );          
                        ref$.subscribe((data)=>{
                            Array.from(Array(data.length).keys()).map(x => {
                                vm.data.push(data[x]);
                            });
                            vm.setMapsZoomLevel(); 
                        })
                    }
            })
        },
        async searchWithLocation() {
            var vm = this;
            vm.loading = true;
            vm.data = [];
            if(vm.productionContractPlace.length == 0){
                vm.productionContractPlace = vm.place;
                vm.place = []; 
            }
            if((vm.destinationPlace != "") && (vm.startPlace != "") || (vm.specificPlace != "") && (vm.searchRadiusMiles <= vm.maximumLimitOfMiles)){
                const radiusInM = (vm.searchRadiusMiles * 1609.34);
                vm.radiusMeter = radiusInM;
                vm.positionFailed=null;
                vm.maximumLimitOfMilesFailed = null;
                const promises = [];
                if(vm.filterOption == false){
                    vm.routesCenterPoints = "";
                    vm.routesPolylinePath = [];
                    await vm.getAllPossibleCoordinates();
                }
                if(vm.getRouteAllCoordinate.length != 0 && vm.filterOption == false){
                    vm.getRouteAllCoordinate.forEach(async(route) => {
                        const center = [route.lat, route.lng];   
                        const bounds= geofire.geohashQueryBounds(center, radiusInM); 
                        for(const b of bounds){
                            var q = db.collection('production_contract_location');
                            q = q.orderBy('geohash').startAt(b[0]).endAt(b[1])
                            promises.push(q.get());
                        }
                       await vm.getProductionContractData(promises, center);
                    });
                } else {
                    vm.productionContractPlace.forEach(async(place) => {
                        const center = [place.position.lat, place.position.lng];    
                        const bounds= geofire.geohashQueryBounds(center, radiusInM);
                        for(const b of bounds){
                            const q = db.collection('production_contract_location').orderBy('geohash').startAt(b[0]).endAt(b[1]);
                            promises.push(q.get());
                        }
                       await vm.getProductionContractData(promises, center);
                    });
                }
                vm.getRouteAllCoordinate = [];
                if(vm.data.length <= 0 ){
                    setTimeout(() => {
                        vm.loading = false;
                        if(vm.data.length > 0){
                            vm.setMapsZoomLevel(); 
                        }                                                     
                    }, 4000);
                }
                if(vm.data.length == 0){
                    setTimeout(() => {
                        vm.loading = false;   
                    }, 4000);
                }
            } else {
                if(!(vm.searchRadiusMiles <= vm.maximumLimitOfMiles)){
                    vm.maximumLimitOfMilesFailed = true
                }
                vm.positionFailed = true
                vm.loading = false
            }
        },
        changeFilterOption(){
            this.productionContractPlace = [];
            this.searchRadiusMiles = 50;
            this.place = [];
            this.startPlace = '';
            this.destinationPlace = '';
            this.specificPlace = '';
            this.positionFailed = null;
            this.loadProductionContract();
            this.routesPolylinePath = [];
            this.routesCenterPoints = "";
            this.routesPointRadius = "";
        },
        clearLocationFilters(){
            this.productionContractPlace = [];
            this.searchRadiusMiles = 50;
            this.specificPlace = '';
            this.startPlace = '';
            this.destinationPlace = '';
            this.data = [];
            this.place = [];
            this.getRouteAllCoordinate = [];
            this.positionFailed=null;
            this.loadProductionContract();
            this.routesPolylinePath = [];
            this.routesCenterPoints = "";
            this.routesPointRadius = "";
        },
        clearFilters(){
            this.selectedProductionContractCategoryType = null,
            this.selectedProductionContractType = null,
            this.selectedProductionContractSubType = null,
            this.selectedProductionContractSpecification = null,
            this.loading = true;
            this.data = [];
            this.loadProductionContract();
            if(this.user.role.admin) this.selectedStatus=null;

        },
        setStartPlace(startPlace) {
            this.getRouteAllCoordinate =[];
            this.startPlace = startPlace.formatted_address
            var data = {
                position:{
                    lat: startPlace.geometry.location.lat(),
                    lng: startPlace.geometry.location.lng()
                },
            };
            this.place.push(data);
        },
        setPlaceSpecificLocation(specificPlace){
            if(this.productionContractPlace.length != 0){this.productionContractPlace = []}
            this.specificPlace = specificPlace.formatted_address;
            var data = {
                position:{
                    lat: specificPlace.geometry.location.lat(),
                    lng: specificPlace.geometry.location.lng()
                },
            };
            this.place.push(data);
        },
        setDestinationPlace(destinationPlace){
            this.getRouteAllCoordinate =[];
            this.destinationPlace = destinationPlace.formatted_address
            var data = {
                position:{
                    lat: destinationPlace.geometry.location.lat(),
                    lng: destinationPlace.geometry.location.lng()
                },
            };
            this.place.push(data);
        },
        async loadTypes(){
            let vm = this;
            let filters = store.state.productionContractFilters;
            filters['commodity'] = [];
            vm.selectedProductionContractType=null;
            if(vm.selectedProductionContractCategoryType){
                let ref = await db.collection('commodity_types').where('category','==',vm.selectedProductionContractCategoryType.id).orderBy('name','asc').get();
                ref.forEach(t=>filters['commodity'].push({...t.data(),type:'commodity',id:t.id,checked:false,hidden:false}));
            }
            store.commit('updateProductionContractFilters',filters);
        },
        async loadSubTypes(){
            let vm = this;
            let filters = store.state.productionContractFilters;
            filters['subtype']=[];
            vm.selectedProductionContractSubType=null;
            if(vm.selectedProductionContractType){
                let ref = await db.collection('commodity_subtypes').where('type','==',vm.selectedProductionContractType.id).orderBy('name','asc').get();
                ref.forEach(t=>filters['subtype'].push({...t.data(),type:'subtype',id:t.id,checked:false,hidden:false}));
            }
            store.commit('updateProductionContractFilters',filters);
        },

        async deleteSelectProductionContract(){
            const vm = this;
            if(vm.selectDeleteProductionContract.length < 0){
                return;
            }
            if(confirm('Are you sure you want to delete this Production contract?')){
                vm.loading = true;
                if(vm.selectDeleteProductionContract.length == 0){
                    Array.from(Array(vm.selectDeleteProductionContract.length).keys()).map(async(x) =>{
                        let ref = await db.collection('production_contract').doc(vm.selectDeleteProductionContract[x].id);
                        ref.update({is_delete: 1, deleted: firebase.firestore.FieldValue.serverTimestamp()}).then(() => {
                            vm.loading = false;
                            vm.data = [];
                            vm.loadProductionContract();
                        })
                    })
                }
            }
        },

        redirectToSubscription(){
            const vm = this;
            vm.$router.replace("/profile")
        },

        async setMapsZoomLevel(){
            let vm = this  
                var bounds = new vm.google.maps.LatLngBounds();
                for(let d of vm.data){
                    if(d.placePosition != undefined){
                        bounds.extend(d.placePosition);
                    }
                }
                setTimeout(function(){
                    for(let ref in vm.$refs){
                        if(ref == "productionContractMap"){
                            vm.$refs[ref].fitBounds(bounds);
                        }
                    } 
                }, 1000)
            if(vm.data.length > 1){
                vm.zoom = 10;
            } else if (vm.data.length >= 6 && vm.data.length <= 2){
                vm.zoom = 8;
            } else if (vm.data.length >= 10 && vm.data.length <= 6){
                vm.zoom = 6;
            } else {
                vm.zoom = 4;
            }
        },
    }

}
</script>
<style>

.buttonMargin{ 
    margin-top: 30px !important;
}

.table-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card.card-commodity {
  height: calc(100% - 20px);
}
.card-commodity .card-header {
    background-color: unset !important;
}
.fixed-bottom {
  position: absolute;
  bottom: 40px;
  margin: 15px;
  width: calc(100% - 30px );
}
.fixed-delete-bottom {
  position: absolute;
  bottom: 10px;
  margin: 0px;
  width: calc(100% - 30px );
}
.fixed-card-body {
  padding-bottom: 100px
}

.card-img-top{
    width: 100%;
    height: 10vw;
    object-fit: cover;
}

@media (max-width:1620px){
  .card-img-top {
    height: 18vw;
}
}

@media (max-width:1024px){
  .card-img-top {
    height: 16vw;
}
}
@media (max-width:767px){
  .card-img-top {
    height: unset;
}
}
.text-color-red {
  color:red;
  font-size: 15px;
}
vdp-datepicker input[type="text"], .pac-target-input{
    border: 1px solid #a8a8a8 !important  ;
    border-radius: 3px !important;
    
}
.mapOptions .btn-secondary:not(:disabled):not(.disabled).active{
 background : #7ec313;
}
.filterOption .btn-secondary:not(:disabled):not(.disabled).active{
  background : #7ec313;
}

.productionContractShowMap .gm-style-iw-d{
  max-height: 354px !important;
  
}
.productionContractShowMap .gm-style .gm-style-iw-c{
  padding: 16px;
  max-height: 364px !important;
  max-width: 260px !important; min-width: 260px !important;
}
.productionContractShowMap  .gm-ui-hover-effect{
    top: -3px !important;
    right: -3px !important;
}
.productionContractShowMap  .gm-ui-hover-effect:focus {
    outline: 0px dotted;
    outline: 0x auto -webkit-focus-ring-color;
}


@media (max-width: 767px) {
  .productionContract-filters div {
      margin-bottom: 10px;
  } 
}


</style>