<template>
    <div class="col-md-6 col-lg-4 mx-auto re-active-login">
        <div class="text-center mb-2">
            <img slot="header" src="/img/logo3.png" class="img-fluid loginHeaderImage" />
        </div>
        <b-card class="reactive-login-card shadow-sm bg-white">
            <b-card-header class="card-header bg-primary text-center">
                <h4>Re-Activate User</h4>
            </b-card-header>
            <b-card-body>
                <b-alert 
                    v-show="ReActiveLoginError" 
                    variant="danger" 
                    dismissible 
                    class="mb-4 rounded-lg shadow-lg"
                    show
                >
                    {{ ReActiveLoginError }}
                </b-alert>

                <b-alert 
                    v-if="accountReactivated    "
                    variant="success"
                    dismissible
                    class="mb-4 rounded-lg shadow-lg"
                    show
                >
                    Your account has been successfully reactivated!
                </b-alert>

                <ValidationObserver ref="ReactiveForm" v-if="!accountReactivated">
                    <b-form @submit.prevent="submitReActiveUser">
                        <b-form-group>
                            <ValidationProvider
                                name="email"
                                rules="emailRequired|email"
                                v-slot="{passed,failed,errors}"
                                >
                                <b-form-group
                                    :invalid-feedback="errors.length>0?errors[0]:null"
                                    :state="(failed?false:(passed?true:null))"
                                >
                                    <b-form-input placeholder="Email (required)" v-model.trim="email" :state="(failed?false:(passed?true:null))" />
                                </b-form-group>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group>
                            <ValidationProvider
                                name="password"
                                rules="required|min:6"
                                v-slot="{ passed, failed, errors }"
                            >
                                <b-form-group
                                    :invalid-feedback="errors.length > 0 ? errors[0] : null"
                                    :state="failed ? false : passed ? true : null"
                                >
                                    <b-input-group>
                                        <b-form-input
                                            :type="passwordType"
                                            placeholder="Password"
                                            v-model.trim="password"
                                            :state="failed ? false : passed ? true : null"
                                        />
                                        <b-input-group-append>
                                            <b-button
                                                variant="seconder"
                                                @click="togglePasswordVisibility"
                                                :aria-label="passwordType === 'password' ? 'Show Password' : 'Hide Password'"
                                            >
                                                    <i :class="passwordIcon"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    
                                </b-form-group>
                                </ValidationProvider>
                        </b-form-group>

                        <b-button
                            type="submit"
                            variant="primary"
                            block
                            :disable="processing"
                            class="rounded-pill"
                        >   
                            <span v-if="processing">
                                <b-spinner small class="ml-2"></b-spinner>
                                Processing....
                            </span>
                            <span v-else>
                                Re-Activate
                            </span>
                        </b-button>

                    </b-form>
                </ValidationObserver>
                <b-button
                    v-if="accountReactivated"
                    variant="success"
                    block
                    class="mt-4 rounded-pill"
                    @click="redirectToLogin"
                >
                    Go to Login Page
                </b-button>
            </b-card-body>
        </b-card>
    </div>
</template>



<script>

import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import {auth} from '@/firebase';
import {db} from '@/firebase';
// import {bus} from "@/vuex/bus.js";
// import store from '@/vuex/store.js';
import firebase from 'firebase';

extend("required", required);
extend("emailRequired", {
  ...required,
  message:'Email is required'
});
extend("email", {
  ...email,
  message:'Enter a valid email'
});


export default {
    name: 'ReActiveUser',
    data(){
        return{
            processing: false,
            email: null, 
            password: null,
            passwordIcon:'fa fa-eye-slash',
            passwordType:'password',
            ReActiveLoginError: null,
            accountReactivated: false, 
        }
    },

    methods:{
        togglePasswordVisibility() {
            if (this.passwordType === "password") {
                this.passwordType = "text"; // Show password
                this.passwordIcon = "fa fa-eye"; // Change icon to "eye"
            } else {
                this.passwordType = "password"; // Hide password
                this.passwordIcon = "fa fa-eye-slash"; // Change icon to "eye-slash"
            }
        },


        async submitReActiveUser() {
            const vm = this;
            vm.processing = true;
            vm.ReActiveLoginError = null;
            vm.accountReactivated = false;

            try {
                const isValid  =await vm.$refs.ReactiveForm.validate();

                if(!isValid){
                    vm.processing = false;
                    return;
                }

                const userCredential = await auth.signInWithEmailAndPassword(vm.email, vm.password);
                const userId = userCredential.user.uid;

                const userRef = db.collection("users").doc(userId);
                const userDoc = await userRef.get();
                if (!userDoc.exists) {
                    vm.ReActiveLoginError = "Your account does not exist.";
                    vm.processing = false;
                    return;
                }

                const userData = userDoc.data();

                if (userData.business?.type === 'admin') {
                    vm.ReActiveLoginError = 'Admin cannot perform this action. Please try a different account.';
                    vm.processing = false;
                    return;
                }

                if (userData.about?.is_active) {
                    vm.ReActiveLoginError = "Your account is already active.";
                    vm.processing = false;
                    return;
                }

                await userRef.update({
                    "about.is_active": true,
                    "deactivationDate":firebase.firestore.FieldValue.delete()
                });

                await vm.reactivateSubscription(userId);
             
            } catch (error) {
                console.error("Error during reactivation:", error);
                vm.processing = false
                vm.ReActiveLoginError = error.message || "An error occurred while processing your request.";
            } 
        },

        async reactivateSubscription(userID){
            const vm = this

            try {

                const subscriptionRef = db.collection("user_subscriptions").doc(userID);
                const subDoc = await subscriptionRef.get();

                let delay = 2000;

                if(subDoc.exists && subDoc.data().stripeSubscription.status==="canceled"){
                    await subscriptionRef.set({
                        reactivate:true, 
                        cancel:firebase.firestore.FieldValue.delete(), 
                        cancelled:firebase.firestore.FieldValue.delete()
                       },
                       {merge:true})
                    delay = 6000;
                }

                await vm.sleep(2000)

                setTimeout(async () => {
                    vm.accountReactivated = true;
                    await auth.signOut();
                }, delay);

            } catch (error) {
                vm.ReActiveLoginError = "An error occurred while processing your request.";
            }

        },

        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        redirectToLogin() {
            this.$router.push('/login');  // Replace 'login' with the correct route name
        },

    },

    watch: {
        ReActiveLoginError(newVal){
            if (newVal) {
                // Clear any previous timeout to avoid conflicts
                if (this.errorTimeout) {
                    clearTimeout(this.errorTimeout);
                }

                // Set a new timeout to clear the error after 5 seconds
                this.errorTimeout = setTimeout(() => {
                    this.ReActiveLoginError = null;
                }, 5000);
            }
        }
    }
}

</script>