<template>
  <div class="col-md-6 col-lg-4 ml-auto mr-auto">
    <div class="text-center mb-2">
      <img slot="header" src="/img/logo3.png" class="img-fluid loginHeaderImage" />
    </div>
    <div v-if="showForgot" class="card rounded bg-white">
      <ForgotPassword @return="showForgot = false" />
    </div>
    <div v-else class="card login-card rounded bg-white">
      <div class="card-header login-header bg-primary text-center">
        <h3 class="text-white">Login</h3>
      </div>

      <div class="card-body">
        <b-alert variant="danger" v-if="loginError" dismissible class="mt-2 mb-2" show>{{ loginError }}</b-alert>
        <fg-input
          type="email"
          class="no-border form-control-lg"
          placeholder="Email"
          addon-left-icon="now-ui-icons ui-1_email-85"
          v-model.trim="email"
          @input="resetError"
          @keyup.enter.native="login"
        />

        <div class="d-flex">
          <fg-input
            :type="passwordType"
            class="no-border form-control-lg w-100"
            placeholder="Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
            :addon-right-icon="'fa ' + passwordIcon"
            v-model.trim="password"
            inputClasses="rounded-0"
            @input="resetError"
            @keyup.enter.native="login"
            @rightclick="togglePasswordShow"
          />
        </div>
        <p class="mt-2 text-center">
          <small>
            <a @click="showForgot = true" class="text-primary add-pointer">Forgot your password?</a>
          </small>
        </p>
      </div>

      <div class="card-footer">
        <a @click="login" class="btn rounded btn-block btn-primary" :disabled="processing">
          <i class="fa fa-spinner mr-2" v-if="processing"></i>
          Login
        </a>
        <a @click="redirectRegister" class="btn rounded btn-block btn-outline-primary mt-2">Join AgHub</a>
      </div>
    </div>
  </div>
</template>

<script>
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import { mapState } from 'vuex';
import store from '@/vuex/store.js';
import { bus } from '@/vuex/bus.js';
import { auth, db } from '@/firebase';

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      loginError: null,
      processing: false,
      showForgot: false,
      passwordType: 'password',
      passwordIcon: 'fa-eye-slash'
    };
  },
  components: { ForgotPassword },
  computed: { ...mapState(['user']) },
  methods: {
    resetError() {
      this.loginError = null;
    },
    redirectRegister() {
      this.$router.replace('/register');
    },
    togglePasswordShow() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      this.passwordIcon = this.passwordType === 'password' ? 'fa-eye-slash' : 'fa-eye';
    },
    async getUser(userId) {
      try {
        const ref = await db.collection("users").doc(userId).get();
        return ref.exists ? { ...ref.data() } : null;
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    },
    async login() {
      const vm = this;
      vm.processing = true;
      vm.loginError = null;

      const email = vm.email.trim();
      const password  = vm.password.trim();

      if (!email) {
        vm.loginError = 'Please enter your email';
        vm.processing = false;
        return;
      }
      if (!password) {
        vm.loginError = 'Please enter your password';
        vm.processing = false;
        return;
      }
      
      try {
        const userCredential = await auth.signInWithEmailAndPassword(email, password);
        const userData = await vm.getUser(userCredential.user.uid);

        if (!userData) {
          vm.loginError = 'Your account does not exist. Please contact NGF-Global.';
          vm.processing = false;
          return vm.logout();
        }

        if (userData.business.type !== "admin" && !userData.about.is_active) {
          vm.loginError = 'Your account was disabled. Please contact NGF-Global.';
          vm.processing = false;
          return vm.logout();
        }

        vm.processing = false;
        vm.$router.replace(vm.$route.query?.redirect || '/');
      } catch (error) {
        vm.processing = false;
        const errorMessages = {
          'auth/invalid-email': 'The email address is badly formatted.',
          'auth/internal-error': 'Invalid email or password.',
          'auth/user-not-found': 'Invalid email or password.',
          'auth/wrong-password': 'Incorrect password. Please try again.',
          'auth/too-many-requests': 'Too many failed login attempts. Try again later.',
          'auth/user-disabled': 'This account has been disabled.'
        };
        vm.loginError = errorMessages[error.code] || 'Login failed. Please check your credentials and try again.';
      }
    },
    async load() {
      if (this.user?.uid) {
        this.$router.replace('/');
      }
    },
    logout() {
      auth.signOut().then(() => {
        bus.$emit('UserLogout');
        store.commit('updateUser', {});
        this.$router.replace('/login');
      });
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    user() {
      this.load();
    }
  }
};
</script>

<style>
.login-card .card-footer {
  position: relative !important;
}
.add-pointer {
  cursor: pointer;
}
.loginHeaderImage {
  max-width: 250px;
  padding: 10px;
}
.card .login-header {
  padding: 25px;
}
.login-header h3 {
  margin-bottom: 0px;
}
</style>
