<template>
    <div class="container">
        <div v-if="!isSubscribe">
            <b-container class="d-flex justify-content-center align-items-center  min-vh-50">            
                    <b-card
                        class="text-center shadow-lg membership-card"
                        header="Membership Required"
                        header-bg-variant="light"
                        style="max-width: 480px;"
                    >
                            <template #header>
                                <div class="membership-header membership-header-font-size">Membership Required</div>
                            </template>
                        <b-card-text class="membership-text mb-4">
                            Become a Member to enjoy these services.
                        </b-card-text>
                        <b-button 
                            variant="primary" 
                            class="membership-button" 
                            @click="redirectToSubscriptionPage"
                        >
                            Become a Member
                        </b-button>
                    </b-card>
            </b-container>
        </div>
      <div v-else>
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div class="row" v-else>
            <div class="col-12"  v-if="productionContractNegotiation != null">
                <div>
                    <div class="border-2 bg-white rounded p-4">
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left text-muted">User ID: {{ productionContractNegotiation.producerID.substr(0, 10) }}</div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3"><h6 class="d-inline">{{ FullName }}</h6></div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3">
                                <h6 class="d-inline"> 
                                    Available acres: {{ numeral(availableAcres).format('0,000') }}
                                </h6>
                            </div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3">
                                <h6 class="d-inline"> 
                                    Guaranteed Purchase {{ productionContractNegotiation.productionContract.unit }} Per acre: {{ numeral(productionContractNegotiation.productionContract.total).format('0,000') }}
                                </h6>
                            </div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3">
                                <h6 class="d-inline">
                                    {{ productionContractNegotiation.productionContract.priceOption }} Price (Per {{productionContractNegotiation.productionContract.unit}}): {{ (productionContractNegotiation.productionContract.price > 0) ? '$' : '-$' }}{{ numeral( Math.abs(productionContractNegotiation.productionContract.price)).format('0,000.00')}}
                                </h6>
                            </div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3">
                                <h6 class="d-inline">
                                    Delivery Window: {{moment(startDate).format('ll')}} to {{moment(endDate).format('ll')}}
                                </h6>
                            </div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3">
                                <h6 class="d-inline">
                                    Farmer Fulfilled Number of Acres: {{ numeral(fullFillAmount).format('0,000') }}
                                </h6>
                            </div>
                            <div class="flex-fill text-center text-md-right pt-0">
                               <strong>Total Project Value:</strong>
                               <h3 class="d-inline font-weight-bold">
                                    {{ (productionContractNegotiation.productionContract.price > 0) ? '$' : '-$' }}{{  numeral(totalProjectedValue).format('0,000.00') }}
                                </h3> 
                            </div>
                        </div>
                        <div class="d-block d-md-flex pt-2 pb-0" v-if="productionContractNegotiation.productionContract.userID === user.uid">
                            <div class="flex-fill text-center text-md-right">
                                <div v-if="productionContractNegotiation.is_productionContract_negotiation_status == undefined">
                                    <b-button @click="validateStatus('accept')" variant="success">Accept</b-button>
                                    <b-button @click="validateStatus('decline')" variant="danger" class="ml-2">Decline</b-button>
                                </div>
                                <div v-else>
                                    <div class="flex-fill text-center text-md-left pt-2 pb-0">
                                        <b-badge variant="success" pill v-if="productionContractNegotiation.is_productionContract_negotiation_status == 1">Approved</b-badge>
                                        <b-badge variant="danger" pill v-if="productionContractNegotiation.is_productionContract_negotiation_status == 0">Rejected</b-badge>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-block d-md-flex" v-if="productionContractNegotiation.productionContract.userID !== user.uid">
                            <b-button variant="success" @click="backTo"><i class="fa fa-arrow-left mr-2"></i>Back</b-button>
                        </div>
                    </div>
                </div>
                <div class="border-2 bg-white rounded p-4 mt-4" v-if="productionContractNegotiation.productionContract.userID !== user.uid">
                    <h5>Production Contract Negotiation Request</h5>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <p v-if="productionContractNegotiation.is_productionContract_negotiation_status !== undefined">
                                <b-badge variant="success" pill v-if="productionContractNegotiation.is_productionContract_negotiation_status == 1">Approved</b-badge>
                                <b-badge variant="danger" pill v-if="productionContractNegotiation.is_productionContract_negotiation_status == 0">Rejected</b-badge>
                            </p>
                            <p v-else>
                                <b-badge variant="warning" pill>Pending Approval</b-badge>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 ml-6" v-else>
                <div class="col d-none d-md-flex"></div>
                <div class="col-12 col-md-8 col-xl-6 pt-4">
                    <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                        <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                        <template v-if="user.role.producer">
                            <h4 class="card-title mt-0 mb-4">Your production contract negotiation request does not exists please contact NGF team. </h4>
                            <p><a href="/production/contract" class="text-info">Browse Production Contract</a></p>
                            <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                        </template>
                        <template v-else>
                            <h4 class="card-title mt-0 mb-4">You do not have any negotiations production contract at this time.</h4>
                            <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                        </template>
                    </card>
                </div>
                <div class="col d-none d-md-flex"></div>
            </div>
        </div>
        <div>
            <form ref="acceptForm" @submit.prevent="confirmNegotiation">
                <b-modal ref="model" size="lg" 
                    title="Confirmation of Negotiation" centered  v-model="confirmationPopup" 
                    @show="resetConfirmationModel" 
                    @hide="resetConfirmationModel"
                >
                    <div v-if="confirmNegotiationLoading" class="row mt-4">
                        <div class="col d-none d-md-flex"></div>
                        <div class="col-12 col-md-8 col-xl-6 pt-4">
                            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                            <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                            </card>
                        </div>
                        <div class="col d-none d-md-flex"></div>
                    </div>
                    <div v-else>
                        <p>
                            By entering your password, you acknowledge and consent to enter into a legally binding contract with the Farmer, pursuant to the NGF Global <a href="https://www.ngf-global.com/user-agreement" target="_blank">User Agreement</a>
                        </p>
                        <p v-if="confirmationPopupError" class="alert alert-danger mb-4">{{confirmationPopupError}}</p>
                        <ValidationProvider
                            name="confirmationPassword"
                            rules="required|min:6"
                            v-slot="{ passed, failed, errors }"
                            >
                            <b-form-group
                                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                                :state="failed ? false : passed ? true : null"
                            >
                                <b-form-input
                                type="password"
                                placeholder="Please Enter Password"
                                v-model="confirmationPassword"
                                :class="confirmationPopupError ? 'is-invalid' : ''"
                                :state="failed ? false : passed ? true : null"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                <template #modal-footer="{ok,cancel}">
                    <b-button size="xl" :disabled="confirmNegotiationLoading" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button size="xl" :disabled="confirmNegotiationLoading" variant="success" @click="confirmNegotiation">
                        Continue
                    </b-button>
                </template>
                </b-modal>    
            </form>
        </div>
      </div>
    </div>
</template>

<script>

import {db} from '@/firebase';
import { mapState } from 'vuex';
import { bus } from "@/vuex/bus.js";
import {auth} from '@/firebase';
import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import firebase from 'firebase';



extend("required", required);
extend("minimum", {
  params: ["minimum"],
  validate: (amount, {minimum})=>{
    return (parseFloat(amount)>=minimum);
  },
  message:'Minimum'
});

export default {
    computed:{
            ...mapState(['user']),
            FullName:  function(){
                const vm = this;
                return vm.productionContractNegotiation.productionContract.subtype+" " + vm.productionContractNegotiation.productionContract.type
            },
            fullFillAmount: function(){
                return this.productionContractNegotiation.fullFillAmount
            },
            availableAcres: function(){
                const vm = this;
                return vm.productionContractNegotiation.productionContract.accepted_full_fill_amount ?  vm.productionContractNegotiation.productionContract.accepted_full_fill_amount: vm.productionContractNegotiation.productionContract.available_acres
            },
            startDate: function(){
                return this.productionContractNegotiation.productionContract.startDate.toDate();
            },
            endDate: function(){
                return this.productionContractNegotiation.productionContract.endDate.toDate();
            },
            totalProjectedValue: function(){
                const vm = this;
                return Math.abs(vm.productionContractNegotiation.productionContract.total * vm.productionContractNegotiation.fullFillAmount * vm.productionContractNegotiation.productionContract.price) ;
            }

            
        },
    data() {
        return {
            loading: true,
            productionContractNegotiationId: '', 
            productionContractNegotiation: null,
            confirmationPopup: false,
            confirmationPopupError: null,
            confirmationPassword: null,
            confirmNegotiationLoading: false,
            updateStatus: null,
            isSubscribe: false
        }
    },
    async mounted(){
        let vm = this;

        vm.isSubscribe = !vm.user.role.admin && vm.user?.subscription?.stripeSubscription?.status === 'active';
        console.log(vm.isSubscribe)
        if(vm.user.role.admin){
            vm.isSubscribe = true;
        }

        vm.loading = true;

        await vm.getProductionContractNegotiation();
        bus.$emit('prepareTitle', null);
    },
    methods: {
        backTo() {
            bus.$emit("backToTop");
        },
        resetConfirmationModel(){
            const vm = this;
            vm.confirmationPopupError = null;
            vm.confirmationPassword = null;
        },
     


        redirectToSubscriptionPage(){
            const vm = this;
            vm.$router.replace("/profile")
        },

        
        async getProductionContractNegotiation(){
            const vm = this;
            if(vm.$route.params.id) vm.productionContractNegotiationId = vm.$route.params.id;

            let productionContractNegotiation = await db.collection('production_contract_negotiation').doc(vm.productionContractNegotiationId).get();
            if(productionContractNegotiation.exists) vm.productionContractNegotiation = {...productionContractNegotiation.data(),id:productionContractNegotiation.id};

            if(vm.productionContractNegotiation != null){
                let productionContract = await db.collection('production_contract').doc(vm.productionContractNegotiation.productionContractID).get();
                if(productionContract.exists) vm.productionContractNegotiation.productionContract = {...productionContract.data(),id: productionContract.id};

                let ref = await db.collection('units').doc(vm.productionContractNegotiation.productionContract.unit).get();
                if(ref.exists) vm.productionContractNegotiation.productionContract = {...vm.productionContractNegotiation.productionContract,unit:ref.data().name};

                ref = await db.collection('commodity_types').doc(vm.productionContractNegotiation.productionContract.type).get();
                if(ref.exists) vm.productionContractNegotiation.productionContract = {...vm.productionContractNegotiation.productionContract,type:ref.data().name};

                ref = await db.collection('commodity_subtypes').doc(vm.productionContractNegotiation.productionContract.subtype).get();
                if(ref.exists) vm.productionContractNegotiation.productionContract = {...vm.productionContractNegotiation.productionContract,subtype:ref.data().name};

                ref = await db.collection('price_options').doc(vm.productionContractNegotiation.productionContract.priceOption).get();
                if (ref.exists) vm.productionContractNegotiation.productionContract = {...vm.productionContractNegotiation.productionContract, priceOption: ref.data().name}

                ref = await db.collection('users').doc(vm.productionContractNegotiation.producerID).get();
                if(ref.exists) vm.productionContractNegotiation.producer = {...ref.data()};
            }

            setTimeout(() => {
                vm.loading = false;
            }, 2000);

        },
        async validateStatus(status){
            const vm = this;
            if(status == "accept"){
                vm.confirmationPopup = true;
                vm.updateStatus = status;
            } else {
                if(confirm('Are you sure want payment request ' + status + '?')){
                    let ref = db.collection('production_contract_negotiation').doc(vm.productionContractNegotiationId);
                    vm.updateStatus = status;
                    ref.update({is_productionContract_negotiation_status: (status == "accept") ? 1 : 0, negotiation_id: vm.productionContractNegotiationId}).then(async() => {
                        vm.$router.replace('/');
                    });
                }
            }
        },

        async confirmNegotiation(){
            const vm = this;
            if(vm.confirmationPassword != null && vm.confirmationPassword.length >= 6){
                vm.confirmNegotiationLoading = true;
                await auth.signInWithEmailAndPassword(vm.user.about.email, vm.confirmationPassword).then(async() => {
                    let ref = db.collection('production_contract_negotiation').doc(vm.productionContractNegotiationId);
                    ref.update({is_productionContract_negotiation_status: (vm.updateStatus == "accept") ? 1 : 0,negotiation_id: vm.productionContractNegotiationId, is_buyer_verified: true }).then(async() => {
                        await vm.completeProductionContract();
                    });
                }).catch(() => {
                    vm.confirmationPopupError = "This password is invalid."
                    vm.confirmNegotiationLoading = false;
                    setTimeout(() => {
                        vm.confirmationPopupError = null;
                    }, 2000);
                });
            }
        },

        async completeProductionContract(){
            const vm = this;
            let data = {};
            let ref = db.collection('production_contracts_complete').doc();
            let id =  ref.id;
            data.id = id;
            data.productionContractID = vm.productionContractNegotiation.productionContractID;
            data.buyerID = vm.user.uid;
            data.producerID = vm.productionContractNegotiation.producerID;
            data.fullFillAmount = vm.productionContractNegotiation.fullFillAmount;
            data.productionContractNegotiationId = vm.productionContractNegotiation.id;
            data.status = 'completed'
            data.is_restore = null;
            data.subtotal = (Math.abs(vm.productionContractNegotiation.productionContract.total * vm.productionContractNegotiation.fullFillAmount * vm.productionContractNegotiation.productionContract.price))
            data.ngfGlobalFee = (vm.productionContractNegotiation.productionContract.price > 0) ? (data.subtotal / 100) : 0;
            data.total = (data.subtotal + data.ngfGlobalFee);
            data.created = firebase.firestore.FieldValue.serverTimestamp();
            data.updated = firebase.firestore.FieldValue.serverTimestamp();
            data.is_restore = null;
            await db.collection('production_contracts_complete').doc(id).set(data).then(async() =>{
                let ref = await db.collection('production_contract_negotiation').doc(vm.productionContractNegotiation.id);
                ref.delete().then(async() => {
                    await vm.deactivateProductionContract();
                    setTimeout(() => {
                        vm.$router.replace('/');                        
                    }, 3000);
                });   
            });
        },

        async deactivateProductionContract(){
            const vm = this;
            let ref = await db.collection('production_contract')
                              .doc(vm.productionContractNegotiation.productionContractID)
                              .get();
            let productionContract = null;
            if(ref.exists) productionContract = {...ref.data()};
            if(parseInt(productionContract.accepted_full_fill_amount) === 0 && productionContract.accepted_full_fill_amount != undefined) {
                let ref = await db.collection('production_contract').doc(vm.productionContractNegotiation.productionContractID)
                ref.update({'active': false});
            }
        },

    }
}
</script>