<template>
    <div class="container subscription">
      <!-- No Active Subscription Alert -->
      <div v-if="!user.about.is_active" class="mt-5">
        <div class="text-center">
          <b-alert variant="warning" class="mb-4" show>
            <p>Your account is currently deactivated. To reactivate it with your existing subscription, click the button below.</p>
            <b-button variant="primary" @click="redirectToReactiveAccount" size="ml" class="ml-auto">
            Re-Activate Account
          </b-button>
          </b-alert>
        </div>
      </div>

      <div v-if="subscription === null" class="mt-5">
        <div class="text-center">
          <b-alert variant="warning" class="py-4 px-5" show>
            <h4 class="alert-heading mb-3">Become a Member</h4>
            <p class="mb-4">
              To unlock features, please purchase a membership plan.
            </p>
            <hr />
            <b-button variant="success" size="lg" @click="clickedSubscription">
              Purchase Plan
            </b-button>
          </b-alert>
        </div>
      </div>
  
      <!-- Subscription Details Section -->
      <div v-else>
        <b-container>
          <!-- Subscription Header -->
            <!-- Active Plan Card -->
          <div v-show="user.about.is_active">
            <b-row class="mb-4 align-items-center">
              <b-col xs="12" md="6">
                <h2 class="mb-0 text-left">Plan Details</h2>
              </b-col>
              <b-col xs="12" md="6" class="text-md-right mt-3 mt-md-0"> 
                <!-- <b-button variant="danger" v-if="user.about.is_active" size="ml" @click="showDeactivationModal" class="ml-auto">
                  <i class="fa fa-power-off mr-2"></i>
                  Deactivate Account
                </b-button> -->
              </b-col>
            </b-row>

              <b-row>
                <b-col lg="8" md="12" class="mx-auto">
                  <transition name="fade">
                    <b-card title="Active Plan" class="mb-4 shadow">
                      <b-card-text>
                        <b-row>
                          <b-col md="6">
                            <h5>Plan: <span class="font-weight-bold"> {{ user.subscription_plan.name }}</span></h5>
                            <p>Enjoy unlimited access to all features and premium support.</p>
                          </b-col>
                          <b-col md="6" class="text-center">
                            <h5>Status: 
                              <span :class="{
                                'badge badge-success': subscription.stripeSubscription.status == 'active',
                                'badge badge-danger': subscription.stripeSubscription.status == 'canceled'
                              }">
                                {{ subscription.stripeSubscription.status === 'active' ? 'ACTIVE' : 'INACTIVE' }}
                              </span>
                            </h5>
                            <p>
                              Renewal Date: 
                              <span class="font-weight-bold" v-if="subscription.stripeSubscription.status !== 'canceled'">
                                {{ moment.unix(subscription.stripeSubscription.current_period_end).format('LL') }}
                              </span>
                              <span class="font-weight-bold" v-else>
                                N/A
                              </span>
                            </p>
                          </b-col>
                        </b-row>
                        <b-row class="d-flex justify-content-center">
                          <b-col md="6">
                            <h5>Billing Cycle: <span class="font-weight-bold">{{ subscription.stripeSubscription.plan.nickname }}</span></h5>
                            <p>
                              <span class="font-weight-bold">${{ numeral(parseInt(subscription.stripeSubscription.plan.amount_decimal)/100).format('0,0.00') }}</span> per {{ subscription.stripeSubscription.plan.interval }}
                            </p>
                          </b-col>
                        </b-row>
      
                        <!-- Action Buttons -->
                        <b-row>
                          <b-col md="12" class="d-flex justify-content-center subscription-button">
                            <div class="d-inline-flex flex-wrap gap-2">
                              <b-button variant="primary" v-show="subscription.stripeSubscription.status !== 'canceled'" @click="clickedSubscription">
                                <i class="fa fa-refresh mr-2"></i> 
                                Update Plan
                              </b-button>
                              <b-button variant="danger" v-show="subscription.stripeSubscription.status === 'active'" @click="cancelSubscription" class="ml-2">
                                <i class="fa fa-times mr-2"></i> 
                                Cancel Plan
                              </b-button>
                              <b-button 
                                variant="success" 
                                class="ml-2" 
                                @click="reactivateSubscription"
                                :disabled="reactivating"
                                v-show="subscription.stripeSubscription.status === 'canceled'"
                              >
                                <span v-if="reactivating" class="fa fa-circle-o-notch fa-spin mr-1 text-white"></span>
                                <span class="font-weight-bold">
                                  {{ reactivating ? 'Reactivating...' : 'Reactivate My Subscription' }}
                                </span>
                              </b-button>
                            </div>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-card>
                  </transition>
                </b-col>
              </b-row>
  
            <!-- Billing Info Card -->
              <b-row v-if="subscription.stripeSubscription.status === 'active'">
                <b-col lg="8" md="12" class="mx-auto">
                  <b-card title="Billing Information" class="mb-4 shadow">
                    <b-card-text>
                      <b-row>
                        <b-col md="6">
                          <h5>Billing Start Date:</h5>
                          <p><span class="font-weight-bold">{{ moment.unix(subscription.stripeSubscription.created).format('LL') }}</span></p>
                        </b-col>
                        <b-col md="6">
                          <h5>Next Payment Date:</h5>
                          <p><span class="font-weight-bold">{{ moment.unix(subscription.stripeSubscription.current_period_end).format('LL') }}</span></p>
                        </b-col>
                      </b-row>
                      <b-row class="justify-content-center">
                        <b-col md="12" class="d-flex justify-content-center">
                            <b-button variant="primary" @click="showInvoice">
                              <i class="fa fa-print mr-2"></i>
                              View Invoice
                            </b-button>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
              
              <b-row class="fixed-column">
                <b-col md="12" class="d-flex justify-content-center mt-4">
                  <b-button variant="danger" v-if="user.about.is_active" size="ml"  @click="showDeactivationModal" class="ml-auto">
                    <i class="fa fa-power-off mr-2"></i>
                    Deactivate Account
                  </b-button>
                </b-col>
              </b-row>
          </div>
          
        </b-container>
      </div>
  
      <!-- Show Invoice Details Data -->
      <div>
          <b-modal
          id="invoice-modal"
          v-model="showInvoiceModal"
          title="Invoice Details"
          size="lg"
          centered
          hide-footer
          class="invoice-details"
        >
          <b-container class="invoice-container p-4 bg-light rounded">
              <b-row class="mb-4">
                <b-col>
                  <h2 class="font-weight-bold text-primary">Paid On {{ moment.unix(latestInvoiceData.created).format('LL') }}</h2>
                </b-col>
              </b-row>

              <b-row class="summary-section mb-4 border-bottom pb-3">
                <b-col cols="12">
                  <h5 class="font-weight-bold text-secondary">Summary</h5>
                </b-col>
                <b-col cols="12" class="mt-2">
                    <p><strong>To: </strong>{{ latestInvoiceData.customer_name }}</p>
                    <p><strong>From:</strong> {{ latestInvoiceData.account_name }}</p>
                    <p><strong>Invoice number:</strong> {{ latestInvoiceData.number }}</p>
                </b-col>
              </b-row>
              <b-row class="items-section mb-4">
                <b-col cols="12">
                  <h5 class="font-weight-bold text-secondary">Items</h5>
                </b-col>

                <b-col
                  cols="12"
                  v-for="(item, index) in latestInvoiceItems"
                  :key="index"
                  class="item-row border-bottom py-3"
                >
                  <div class="d-flex gap-2">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted">{{ item.description }}</p>
                    </div>
                    <div class="text-right">
                      <p><strong> {{ numeral(item.amount/100).format('$0,0.00') }}</strong></p>
                    </div>
                  </div>
                 
                  
                </b-col>
              </b-row>

              <b-row class="totals-section border-top pt-3">
                <b-col cols="12" class="total-row d-flex justify-content-between align-items-center mb-3">
                  <h5 class="font-weight-bold">Total</h5>
                  <h5 class="font-weight-bold text-success">${{ (latestInvoiceData.total / 100).toFixed(2) }}</h5>
                </b-col>
                <b-col cols="12" class="payment-details">
                  <div class="d-flex justify-content-between mb-2">
                    <p>Amount Paid:</p>
                    <p class="font-weight-bold">${{ (latestInvoiceData.amount_paid / 100).toFixed(2) }}</p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <p>Amount Remaining:</p>
                    <p class="text-danger font-weight-bold">${{ (latestInvoiceData.amount_remaining /100).toFixed(2) }}</p>
                  </div>
                </b-col>
              </b-row>
              <b-row class="text-right mt-4">
                <b-col>
                  <b-button
                    variant="primary"
                    @click="downloadLatestInvoice"
                    class="px-4 py-2"
                  >
                    <i class="fa fa-download mr-2"></i> Download Invoice
                  </b-button>
                </b-col>
              </b-row>
          </b-container>
        </b-modal>
      </div>

      <div>
        <form @submit.prevent="confirmUserDeactivation">
          <b-modal
            id="deactivation-modal"
            title="Confirm Account Deactivation"
            v-model="isShowUserDeactivation"
            size="lg"
            centered
            @show="resetDeactivateConfirmationModel" 
            @hide="resetDeactivateConfirmationModel"
          >   
            <p class="text-center">
              Your account will be deactivated, and your active subscription will be canceled.
            </p>

            <p v-if="deactivationPasswordError" class="alert alert-danger mb-4">
              {{ deactivationPasswordError }}
            </p>

              <ValidationProvider
                name="deactivationPassword"
                rules="required|min:6"
                v-slot="{ passed, failed, errors }"
              >
                  <b-form-group
                      label="Password"
                      label-for="confirmation-password"
                      :invalid-feedback="errors.length > 0 ? errors[0] : null"
                      :state="failed ? false : passed ? true : null"
                      class="mb-3"
                  >
                    <b-form-input
                        id="confirmation-password"
                        type="password"
                        placeholder="Please Enter Password"
                        v-model="deactivationPassword"
                        :class="deactivationPasswordError ? 'is-invalid' : ''"
                        :state="failed ? false : passed ? true : null"
                        required
                    />
                    </b-form-group>
              </ValidationProvider>
                <template #modal-footer="{ ok, cancel }">
                    <b-button size="xl" :disabled="confirmDeactivationLoading" @click="cancel()">Cancel</b-button>
                    <b-button size="xl" :disabled="confirmDeactivationLoading" variant="success" @click="confirmUserDeactivation">
                      <span v-if="confirmDeactivationLoading" class="fa fa-circle-o-notch fa-spin mr-2"></span>
                      Continue
                    </b-button>
                </template>
          </b-modal>
      </form>
      </div>
    

      <!-- Confirmation Modal for Subscription Cancellation -->
      <form @submit.prevent="confirmCancelSub">
        <b-modal 
          ref="modal" 
          size="lg"
          title="Confirmation of Cancel Membership Plan" 
          centered
          v-model="confirmationPopup" 
          @show="resetConfirmationModel" 
          @hide="resetConfirmationModel"
        >
          <div class="text-start mb-3">
            <h5>Are you sure you want to cancel your Membership Plan?</h5>
            <p class="text-muted">
              Please confirm by entering your password. This action cannot be undone.
            </p>
          </div>
  
          <p v-if="confirmationPopupError" class="alert alert-danger mb-4">
            {{ confirmationPopupError }}
          </p>
  
          <ValidationProvider
            name="confirmationPassword"
            rules="required|min:6"
            v-slot="{ passed, failed, errors }"
          >
            <b-form-group
              label="Password"
              label-for="confirmation-password"
              :invalid-feedback="errors.length > 0 ? errors[0] : null"
              :state="failed ? false : passed ? true : null"
            >
              <b-form-input
                id="confirmation-password"
                type="password"
                placeholder="Please Enter Password"
                v-model="confirmationPassword"
                :class="confirmationPopupError ? 'is-invalid' : ''"
                :state="failed ? false : passed ? true : null"
              />
            </b-form-group>
          </ValidationProvider>
  
          <template #modal-footer="{ ok, cancel }">
            <b-button size="xl" :disabled="confirmCancelSubLoading" @click="cancel()">Cancel</b-button>
            <b-button size="xl" :disabled="confirmCancelSubLoading" variant="success" @click="confirmCancelSub">
              <span v-if="confirmCancelSubLoading" class="fa fa-circle-o-notch fa-spin mr-2"></span>
              Continue
            </b-button>
          </template>
        </b-modal>
      </form>
    </div>
  </template>
<script>
import {mapState} from 'vuex'
import { db } from '@/firebase';
// import store from '@/vuex/store.js';
import {auth} from '@/firebase';
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {bus} from '@/vuex/bus.js';
import store from '@/vuex/store.js';
import firebase from 'firebase';

extend("required", required);


export default {
    name: 'subscription',
    data() {
        return {
            subscription: null,
            cancelShowModel: false,
            accounts: null,
            SubscriptionPlans: [],
            reactivating:false,
            confirmationPopup: false,
            confirmationPopupError :  null,
            confirmationPassword: null,
            confirmCancelSubLoading: false,
            showInvoiceModal: false,
            invoicesData: [],
            latestInvoiceData: [],
            latestInvoiceItems:[],
            isShowUserDeactivation: false,
            deactivationPassword: null,
            deactivationPasswordError: null,
            confirmDeactivationLoading: false,
        };
    },

    components:{
        ValidationProvider
    },

    computed:{
        ...mapState(['user']),
    },

    async mounted(){
        const vm = this;
        vm.subscription = (vm.user.subscription?.stripeSubscription) ? vm.user.subscription : null;
        vm.invoicesData = (vm.user.subscription?.invoices) ? vm.user.subscription.invoices : null
        await vm.getSubscriptionList();
        if(vm.user.subscription !== null){
            await vm.loadCardDetails();
        }
        
    },

    methods: {
        async subscribe (subPlanID){
            const vm = this;
            return vm.$router.push(`/checkout/${subPlanID}`);
        },

        async loadCardDetails(){
            const vm = this;

            try {
                const querySnapshot = await db.collection('user_accounts')
                    .where('userID', '==', vm.user.uid)
                    .get();

                    if (querySnapshot.empty) {
                        console.log('No matching accounts found.');
                        return;
                    }
                    vm.accounts = querySnapshot.docs.map(doc => doc.data());
            } catch (error) {
                console.error('Error loading card details:', error);
            }
        },

        clickedSubscription() {
            this.$router.replace('/subscriptions')
        },

        cancelSubscription() {
            const vm = this;
            vm.confirmationPopup = true; 
        },

        async confirmCancelSub(){
            const vm = this;
            vm.confirmCancelSubLoading = true;
            try {
                const isAuth = await auth.signInWithEmailAndPassword(vm.user.about.email, vm.confirmationPassword)
                if (!isAuth) throw new Error("Authentication failed");

                await db.collection('user_subscriptions')
                        .doc(vm.user.uid)
                        .set({ cancelled: true, cancel: true }, { merge: true })
                        
                await vm.sleep(2000);

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            } catch (error) {
                vm.confirmationPopupError = "This password is invalid.";
                vm.confirmCancelSubLoading = false;
                setTimeout(() => {
                    vm.confirmationPopupError = null;
                }, 4000);
            }
        },

        resetConfirmationModel(){
            const vm = this;
            vm.confirmationPopupError = null;
            vm.confirmationPassword = null;
        },

        async reactivateSubscription(){
            const vm = this;
            vm.reactivating=true;
            try {
                await db.collection('user_subscriptions').doc(vm.user.uid).set(
                    {reactivate:true},
                    {merge:true}
                )
                await vm.sleep(3000);
                setTimeout(() => {
                    vm.reactivating=false;                    
                    window.location.reload();
                }, 6000);
            } catch(error){
                vm.reactivating = false;
                console.error("Error reactivating subscription:", error);
            }
        },


        async showInvoice(){
            const vm = this;
            vm.latestInvoiceData = [];
            vm.latestInvoiceItems = [];
            vm.showInvoiceModal = true
            vm.latestInvoiceData = vm.invoicesData.find(item => item.id === vm.subscription.stripeSubscription.latest_invoice);
            vm.latestInvoiceItems = vm.latestInvoiceData.lines.data
            console.log(vm.latestInvoiceItems);
        },

        async downloadLatestInvoice(){
            const vm = this;
            const invoiceUrl  = vm.latestInvoiceData.invoice_pdf
            const link = document.createElement('a');
            link.href = invoiceUrl ;
            link.download = `Invoice_${vm.latestInvoiceData.number}.pdf`;
            link.click();
        },

        async getSubscriptionList(){
            const vm = this; 
            const items = await db.collection('subscription_list').orderBy('name','asc').get();
            items.forEach((item) => {
                vm.SubscriptionPlans.push({
                    name: item.data().name,
                    plan_id: item.data().id,
                    price: item.data().priceDetails[0].unit_amount,
                    duration: item.data().duration,
                    Details: item.data().features
                })
            });
        },

        showDeactivationModal(){
          const vm = this;
          vm.isShowUserDeactivation = true;
        },

        resetDeactivateConfirmationModel(){
            const vm = this;
            vm.deactivationPasswordError = null;
            vm.deactivationPassword = null;
        },

        async confirmUserDeactivation(){
           const vm = this;
            vm.confirmDeactivationLoading = true;
              try {
                  const isAuth = await auth.signInWithEmailAndPassword(vm.user.about.email, vm.deactivationPassword)
                  if (!isAuth) throw new Error("Authentication failed");

                  const userUpdatePromise = db.collection("users")
                        .doc(vm.user.uid)
                        .update({"about.is_active": false, "deactivationDate": firebase.firestore.FieldValue.serverTimestamp()});

                  await Promise.all([userUpdatePromise]);
                  await vm.sleep(1000);
                  setTimeout(() => {
                    auth.signOut().then(() => {
                      bus.$emit('UserLogout');
                      store.commit('updateUser',{});
                      this.$router.replace('/login')
                    });
                  }, 6000);

              } catch (error) {
                  vm.deactivationPasswordError = "This password is invalid.";  
              } 
        },

        redirectToReactiveAccount (){
          this.$router.replace('/re-active/user')
        },
                
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

    }
}

</script>

<style>

.subscription {
  padding: 20px; 
  background-color: #f8f9fa; 
  border: 1px solid #ddd; 
  border-radius: 8px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif; 
  max-width: 600px; 
  margin: 0 auto; 
  text-align: center;
}

.subscription{
  padding: 20px;
}

.subscription{
  font-size: 14px;
}

@media (min-width: 600px) {
    .subscription {
      padding: 30px;
      font-size: 16px;
      max-width: 800px;
    }
}

@media (min-width: 1024px) {
    .subscription {
      padding: 40px;
      font-size: 18px;
      max-width: 1000px;
    }
}

.subscription-button .d-inline-flex .b-button{
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px 20px;
  font-size: 14px;
}

@media (max-width: 576px){
  .subscription-button .d-inline-flex{
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .subscription-button  .b-button{
    width: 100%;
    font-size: 16px;
    padding: 12px;
  }
}

@media (min-width: 576px) and (max-width: 1023px) {
  .subscription-button  .d-inline-flex {
    flex-direction: row; 
    justify-content: center;
    gap: 10px;
  }
  .subscription-button .b-button {
    width: auto;
    font-size: 16px;
    padding: 12px 25px;
  }
}

@media (min-width: 1024px) {
  .subscription-button .d-inline-flex {
    flex-direction: row; /* Buttons will be side by side */
    justify-content: flex-start; /* Align buttons to the left */
    gap: 15px; /* Larger gap for desktop screens */
  }
  .subscription-button .b-button {
    width: auto; /* Let the buttons maintain their default width */
    font-size: 18px; /* Larger font for desktop */
    padding: 14px 30px; /* Comfortable padding for desktop */
  }
}

h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #343a40;
}

h5 {
  font-size: 1.25rem;
  color: #495057;
}

.b-alert {
  margin-top: 30px;
}


.b-alert .alert-heading {
  font-size: 1.75rem;
  font-weight: 700;
  color: #856404;
}

.b-alert p {
  font-size: 1.1rem;
  color: #6c757d;
}

.b-button {
  font-size: 1rem;
  padding: 10px 20px;
}

.b-button-success {
  background-color: #28a745;
  border-color: #28a745;
}

.b-button-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.b-button-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.b-button-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.b-button-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.b-button-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.b-card {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.b-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #343a40;
}

.b-card-text {
  color: #495057;
}

.b-row {
  margin-bottom: 20px;
}

.b-col {
  padding: 15px;
}

.subscription .badge {
  font-size: 1.1rem;
}

.badge-success {
  background-color: #28a745;
}

.badge-danger {
  background-color: #dc3545;
}

.font-weight-bold {
  font-weight: 700;
}

.b-list-group-item {
  background-color: #f8f9fa;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.b-list-group-item:hover {
  background-color: #e9ecef;
}

.b-modal .modal-header {
  border-bottom: 1px solid #ddd;
}

.b-modal .modal-footer {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
}

.b-form-input {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f8f9fa;
}

.b-form-input.is-invalid {
  border-color: #dc3545;
}

.is-invalid {
  border-color: #dc3545 !important;
}

.b-button-xl {
  font-size: 1.25rem;
  padding: 15px 30px;
}

.fa-circle-o-notch {
  font-size: 1.5rem;
}

.ml-2 {
  margin-left: 10px;
}

@media (max-width: 768px) {
  h2 {
    font-size: 2rem;
  }

  .b-card {
    margin-bottom: 15px;
  }

  .b-button {
    width: 100%;
    margin-top: 10px;
  }

  .b-row {
    margin-bottom: 10px;
  }
}


.invoice-details{
  max-width: 900px;
  margin: 0 auto;
}

.item-description {
  font-size: 1rem;
  color: #6c757d; /* Muted color for descriptions */
}

.item-row:last-child {
  border-bottom: none !important; /* Remove border on the last item */
}

.card-wizard .disabled{
  display: block;
}


@media (max-width: 576px) {
  h2 {
    font-size: 1.8rem;
  }

  .b-col {
    padding: 10px;
  }
}

</style>