<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="pl-4 pr-4 pt-0 pt-md-4">
        <h3 class="d-block d-md-none">Business Info</h3>
        <div class="row" v-if="!user.business">
          <div class="col-12 pb-4">
            <ValidationProvider
              name="signup.business.type"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <strong class="mr-4">I am a:</strong>
              <label class="mr-4 text-dark"><input type="radio" v-model="signup.business.type" value="producer" /> Farmer</label>
              <label class="mr-4 text-dark"><input type="radio" v-model="signup.business.type" value="buyer" /> Buyer</label>
              <small class="d-block text-danger" v-if="failed">Please choose your type</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="d-none d-sm-flex col-sm-1 col-md-2"></div>
          <div class="col-12 col-sm-10 col-md-8">
            <ValidationProvider
              name="signup.business.name"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <b-form-group
                :invalid-feedback="'Company name is required'"
                :state="(failed?false:(passed?true:null))"
              >
                <b-form-input placeholder="Company name (required)" v-model="signup.business.name" :state="(failed?false:(passed?true:null))" />
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="d-none d-sm-flex col-sm-1 col-md-2"></div>
        </div>
        <div class="row"><div class="col pt-4 pb-4"><hr /></div></div>
        <div class="row">
          <div class="col-12 col-md-6">
            <ValidationProvider
              name="signup.business.address.address"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <b-form-group
                :invalid-feedback="'Address is required'"
                :state="(failed?false:(passed?true:null))"
              >
                <b-form-input placeholder="Address (required)" v-model="signup.business.address.address" :state="(failed?false:(passed?true:null))" />
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-12 col-md-6">
            <ValidationProvider
              name="signup.business.address.city"
              :rules="{required:true,regex:/^[A-Za-z\s.,'-]+$/}"
              v-slot="{ passed, failed }"
            >
              <b-form-group
                :invalid-feedback="'Please enter a valid city'"
                :state="(failed?false:(passed?true:null))"
              >
                <b-form-input placeholder="City (required)" v-model="signup.business.address.city" :state="(failed?false:(passed?true:null))" />
              </b-form-group>

            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3">
            <ValidationProvider
              name="signup.business.address.state"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <b-form-group
                :invalid-feedback="'State is required'"
                :state="(failed?false:(passed?true:null))"
              >
                <b-form-select v-model="signup.business.address.state" :options="stateOptions" :state="(failed?false:(passed?true:null))"></b-form-select>
              </b-form-group>

            </ValidationProvider>
          </div>
          <div class="col-12 col-md-3">
            <ValidationProvider
              name="signup.business.address.zip"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <b-form-group
                :invalid-feedback="'Zip is required'"
                :state="(failed?false:(passed?true:null))"
              >
                <b-form-input placeholder="Zip (required)" v-model="signup.business.address.zip" :state="(failed?false:(passed?true:null))" />
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-12 col-md-6">
            <ValidationProvider
              name="signup.business.address.country"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <b-form-group
                :invalid-feedback="'Country is required'"
                :state="(failed?false:(passed?true:null))"
              >
                <b-form-select v-model="signup.business.address.country" :options="countryOptions" :state="(failed?false:(passed?true:null))"></b-form-select>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import {mapState} from 'vuex'
import { Select, Option } from "element-ui";
import { extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);
extend("regex", regex);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      signup:{
        business:{
          type:null,
          name:null,
          address:{
            address:null,
            city:null,
            state:null,
            zip:null,
            country:'United States'
          }
        }
      },
      stateOptions: [
        {value:null,text:"State (required)"},
        {value:"Alaska",text:"Alaska"},
        {value:"Alabama",text:"Alabama"},
        {value:"Arkansas",text:"Arkansas"},
        {value:"Arizona",text:"Arizona"},
        {value:"California",text:"California"},
        {value:"Colorado",text:"Colorado"},
        {value:"Connecticut",text:"Connecticut"},
        {value:"District of Columbia",text:"District of Columbia"},
        {value:"Delaware",text:"Delaware"},
        {value:"Florida",text:"Florida"},
        {value:"Georgia",text:"Georgia"},
        {value:"Hawaii",text:"Hawaii"},
        {value:"Iowa",text:"Iowa"},
        {value:"Idaho",text:"Idaho"},
        {value:"Illinois",text:"Illinois"},
        {value:"Indiana",text:"Indiana"},
        {value:"Kansas",text:"Kansas"},
        {value:"Kentucky",text:"Kentucky"},
        {value:"Louisiana",text:"Louisiana"},
        {value:"Massachusetts",text:"Massachusetts"},
        {value:"Maryland",text:"Maryland"},
        {value:"Maine",text:"Maine"},
        {value:"Michigan",text:"Michigan"},
        {value:"Minnesota",text:"Minnesota"},
        {value:"Missouri",text:"Missouri"},
        {value:"Mississippi",text:"Mississippi"},
        {value:"Montana",text:"Montana"},
        {value:"North Carolina",text:"North Carolina"},
        {value:"North Dakota",text:"North Dakota"},
        {value:"Nebraska",text:"Nebraska"},
        {value:"New Hampshire",text:"New Hampshire"},
        {value:"New Jersey",text:"New Jersey"},
        {value:"New Mexico",text:"New Mexico"},
        {value:"Nevada",text:"Nevada"},
        {value:"New York",text:"New York"},
        {value:"Ohio",text:"Ohio"},
        {value:"Oklahoma",text:"Oklahoma"},
        {value:"Oregon",text:"Oregon"},
        {value:"Pennsylvania",text:"Pennsylvania"},
        {value:"Puerto Rico",text:"Puerto Rico"},
        {value:"Rhode Island",text:"Rhode Island"},
        {value:"South Carolina",text:"South Carolina"},
        {value:"South Dakota",text:"South Dakota"},
        {value:"Tennessee",text:"Tennessee"},
        {value:"Texas",text:"Texas"},
        {value:"Utah",text:"Utah"},
        {value:"Virginia",text:"Virginia"},
        {value:"Vermont",text:"Vermont"},
        {value:"Washington",text:"Washington"},
        {value:"Wisconsin",text:"Wisconsin"},
        {value:"West Virginia",text:"West Virginia"},
        {value:"Wyoming",text:"Wyoming"}
      ],
      countryOptions: ["United States","Canada","Mexico"]
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        if(res) this.$emit("on-validated", this.signup);
        return res;
      });
    }
  },
  computed:{
    ...mapState(['user'])
  },
  mounted(){
    if(this.user.business){
      this.signup.business = {...this.user.business};
    }
  }
};
</script>

<style>
.default-button{
    display: block;
}
</style>
