<template>
    <div class="container">
        <div v-if="!isSubscribe">
            <b-container class="d-flex justify-content-center align-items-center  min-vh-50">            
                    <b-card
                        class="text-center shadow-lg membership-card"
                        header="Membership Required"
                        header-bg-variant="light"
                        style="max-width: 480px;"
                    >
                            <template #header>
                                <div class="membership-header membership-header-font-size">Membership Required</div>
                            </template>
                        <b-card-text class="membership-text mb-4">
                            Become a Member to enjoy these services.
                        </b-card-text>
                        <b-button 
                            variant="primary" 
                            class="membership-button" 
                            @click="redirectToSubscriptionPage"
                        >
                            Become a Member
                        </b-button>
                    </b-card>
            </b-container>
        </div>
        <div v-else>
            <div v-if="loading" class="row mt-4">
                <div class="col d-none d-md-flex"></div>
                <div class="col-12 col-md-8 col-xl-6 pt-4">
                    <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                    </card>
                </div>
                <div class="col d-none d-md-flex"></div>
            </div>
            <div class="row" v-else>
                <div class="col-12" v-if="productNegotiation != null">
                    <div>
                        <div class="border-2 bg-white rounded p-4">
                            <div class="d-block d-md-flex">
                                <div class="flex-fill text-center text-md-left text-muted">User ID: {{ productNegotiation.buyerID.substr(0, 10) }}</div>
                            </div>
                            <b-row align-v="center">
                                <b-col cols="12" md="6" class="text-center text-md-left">
                                    <h6 class="font-weight-bold">
                                        Purchase of {{numeral(productNegotiation.amount).format('0,000')}} {{ productNegotiation.commodity.unit }}
                                        {{ productNegotiation.commodity.subtype }} {{ productNegotiation.commodity.type }}
                                    </h6>
                                </b-col>
                                <b-col cols="12" md="6" class="text-center text-md-right">
                                    <strong>Total:</strong>
                                    <h3 class="d-inline text-primary">${{ numeral(productNegotiation.total).format('0,000.00') }}</h3>
                                </b-col>
                            </b-row>
                            <div class="d-bloc d-md-flex">
                                <div class="flex-fill text-center text-md-left text-muted" v-if="productNegotiation.commodity.userID === user.uid">Request Offer price: ${{ numeral(productNegotiation.offer_price).format('0,000.00') }}</div>
                                <div class="flex-fill text-center text-md-left text-muted" v-if="productNegotiation.commodity.userID !== user.uid">Offer price: ${{ numeral(productNegotiation.offer_price).format('0,000.00') }}</div>
                            </div>
                            <div class="d-block d-md-flex" v-if="productNegotiation.commodity.userID === user.uid">
                                <div class="flex-fill text-center text-md-left text-muted"> Original Price Offer:  ${{ numeral(productNegotiation.commodity.price).format('0,000.00') }} </div>  
                            </div>
                            <div class="d-block d-md-flex" v-if="productNegotiation.commodity.userID === user.uid">
                                <p>
                                    <small v-if="productNegotiation.paymentOption == '100_at_purchase'">Payment Request: <strong>100% at time of Purchase</strong></small>
                                    <small v-else-if="productNegotiation.paymentOption == '50_at_purchase'">Payment Request: <strong>Buyer and Seller Negotiated Payment Split</strong></small>
                                    <small v-else-if="productNegotiation.paymentOption == '100_at_delivery'">Payment Request: <strong>100% at time of Delivery</strong></small>                            
                                </p>
                                <div class="flex-fill text-center text-md-right">
                                    <div v-if="productNegotiation.is_product_negotiation_status == undefined">
                                        <b-button @click="validateStatus('accept')" variant="success">Accept</b-button>
                                        <b-button @click="validateStatus('decline')" variant="danger" class="ml-2">Decline</b-button>
                                    </div>
                                    <div v-else>
                                        <div>
                                            <b-badge variant="success" pill v-if="productNegotiation.is_product_negotiation_status == 1">Approved</b-badge>
                                            <b-badge variant="danger" pill v-if="productNegotiation.is_product_negotiation_status == 0">Rejected</b-badge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-block d-md-flex" v-if="productNegotiation.commodity.userID !== user.uid && !user.role.admin">
                                <b-button variant="success" :disabled="productNegotiation.is_product_negotiation_status == 1" @click="editProductNegotiation">Edit</b-button>
                                <b-button variant="success" @click="myProductNegotiation"><i class="fa fa-arrow-left mr-2"></i>Back to My Negotiations</b-button>
                            </div>
                        </div>
                    </div>
                    <div class="border-2 bg-white rounded p-4 mt-4" v-if="productNegotiation.commodity.userID !== user.uid">
                        <h5>Payment Negotiation Request</h5>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p>
                                    <small v-if="productNegotiation.paymentOption == '100_at_purchase'">Payment Request: <strong>100% at time of Purchase</strong></small>
                                    <small v-else-if="productNegotiation.paymentOption == '50_at_purchase'">Payment Request: <strong>Buyer and Seller Negotiated Payment Split</strong> </small>
                                    <small v-else-if="productNegotiation.paymentOption == '100_at_delivery'">Payment Request: <strong>100% at time of Delivery</strong></small>                            
                                </p>
                            </div>
                            <div class="col-12 col-md-6">
                                <p v-if="productNegotiation.is_product_negotiation_status !== undefined">
                                    <b-badge variant="success" pill v-if="productNegotiation.is_product_negotiation_status == 1">Approved</b-badge>
                                    <b-badge variant="danger" pill v-if="productNegotiation.is_product_negotiation_status == 0">Rejected</b-badge>
                                </p>
                                <p v-else>
                                    <b-badge variant="warning" pill>Pending Approval</b-badge>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 ml-6" v-else>
                    <div class="col d-none d-md-flex"></div>
                    <div class="col-12 col-md-8 col-xl-6 pt-4">
                        <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                        <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                        <template v-if="user.role.buyer">
                            <h4 class="card-title mt-0 mb-4">Your payment negotiation request does not exists please contact NGF team. </h4>
                            <!-- <p><a href="/product/grain/cart" class="text-info">Browse Grain cart</a></p> -->
                            <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                        </template>
                        <template v-else>
                            <h4 class="card-title mt-0 mb-4">You do not have any negotiations at this time.</h4>
                            <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                        </template>
                        </card>
                    </div>
                    <div class="col d-none d-md-flex"></div>
                </div>
            </div>
            <div>
                <form ref="acceptForm" @submit.prevent="confirmNegotiation">
                    <b-modal ref="model" size="lg" 
                    title="Confirmation of Negotiation" centered  v-model="confirmationPopup" 
                    @show="resetConfirmationModel" 
                    @hide="resetConfirmationModel"
                    >
                    <div v-if="confirmNegotiationLoading" class="row mt-4">
                    <div class="col d-none d-md-flex"></div>
                    <div class="col-12 col-md-8 col-xl-6 pt-4">
                        <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                        <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                        </card>
                    </div>
                    <div class="col d-none d-md-flex"></div>
                    </div>
                    <div v-else>
                    <p>
                        By entering your password, you acknowledge and consent to enter into a legally binding contract with the Buyer, pursuant to the NGF Global <a href="https://www.ngf-global.com/user-agreement" target="_blank">User Agreement</a>, for the purchase of the product listed.
                    </p>
                    <p v-if="confirmationPopupError" class="alert alert-danger mb-4">{{confirmationPopupError}}</p>
                    <ValidationProvider
                        name="confirmationPassword"
                        rules="required|min:6"
                        v-slot="{ passed, failed, errors }"
                        >
                        <b-form-group
                            :invalid-feedback="errors.length > 0 ? errors[0] : null"
                            :state="failed ? false : passed ? true : null"
                        >
                            <b-form-input
                            type="password"
                            placeholder="Please Enter Password"
                            v-model="confirmationPassword"
                            :class="confirmationPopupError ? 'is-invalid' : ''"
                            :state="failed ? false : passed ? true : null"
                            />
                        </b-form-group>
                        </ValidationProvider>
                    </div>
                    <template #modal-footer="{ok,cancel}">
                        <b-button size="xl" :disabled="confirmNegotiationLoading" @click="cancel()">
                            Cancel
                        </b-button>
                        <b-button size="xl" :disabled="confirmNegotiationLoading" variant="success" @click="confirmNegotiation">
                            Continue
                        </b-button>
                    </template>
                    </b-modal>    
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import {db} from '@/firebase';
import Vue from "vue";
import moment from 'moment';
import { mapState } from 'vuex';
import firebase from 'firebase';
import {auth} from '@/firebase';
import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { bus } from "@/vuex/bus.js";


extend("required", required);
extend("minimum", {
  params: ["minimum"],
  validate: (amount, {minimum})=>{
    return (parseFloat(amount)>=minimum);
  },
  message:'Minimum'
});


Vue.prototype.moment = moment;

    export default {
        computed:{
            ...mapState(['user']),
        },
        data() {
            return {
                loading: true,
                productNegotiationId: '',
                productNegotiation: null,
                confirmationPopup: false,
                confirmationPopupError: null,
                confirmationPassword: null,
                confirmNegotiationLoading: false,
                updateStatus: null,
                isSubscribe: false
            }
        },
        async mounted() {
            let vm = this;

            vm.isSubscribe = !vm.user.role.admin && vm.user?.subscription?.stripeSubscription?.status === 'active';
            if(vm.user.role.admin){
                vm.isSubscribe = true;
            }


            vm.loading = true;
            vm.getProductNegotiation();
            bus.$emit('prepareTitle', null);
        },
        methods: {
            resetConfirmationModel(){
                this.confirmationPopupError = null;
                this.confirmationPassword = null;
            },

            
            redirectToSubscriptionPage(){
                const vm = this;
                vm.$router.replace("/profile")
            },

            // async addUpdateStatusInsertMessage(status){
            //     const vm = this;
            //     let ref = db.collection('inbox').doc();
            //     let inboxID = ref.id;
            //     let data = {
            //         producerID: vm.user.uid,
            //         commodityID: vm.productNegotiation.commodityID,
            //         product_negotiation_id: vm.productNegotiationId,
            //         entity: 'product_negotiation',
            //         from: vm.user.uid,
            //         to: vm.productNegotiation.buyerID,
            //         created: firebase.firestore.FieldValue.serverTimestamp(),
            //         title: "STATUS",
            //         unread: true,
            //     }
            //     data.type = (status === "decline") ? "alert":"update",
            //     data.text = `Your payment negotiation request was ${status}`
            //     await db.collection('inbox').doc(inboxID).set(data);
            // },
            async validateStatus(status){
                const vm = this;
                if(status == "accept"){
                    vm.confirmationPopup = true;
                    vm.updateStatus = status;
                } else {
                    if(confirm('Are you sure want payment request ' + status + '?')){
                        let ref = db.collection('products_negotiation').doc(vm.productNegotiationId);
                        ref.update({is_product_negotiation_status: (status == "accept") ? 1 : 0, is_hide_paymentOption: 1, negotiation_id: vm.productNegotiationId, is_buyer_verified: true}).then(() => {
                            // vm.addUpdateStatusInsertMessage(status);
                            vm.$router.replace('/');
                        });
                    }
                }
            },
          
            async confirmNegotiation(){
                const vm = this;
                if(vm.confirmationPassword != null && vm.confirmationPassword.length >= 6){
                    vm.confirmNegotiationLoading = true;
                    let isAuth = await auth.signInWithEmailAndPassword(vm.user.about.email, vm.confirmationPassword).then(async() => {
                            return true;
                        }).catch(() => {
                            vm.confirmationPopupError = "This password is invalid."
                            vm.confirmNegotiationLoading = false;
                                setTimeout(() => { vm.confirmationPopupError = null; }, 4000);
                            return;
                        });
                    console.log(isAuth);

                    if(isAuth){
                        let ref = db.collection('products_negotiation').doc(vm.productNegotiationId);
                        ref.update({is_product_negotiation_status: (vm.updateStatus == "accept") ? 1 : 0, is_hide_paymentOption: 1, negotiation_id: vm.productNegotiationId}).then(async() => {
                            // vm.addUpdateStatusInsertMessage("accept");
                            await vm.acceptNegotiationRequest();
                        });
                    }
                }
            },

            async acceptNegotiationRequest(){
                const vm = this;
                let ref = await db.collection('orders').doc();
                let orderID = ref.id;
                let data = {};
                data.id = orderID;
                data.status = "completed";
                data.buyerID = vm.productNegotiation.buyerID;
                data.commodityID = vm.productNegotiation.commodityID;
                data.producerID = vm.productNegotiation.producerID;
                data.subtotal = vm.productNegotiation.total;
                data.ngfGlobalFee = (vm.productNegotiation.total / 100);
                data.total = vm.productNegotiation.total + (vm.productNegotiation.total / 100);
                data.is_restore = null;
                data.paymentOption = vm.productNegotiation.paymentOption
                data.amount = vm.productNegotiation.amount
                data.currency = vm.productNegotiation.currency
                data.offer_price = vm.productNegotiation.offer_price
                data.created =  firebase.firestore.FieldValue.serverTimestamp();
                data.update = firebase.firestore.FieldValue.serverTimestamp();
                data.productNegotiationID = vm.productNegotiation.id;
                await db.collection('orders').doc(orderID).set(data).then(async() => {
                    let ref =  await db.collection('products_negotiation').doc(vm.productNegotiation.id);
                    ref.delete().then(async() => {
                        await vm.storeAndUpdateTotalAmount();
                        setTimeout(() => {
                            vm.$router.replace('/');
                        }, 5000);
                    })
                })
            },

            async storeAndUpdateTotalAmount(){
                const vm = this;
                await db.collection("accepted_amounts")
                                .doc().set({
                                    buyerID: vm.user.uid,
                                    amount: vm.productNegotiation.amount,
                                    commodityID: vm.productNegotiation.commodityID,
                                    commodityNegotiationID: vm.productNegotiationId
                                });

                let acceptAmountRef = await db.collection("accepted_amounts")
                    .where("commodityID", "==", vm.productNegotiation.commodityID)
                    .get()

                let acceptedAmount = acceptAmountRef.docs
                    .filter((o) => !o.data().cancelled)
                    .map((o) => {
                        return { amount: o.data().amount };
                    });

                let ref = await db.collection("commodities")
                    .doc(vm.productNegotiation.commodityID)
                    .get();
                                
                let commodity = null;
                if (ref.exists) { commodity = { ...ref.data() }; }

                let total = parseFloat(commodity.total);
                acceptedAmount.forEach((o) => (total = total - parseFloat(o.amount)));
                db.collection("commodities").doc(vm.productNegotiation.commodityID).update({accepted_amount: total})
            },

            async editProductNegotiation(){
                this.$router.replace("/commodity/view/" + this.productNegotiation.commodityID + "/" + this.productNegotiation.id);
            },
            async myProductNegotiation(){
                this.$router.replace("/products/negotiation/")
            },
            async getProductNegotiation(){
                let vm = this;
                if(vm.$route.params.id) vm.productNegotiationId = vm.$route.params.id;
            
                let product = await db.collection('products_negotiation').doc(vm.productNegotiationId).get();
                if(product.exists) vm.productNegotiation = {...product.data(),id:product.id};


                if(vm.productNegotiation != null){
                    let commodity = await db.collection('commodities').doc(vm.productNegotiation.commodityID).get();
                    if(commodity.exists) vm.productNegotiation.commodity = {...commodity.data(),id:commodity.id};

                    let ref = await db.collection('commodity_categories').doc(vm.productNegotiation.commodity.category).get();
                    if(ref.exists) vm.productNegotiation.commodity = {...vm.productNegotiation.commodity,category:ref.data().name};

                    ref = await db.collection('units').doc(vm.productNegotiation.commodity.unit).get();
                    if(ref.exists) vm.productNegotiation.commodity = {...vm.productNegotiation.commodity,unit:ref.data().name};

                    // ref = await db.collection('price_options').doc(vm.productNegotiation.commodity.priceOption).get();
                    // if (ref.exists)vm.productNegotiation.commodity = {...vm.productNegotiation.commodity, priceOption: ref.data().name}

                    ref = await db.collection('commodity_types').doc(vm.productNegotiation.commodity.type).get();
                    if(ref.exists) vm.productNegotiation.commodity = {...vm.productNegotiation.commodity,type:ref.data().name};

                    ref = await db.collection('commodity_subtypes').doc(vm.productNegotiation.commodity.subtype).get();
                    if(ref.exists) vm.productNegotiation.commodity = {...vm.productNegotiation.commodity,subtype:ref.data().name};

                    ref = await db.collection('users').doc(vm.productNegotiation.buyerID).get();
                    if(ref.exists) vm.productNegotiation.buyer = {...ref.data()};
                }

                setTimeout(() => {
                    vm.loading = false;
                }, 5000);
            }
        },
    }
</script>