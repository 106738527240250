var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-6 col-lg-4 mx-auto re-active-login"},[_c('div',{staticClass:"text-center mb-2"},[_c('img',{staticClass:"img-fluid loginHeaderImage",attrs:{"slot":"header","src":"/img/logo3.png"},slot:"header"})]),_c('b-card',{staticClass:"reactive-login-card shadow-sm bg-white"},[_c('b-card-header',{staticClass:"card-header bg-primary text-center"},[_c('h4',[_vm._v("Re-Activate User")])]),_c('b-card-body',[_c('b-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.ReActiveLoginError),expression:"ReActiveLoginError"}],staticClass:"mb-4 rounded-lg shadow-lg",attrs:{"variant":"danger","dismissible":"","show":""}},[_vm._v(" "+_vm._s(_vm.ReActiveLoginError)+" ")]),(_vm.accountReactivated    )?_c('b-alert',{staticClass:"mb-4 rounded-lg shadow-lg",attrs:{"variant":"success","dismissible":"","show":""}},[_vm._v(" Your account has been successfully reactivated! ")]):_vm._e(),(!_vm.accountReactivated)?_c('ValidationObserver',{ref:"ReactiveForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitReActiveUser($event)}}},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":"email","rules":"emailRequired|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors.length>0?errors[0]:null,"state":(failed?false:(passed?true:null))}},[_c('b-form-input',{attrs:{"placeholder":"Email (required)","state":(failed?false:(passed?true:null))},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})],1)]}}],null,false,1061237412)})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors.length > 0 ? errors[0] : null,"state":failed ? false : passed ? true : null}},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":_vm.passwordType,"placeholder":"Password","state":failed ? false : passed ? true : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"seconder","aria-label":_vm.passwordType === 'password' ? 'Show Password' : 'Hide Password'},on:{"click":_vm.togglePasswordVisibility}},[_c('i',{class:_vm.passwordIcon})])],1)],1)],1)]}}],null,false,4168905997)})],1),_c('b-button',{staticClass:"rounded-pill",attrs:{"type":"submit","variant":"primary","block":"","disable":_vm.processing}},[(_vm.processing)?_c('span',[_c('b-spinner',{staticClass:"ml-2",attrs:{"small":""}}),_vm._v(" Processing.... ")],1):_c('span',[_vm._v(" Re-Activate ")])])],1)],1):_vm._e(),(_vm.accountReactivated)?_c('b-button',{staticClass:"mt-4 rounded-pill",attrs:{"variant":"success","block":""},on:{"click":_vm.redirectToLogin}},[_vm._v(" Go to Login Page ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }