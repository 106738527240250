import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import '@/assets/sass/now-ui-dashboard.scss'

import router from '@/lib/router.js';

import store from '@/vuex/store.js';
import {auth,db} from '@/firebase';

import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "@/components/NotificationPlugin";
import SideBar from "@/components/SidebarPlugin";
import VueCurrencyInput from "vue-currency-input";


import { StripePlugin } from '@vue-stripe/vue-stripe';


Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.use(SideBar);
Vue.use(VueCurrencyInput);


const options = {
  pk: process.env.VUE_APP_STRIPE_KEY,
};


Vue.use(StripePlugin, options);


let app = '';
auth.onAuthStateChanged(async(user)=>{
  try{
    const subscriptionsRef = await db.collection('settings').doc('subscriptions').get();
    if(subscriptionsRef.exists) store.commit('updateSubscriptions',Object.assign({...subscriptionsRef.data()}));

    const timeoutRef = await db.collection('settings').doc('timeout').get();
    if(timeoutRef.exists) store.commit('updateTimeout', Object.assign({...timeoutRef.data()}));

    const limitOfMilesRef = await db.collection('settings').doc('limitOfMiles').get();
    if(limitOfMilesRef.exists) store.commit('updateLimitOfMiles', Object.assign({...limitOfMilesRef.data()}));

    if(user){
        const token = await user.getIdTokenResult();
        const res = await db.collection("users").doc(user.uid).get();

        if(res.exists) user = {...res.data(),uid:user.uid};

        if (token) {
          user.role = {
            producer: token.claims.producer || false,
            buyer: token.claims.buyer || false,
            admin: token.claims.admin || false,
          };
        }

        if(!user.role.producer&&!user.role.buyer&&!user.role.admin){
          user = {...user,...{
            role:{
              [user.business.type]:true
            }
          }};
        }

        const userSubscriptionRef = await db.collection("user_subscriptions").doc(user.uid).get();
        
        if (userSubscriptionRef.exists) {
          user.subscription = { ...userSubscriptionRef.data() };
          user.subscription_plan = null;
          if(user.subscription.stripeSubscription){
            const planRef = await db.collection("subscription_list").doc(user.subscription.stripeSubscription.plan.product).get();
            if(planRef.exists){
              user.subscription_plan = {...planRef.data()}
            }
          }
        } else {
          user.subscription = null;
          // Uncomment if you want to initialize an empty subscription document
          // await db.collection("user_subscriptions").doc(user.uid).set({});
        }
        store.commit('updateUser',Object.assign({...user}));
    }
  } catch(error){
    console.error('Error loading data:', error);
  }

  if(!app) {
    app = new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
  }
});
