<template>
    <div class="container mt-4 membership-plan">
        <div>
            <div v-if="loading" class="text-center">
                <b-spinner label="Loading subscription plans..."></b-spinner>
            </div>

            <div v-else>
                <p class="h4 text-center text-primary mb-4 font-weight-bold">
                    Choose Your Membership Plan
                </p>
                <b-card-group deck class="d-flex flex-wrap justify-content-center plan-card">
                    <b-card
                        v-for="(item, index) in SubscriptionPlans"
                        :key="index"
                        class="mx-2 my-3 shadow-lg"
                        style="max-width: 22rem;"
                        :class="{
                            'selected-plan': isCurrentPlan(item.plan_id),
                            'default-plan': !isCurrentPlan(item.plan_id),
                        }"
                    >
                        <template #header>
                            <b-card-header
                                class="p-2 text-center text-white text-uppercase bg-primary rounded-top"
                            >   
                                {{ item.name }}
                            </b-card-header>
                        </template>

                        <b-card-text class="px-3 py-4">
                            <p class="text-center text-primary mb-1 h1 font-weight-bold">
                                $<span>{{ numeral(item.price / 100).format('0,0.00') }}</span> / {{ item.duration }}
                            </p>
                            <div class="border-top mt-4 mb-4"></div>
                            <ul v-if="item.Details.length" class="list-unstyled text-center">
                                <li
                                    v-for="(feature, idx) in item.Details"
                                    :key="idx"
                                    class="font-weight-bold text-uppercase mb-2"
                                >
                                    {{ feature }}
                                </li>
                            </ul>
                        </b-card-text>

                        <template #footer>
                            <b-button
                                variant="primary"
                                class="btn rounded w-100 shadow-sm"
                                :disabled="isCurrentPlan(item.plan_id)"
                                @click="clickCheckout(item)"
                            >
                                {{ isCurrentPlan(item.plan_id) ? "Current Plan" : "Confirm Plan" }}
                            </b-button>
                        </template>

                    </b-card>
                </b-card-group>

                <div class="text-center mt-4">
                    <b-button variant="secondary" @click="goBack" size = "lg" class="w-50">
                        <i class="fa fa-arrow-left mr-2"></i>
                        Back
                    </b-button>
                </div>
            </div>        
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import { db } from '@/firebase';
import {bus} from '@/vuex/bus.js';


export default {
    data() {
        return {
            SubscriptionPlans: [],
            selectedPlanName: '',
            loading: false
        }
    },
    computed:{
        ...mapState(['user'])
    },
    
    created(){
        this.loading = true;
    },

    async mounted(){
        const vm = this;
        await vm.getSubscriptionList();
        vm.loading = false;
        bus.$emit('prepareTitle',null)
    },

    methods: {
        isCurrentPlan(planId) {
            return this.user.subscription_plan != null && planId === this.user.subscription_plan.id;
        },

        clickCheckout(item) {
            const vm = this;
            vm.selectedPlanName = item.name;
            vm.selectedPlan = item.plan_id
            vm.$router.replace(`/checkout/${this.selectedPlan}`);
        },

        async getSubscriptionList(){
            const vm = this; 
            const items = await db.collection('subscription_list').orderBy('name','asc').get();
            items.forEach((item) => {
                vm.SubscriptionPlans.push({
                    name: item.data().name,
                    plan_id: item.data().id,
                    price: item.data().priceDetails[0].unit_amount,
                    duration: item.data().priceDetails[0].nickname,
                    Details: item.data().features
                })
            });
        },
        goBack() {
            this.$router.replace('/profile')
        }

    }
}
</script>

<style>

.membership-plan .card-deck .card{
    flex: 1 1 20rem;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.membership-plan .card .card-header{
    padding: 0.75rem 2rem;
}

.membership-plan .card:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15)
}

.plan-card {
    gap: 1rem;
} 

.membership-plan .b-card-footer .btn{
    font-weight: bold;
    width: 100%;
    transition: background-color 0.3s ease-in-out;
}

.membership-plan .b-card-footer .btn:hover {
    background-color: #007bff;
}

.membership-plan .text-center .btn {
    font-weight: bold;
    padding: 0.75rem 2rem;
}

@media (max-width: 576px){
    .membership-plan .card {
        max-width: 100%;
        margin: 10px 0;
    }
    .membership-plan .h4 {
        font-size: 1.2rem;
    }
    .membership-plan .b-card-footer .btn {
        font-size: 0.9rem;
    }
}

@media (min-width: 577px) and (max-width: 992px) {
    .membership-plan .card {
        max-width: 100%;
    }
    .membership-plan .h4 {
        font-size: 1.4rem;
    }
    .membership-plan .b-card-footer .btn {
        font-size: 1rem;
    }
}

@media (min-width: 993px) {
    .membership-plan .card {
        max-width: 22rem;
        margin: 10px;
    }
    .membership-plan .h4 {
        font-size: 1.5rem;
    }
    .membership-plan .b-card-footer .btn {
        font-size: 1.1rem;
    }
}

.membership-plan .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
}

.membership-plan .btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}
</style>
