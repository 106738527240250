<template>
    <div class="container">
        <div class="align-items-center">
            <div v-if="loading" class="row mt-4">
                <div class="col d-none d-md-flex"></div>
                <div class="col-12 col-md-8 col-xl-6 pt-4">
                    <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                    </card>
                </div>
                <div class="col d-none d-md-flex"></div>
            </div>
            <div v-else class="bg-white" style="max-width:55rem">
                        <div class="row py-3 m-0 justify-content-between border-bottom">
                            <div class="col-md-12 text-center col-12">
                                <div class="logo-image mb-2">
                                    <img :src="logo" style="border-radius: 50%;max-width: 100px;"/>
                                </div>
                                <h6 >AgHub
                                </h6>
                            </div>
                        </div>
                      <div class="row m-0">
                        <div class="col-md-8 col-12 pl-4 d-flex flex-column justify-content-center py-3" style="background:#94cc53;">
                            <h2 class="m-0">INVOICE</h2>
                            <p class="m-0">Payment Option</p>
                        </div>
                        <div class="col-md-4 col-12 d-flex flex-column justify-content-center text-center  py-2" style="background: #76a343">
                            <p class="m-0 text-white">Amount Due</p>
                            <div class="total_amt text-white h2 m-0">
                                $235.00
                            </div>
                        </div>
                      </div>
                    
                      <div class="row m-0 py-3" >
                        <div class="col-md-8 col-12 pl-4">
                            <h6>BILL TO:</h6>
                            <p class="m-0">ADG,<br>
                            ed richardson 3613 Pearl Court,<br>
                            Navarre,<br>
                            </p>
                            <p class="m-0"> Florida 32566</p>
                            <p class="m-0">United States</p>
                            <p class="m-0">850-313-4416</p>
                            <p class="m-0"><a href="mailto:adg.chairman@gmail.com" class="s5">adg.chairman@gmail.com</a></p>
                        </div>
                        <div class="col-md-4 col-12" >
                            <h6>Invoice Details:</h6>
                            <p class="m-0">Invoice Number: <span class="s3">108 - 2</span></p>
                            <p>Invoice Date: <span class="s3">January 10, 2023</span></p>
                            <h6 >Payment instructions:</h6>
                            <p class="m-0">Routing <span># 063102152</span></p>
                            <p class="m-0">Account <span># ********48517</span></p>
                            <p >Payment Due: <span class="s3">January 25, 2023</span></p>
                        </div>
                      </div>
                        
                      <div class="row m-0 px-3" >
                <table  class="table invoice table-striped table-borderless m-0" style="border:1px solid #dee0e1;" cellspacing="0">
                   
                 
                    <tr style=" background: #76a343;" class="text-white ">
                        <td class="px-3 py-2"  style="width:239pt;">
                            <p class="m-0" >PRODUCTS</p>
                        </td>
                        <td class="text-center" style="width:125pt;">
                            <p class="m-0" >QUANTITY</p>
                        </td>
                        <td class="text-center" >
                            <p class="m-0">PRICE</p>
                        </td>
                        <td style="width:99pt;" class="text-center" >
                                <p class="m-0 px-3 text-right" >AMOUNT</p>
                        </td>
                    </tr>
                    <tr >
                        <td class="px-3 py-2" style="width:239pt;">
                            <h6 class="mb-0 text-capitalized">Purchase Item(s)</h6>
                            <p style="white-space: nowrap; width: 150px; overflow: hidden; text-overflow: ellipsis; " class="mb-1" >Item description here</p>
                            <p class="mb-0 "><small><b>Seller</b>: Seller Name</small></p>
                            <p class="mb-0" ><small><b>Listing ID: </b>ksurfi7hw4fiu4f</small></p>
                        </td>
                        <td class="text-center align-middle" style="width:125pt;">
                            <p class="m-0">
                                8000</p>
                        </td>
                        <td class="text-center align-middle"  style="width:149pt;" >
                            <p class="m-0">$8.00</p>
                        </td>
                        <td class="m-0 px-3 text-right align-middle"  style="width:99pt;">
                            <p class="m-0 px-3 text-right" >
                                $64,000.00</p>
                        </td>
                    </tr>
                    <tr >
                        <td class="px-3 py-2" style="width:239pt;">
                            <h6 class="mb-0 text-capitalized">Purchase Item(s)</h6>
                            <p style="white-space: nowrap; width: 150px; overflow: hidden; text-overflow: ellipsis; " class="mb-1" >Item description here</p>
                            <p class="mb-0 "><small><b>Seller</b>: Seller Name</small></p>
                            <p class="mb-0" ><small><b>Listing ID: </b>ksurfi7hw4fiu4f</small></p>
                        </td>
                        <td class="text-center align-middle" style="width:125pt;">
                            <p class="m-0">
                                8000</p>
                        </td>
                        <td class="text-center align-middle"  style="width:149pt;" >
                            <p class="m-0">$8.00</p>
                        </td>
                        <td class="m-0 px-3 text-right align-middle"  style="width:99pt;">
                            <p class="m-0 px-3 text-right" >
                                $64,000.00</p>
                        </td>
                    </tr>
                    
                    <tr>
                        <td class="px-3 py-2" style="width:239pt;">
                            <h6 class="mb-0 text-capitalized">Purchase Item(s)</h6>
                            <p style="white-space: nowrap; width: 150px; overflow: hidden; text-overflow: ellipsis; " class="mb-1" >Item description here</p>
                            <p class="mb-0 "><small><b>Seller</b>: Seller Name</small></p>
                            <p class="mb-0" ><small><b>Listing ID: </b>ksurfi7hw4fiu4f</small></p>
                        </td>
                        <td class="text-center align-middle" style="width:125pt;">
                            <p class="m-0">
                                8000</p>
                        </td>
                        <td class="text-center align-middle"  style="width:149pt;" >
                            <p class="m-0">$8.00</p>
                        </td>
                        <td class="m-0 px-3 text-right align-middle"  style="width:99pt;">
                            <p class="m-0 px-3 text-right" >
                                $64,000.00</p>
                        </td>
                    </tr>
                    </table>
                   
                    
                </div>  
                <div class="row m-0 justify-content-end">
                        <div class="col-md-6 col-12">
                            <table class="table  table-borderless">
                                    <tr >
                                        <td class="m-0 px-3 text-right align-middle">
                                            <p class="m-0" >Subtotal:</p>
                                        </td>
                                        <td class="m-0 px-3 text-right align-middle">
                                            <p  class="m-0" >$138,991.00</p>
                                        </td>
                                    </tr>
                                    <tr >
                                        
                                        <td class="m-0 px-3 text-right align-middle">
                                            <p  class="m-0" >50% Payment Option Split:</p>
                                        </td>
                                        <td class="m-0 px-3 text-right align-middle">
                                            <p  class="m-0" >
                                                ($69,495.50)</p>
                                        </td>
                                    </tr>
                                    <tr >
                                    
                                        <td class="m-0 px-3 text-right align-middle">
                                            <p  class="m-0" >P-B 1%:
                                            </p>
                                        </td>
                                        <td class="m-0 px-3 text-right align-middle">
                                            <p class="m-0" >$694.96
                                            </p>
                                        </td>
                                    </tr>
                                    <tr class="border-top">
                                    
                                        <td class="m-0 px-3 text-right align-middle">
                                            <h5 class="m-0" >Total:</h5>
                                        </td>
                                        <td class="m-0 px-3 text-right align-middle">
                                            <h5 class="m-0" >$70,190.46</h5>
                                        </td>
                                    </tr>
                                    <tr class="border-top">
                                        <td class="m-0 px-3 text-right align-middle">
                                            <p  class="m-0" >Amount
                                                Due (USD):</p>
                                        </td>
                                        <td class="m-0 px-3 text-right align-middle">
                                            <p class="m-0" >
                                                $70,190.46</p>
                                        </td>
                                    </tr>
                                </table>
                        </div>
                    </div>

                <div class="row py-3 m-0 border-top border-bottom">
                    <div class=" pl-4 col-md-6 col-12">
                        <h6 class="m-0">Notes / Terms:</h6>
                    </div>
                </div>
               
                <div class="py-3 text-center bg-gray">
                    <a href="http://www.ngf-global.com/">www.ngf-global.com</a>
                    <p class="mb-0" >To review all
                        Terms and Conditions, follow the link below:<br /> <a href="https://ngf-global.com/wpautoterms/user-agreement/">https://ngf-global.com/wpautoterms/user-agreement/</a></p>
                    <p class="mb-0">We are Smart
                        Agribusiness</p>
                
               </div>
                    
            </div>
        </div>
        
    </div>
</template>
<style >
.invoice tr:nth-child(even){
    background: #F4F5F5;
}
.bg-gray{
    background: #e7e7e7;
}
</style>
<script>

import { db } from '@/firebase';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { mapState } from 'vuex'
import numeral from 'numeral';
import moment from 'moment';
import Vue from "vue";


Vue.prototype.moment = moment;
Vue.prototype.numeral = numeral;

export default {
    name:"invoice",
    props: {
        logo: {
      type: String,
      default: "/img/logo@2x.png"
    }
    },
    mounted(){
        this.getMyInvoice();
    },
    computed:{
        ...mapState(['user']),
        sortedGrainOrder: function(){
            return [...this.myGrainOrders].sort((a, b) => {
                return b.created.toDate() - a.created.toDate();
            });
        }
    },
    data() {
        return {
            loading: false,
            total: 0,
            myInvoice: [],
            order: [],
            orders: [],
            invoice: [],
            errors: [],
            completePurchaseButton: false,
            removeButton: false,
        }
    },
    methods: {
        formatDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },
        formatExpiredDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')>1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')>1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')>1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },
        getUniqueValueFromArray(orders){      
            return [
                ...new Map(
                    orders.map((item) => [item['id'], item])
                ).values()
            ]
        },
        myDashboard(){
            this.$router.replace('/');
        },
        
        async getMyInvoice(){
            let vm = this;
            vm.loading = true;
            vm.myInvoice = [];
            vm.total = 0;
            let ref = db.collection('invoices');
                ref = ref.where('buyerID', '==', vm.user.uid);
                collectionData(ref, 'id').pipe(
                    switchMap((orders) => {
                        if(orders.empty) return of([]);
                        return combineLatest(...orders.map((order) => {
                            return docData(db.collection('commodities').doc(order.commodityID)).pipe(
                                    map(c=>{
                                        if(JSON.stringify(c) !== '{}'){
                                            return {...order, commodity: c}
                                        } else {
                                            return of({});
                                        } 
                                    })
                                )
                            })
                        )
                    })
                ).pipe(
                    switchMap((orders) => {
                        if(orders.empty) return of([]);
                        return combineLatest(...orders.map((order) => {
                            return docData(db.collection('commodity_types').doc(order.commodity.type)).pipe(
                                    map(c=>{
                                        if(JSON.stringify(c) !== '{}'){
                                            return {...order, commodity:{...order.commodity, type: c}}
                                        } else {
                                            return of({});
                                        }
                                    })
                                )
                            })
                        )
                    })
                ).pipe(
                    switchMap((orders) => {
                        if(orders.empty) return of([]);
                        return combineLatest(...orders.map((order) => {
                            return docData(db.collection('commodity_subtypes').doc(order.commodity.subtype)).pipe(
                                    map(c=>{
                                        if(JSON.stringify(c) !== '{}'){
                                            return {...order, commodity:{...order.commodity, subtype: c}}
                                        } else {
                                            return of({});
                                        }
                                    })
                                )
                            })
                        )
                    })
                ).pipe(
                    switchMap((orders) => {
                        if(orders.empty) return of([]);
                        return combineLatest(...orders.map((order) => {
                            return docData(db.collection('units').doc(order.commodity.unit)).pipe(
                                    map(c=>{
                                        if(JSON.stringify(c) !== '{}'){
                                            return {...order, commodity:{...order.commodity, unit: c}}
                                        } else {
                                            return of({});
                                        }
                                    })
                                )
                            })
                        )
                    })
                ).subscribe((orders) => {
                    if(orders.length != 0){
                        vm.total = 0
                        orders.map((order) => {
                            vm.total = vm.total + numeral(order.total).value();
                            vm.myGrainOrders.push(order);
                        });
                    }
                })
                setTimeout(() => {
                    vm.loading = false;
                }, 2000);
        }
    }

}
</script>