<template>
    <div class="container allUser">
        <div class="row">
            <div class="col-12 p-0 ">
                <b-card class="rounded border-2">
                    <b-card-header>
                        <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                            <h5 class="m-2 text-white">All Users</h5>
                            <div>
                                <b-input-group class=" mb-3 mb-md-0">
                                    <template #append>
                                        <b-input-group-text><i class="fa fa-search"></i></b-input-group-text>
                                    </template>
                                    <b-form-input type="text" class="bg-white" v-model.trim="searchQuery"
                                        placeholder="Search"></b-form-input>
                                </b-input-group>
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body class="p-0">
                        <b-list-group>
                            <template v-if="filteredList.length != 0">
                                <b-list-group-item @click="openUserDetails(user)" v-for="(user) in filteredList"
                                    :key="user.id"
                                    class="d-block d-md-flex align-items-center justify-content-between text-md-left">
                                    <div class="text-md-left pl-0 pr-0 pr-md-4">
                                        <div v-if="(user.about)">
                                            <div>User ID: <span><strong>{{ user.id }}</strong></span></div>
                                            <strong>{{ user.about.first + " " + user.about.last }}</strong>
                                            <div class=" text-muted" v-if="user.business">{{ user.business.type }}</div>
                                            <div class="buss-name-width text-muted" v-if="user.business">{{
                                                user.business.name }}</div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <div class="">
                                            <div v-if="user.about.is_active == undefined">
                                                <button size="sm" @click.stop="updateUsersStatus(true, user)" type='button'
                                                    :class="'btn btn-' + (false ? 'primary' : 'outline-primary')"><i
                                                        :class="'fa fa-' + (false ? 'toggle-on' : 'toggle-off') + ' mr-2'"></i>
                                                    ACTIVE</button>
                                                <b-button variant="danger" class="ml-2"
                                                    @click.stop="deleteUser(user)">Delete</b-button>
                                            </div>
                                            <div v-else>
                                                <button size="sm"
                                                    @click.stop="updateUsersStatus(!user.about.is_active, user)"
                                                    type='button'
                                                    :class="'btn btn-' + (user.about.is_active ? 'primary' : 'outline-primary')"><i
                                                        :class="'fa fa-' + (user.about.is_active ? 'toggle-on' : 'toggle-off') + ' mr-2'"></i>
                                                    ACTIVE</button>
                                                <b-button variant="danger" class="ml-2"
                                                    @click.stop="deleteUser(user)">Delete</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-list-group-item>
                            </template>
                            <template v-else>
                                <b-list-group-item>
                                    <p class="text-muted m-0">No User Found</p>
                                </b-list-group-item>
                            </template>
                        </b-list-group>
                    </b-card-body>
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>

import { db } from '@/firebase';
import { collectionData } from 'rxfire/firestore';
import {mapState} from 'vuex'



export default {
    data(){
        return{
            users: [],
            active: false,
            searchQuery: '',
        }
    }, 
    mounted(){
        if(!this.user.role.admin){
            this.$router.replace("/not/access")
        }
        this.getUsers();
    },
    computed: {
        ...mapState(['user']),
        filteredList() {
            const query = this.searchQuery.trim().toLowerCase();

            if (!query) return this.users;

            return this.users.filter(user => {
            if (!user.about) return false;

            const { id, about, business } = user;
            return (
                    id.toString().toLowerCase().includes(query) ||
                    `${about.first} ${about.last}`.toLowerCase().includes(query) ||
                    business?.name?.toLowerCase().includes(query)
                );
            });
        }
    },

    methods: {
        async getUsers(){
            let vm = this;
            let ref = await db.collection("users")
            collectionData(ref, 'id').subscribe((users) => {
                vm.users = users;
            });
        }, 
        async updateUsersStatus(status, user){
            const userStatus = status ? "Active" : "De-Active";
            
            const confirmed = await this.$bvModal.msgBoxConfirm(
                `Are you sure you want to set this user to ${userStatus}?`, 
                {
                    title: 'Update Status Confirmation',
                    size: 'sm',
                    okTitle: 'Yes',
                    okVariant: 'primary',
                    cancelTitle: 'No',
                    cancelVariant: 'secondary',
                    centered: true
                }
            );
            
            if(confirmed){
                await db.collection("users").doc(user.id)
                .update({"about.is_active": status}).then(() => {
                    this.getUsers();
                });
            }
        }, 
        async deleteUser(user){
            const confirmed = await this.$bvModal.msgBoxConfirm(
                'Are you sure you want to delete this user?', 
                {
                    title: 'Delete Confirmation',
                    size: 'sm',
                    okTitle: 'Yes',
                    okVariant: 'danger',
                    cancelTitle: 'No',
                    cancelVariant: 'secondary',
                    centered: true
                }
            );
            if (confirmed) {
                await db.collection("users").doc(user.id).delete();
                this.getUsers();
            }
        },
        openUserDetails(user){
            this.$router.push('/user/view/' + user.id);
        }
    },
}
</script>
<style>


@media screen and (max-width:767px) {
    .buss-name-width {
        width: 260px;
        max-width: 100%;
    }
}

</style>