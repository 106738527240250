<template>
  <div>
    <b-card class="border-2 bg-white rounded">
      <b-row class="align-items-center">
        <b-col md="6" class="text-center text-md-left text-muted" v-if="order.producerID">
          Owner ID: {{ order.producerID.substr(0, 10).toUpperCase() }}
        </b-col>
        <b-col md="6" class="text-center text-md-right text-success font-weight-bold">
          {{ moment(order.created).format("ll") }}
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-2">
        <b-col>
          <h2 class="d-none d-md-block">Trans. ID: {{ order.id.toUpperCase() }}</h2>
          <p class="d-block d-md-none text-center">
            <span class="text-muted">Trans. ID:</span><br /><strong>{{ order.id.toUpperCase() }}</strong>
          </p>
        </b-col>
      </b-row>
      <b-row class="align-items-center">
        <b-col md="6" class="text-center text-md-left pt-3">
          <h6>
            Purchase of {{ numeral(order.amount).format("0,000") }}
            {{ order.commodity.unit }} {{ order.commodity.subtype }} {{ order.commodity.type }}
          </h6>
        </b-col>
        <b-col md="6" class="text-md-right text-center p-3">
          <b-row>
            <b-col><strong class="text-muted">Subtotal:</strong> <span>${{ numeral(order.subtotal).format("0,000.00") }}</span></b-col>
          </b-row>
          <b-row>
            <b-col><strong class="text-muted">+ AgHub Fee:</strong> <span class="text-warning">${{ numeral(order.ngfGlobalFee).format("0,000.00") }}</span></b-col>
          </b-row>
          <b-row>
            <b-col><strong class="text-dark">Total:</strong> <span class="font-weight-bold text-success">${{ numeral(order.total).format("0,000.00") }}</span></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-3 align-items-center">
        <b-col md="8">
          <h6>Payment Negotiation Option</h6>
          <p class="mb-0">
            {{
              order.paymentOption === "100_at_purchase"
                ? "100% at time of purchase".toUpperCase()
                : order.paymentOption === "100_at_delivery"
                ? "100% at time of delivery".toUpperCase()
                : order.paymentOption === "50_at_purchase"
                ? "Buyer and Seller Negotiated Payment Split".toUpperCase()
                : "Please Contact Customer Support for Payment Options"
            }}
          </p>
        </b-col>
        <b-col md="4" class="text-md-right text-center">
          <b-badge variant="success" pill>Approved</b-badge>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <b-button :href="'/commodity/view/' + order.commodityID" target="_blank" variant="primary" size="sm">
            <span class="fa fa-info-circle mr-2"></span> View Product Details
          </b-button>
          <b-button v-if="user.role.admin" :href="'/search/purchase/order/' + order.id" target="_blank" variant="info" size="sm" class="ml-md-2">
            <span class="fa fa-info-circle mr-2"></span> View Purchase Details
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="user.role.admin" class="border-2 bg-white rounded mt-4">
      <h5>More Details</h5>
      <b-row>
        <b-col md="4" v-if="order.producer && order.producer.about">
          <small class="text-muted d-block">Farmer</small>
          <strong class="d-block">{{ order.producer.about.first }} {{ order.producer.about.last }}</strong>
          <strong class="d-block">{{ order.producer.business.name }}</strong>
          <strong class="d-block">{{ order.producer.business.address.address }}</strong>
          <strong class="d-block">{{ order.producer.business.address.city }}, {{ order.producer.business.address.state }} {{ order.producer.business.address.zip }}</strong>
          <strong class="d-block">{{ order.producer.about.email }}</strong>
          <strong class="d-block">{{ order.producer.about.phone }}</strong>
        </b-col>

        <b-col md="4" v-if="order.buyer && order.buyer.about">
          <small class="text-muted d-block">Buyer</small>
          <strong class="d-block">{{ order.buyer.about.first }} {{ order.buyer.about.last }}</strong>
          <strong class="d-block">{{ order.buyer.business.name }}</strong>
          <strong class="d-block">{{ order.buyer.business.address.address }}</strong>
          <strong class="d-block">{{ order.buyer.business.address.city }}, {{ order.buyer.business.address.state }} {{ order.buyer.business.address.zip }}</strong>
          <strong class="d-block">{{ order.buyer.about.email }}</strong>
          <strong class="d-block">{{ order.buyer.about.phone }}</strong>
          <b-button variant="info" pill @click="viewBuyerDetails(order.buyerID)">View Buyer Details</b-button>
        </b-col>

        <b-col cols="12" md="4" v-if="order.commodity.location">
          <div>
            <small class="text-muted d-block">Location</small>
            <strong class="d-block">
              {{
                order.commodity.location.formatted_address
                  ? order.commodity.location.formatted_address
                  : order.commodity.location
              }}
            </strong>
            <gmap-map
              v-if="order.commodity.location.position"
              :center="order.commodity.location.position"
              :zoom="12"
              class="mt-2"
              style="width:100%;height: 320px;"
              :options="{streetViewControl: false}" 
            >
              <gmap-marker
                :position="order.commodity.location.position"
              ></gmap-marker>
            </gmap-map>
            <a
              v-if="order.commodity.location.url"
              :href="order.commodity.location.url"
              target="_blank"
              class="d-block mt-3 text-primary"
            >
              <strong>
                <span class="fa fa-external-link"></span> View Full Map
              </strong>
            </a>
            
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import moment from "moment";
import Vue from "vue";
import numeral from "numeral";
import { mapState } from "vuex";
Vue.prototype.numeral = numeral;
Vue.prototype.moment = moment;
export default {
  props: ["order"],
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    viewBuyerDetails(buyerID) {
      this.$router.replace("/search/buyer/" + buyerID);
    },
  },
};
</script>
