<template>
  <div>
  <!-- <h5>Select what type of product to add.</h5>
  <b-tabs content-class="mt-3">
    <b-tab title="Commodity Crops" active>    -->
      <div v-if="!isSubscribe">
          <b-container class="d-flex justify-content-center align-items-center  min-vh-50">            
                  <b-card
                      class="text-center shadow-lg membership-card"
                      header="Membership Required"
                      header-bg-variant="light"
                      style="max-width: 480px;"
                  >
                      <template #header>
                          <div class="membership-header membership-header-font-size">Membership Required</div>
                      </template>
                      <b-card-text class="membership-text mb-4">
                        Become a Member to enjoy these services.
                      </b-card-text>
                      <b-button 
                        variant="primary" 
                        class="membership-button" 
                        @click="redirectToSubscriptionPage"
                      >
                        Become a Member
                      </b-button>
                  </b-card>
          </b-container>
      </div>
      <div v-else>
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <div class="container">
              <div class="row" v-if="$route.params.id">
                <div class="d-block d-md-none col-6"><b-button @click="backTo" variant="primary"><i class="fa fa-chevron-left mr-2"></i> Back</b-button></div>
                <div v-if="!saved" class="col-6 col-md-12 text-right">
                  <button @click="updateCommodityStatus(commodity.active)" type='button' :disabled="error" :class="'btn btn-'+(commodity.active?'primary':'outline-primary')"><i :class="'fa fa-'+(commodity.active?'toggle-on':'toggle-off')+' mr-2'"></i> ACTIVE</button>
                </div>
              </div>
              <div v-if="saved" class="row mt-4">
                <div class="col d-none d-md-flex"></div>
                <div class="col-12 col-md-8 col-xl-6 pt-4">

                  <card v-if="$route.params.id" class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <h4 v-if="!commodity.active" class="card-title mt-0 mb-4">Your listing is saved. You must upload a Standard Grade Test certification for it to go live!</h4>
                    <h4 v-else class="card-title mt-0 mb-4">Your listing is saved!</h4>
                    <p><a @click="backTo" class="text-info">Return</a></p>
                  </card>
                  <card v-else class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <h4 v-if ="!commodity.active" class="card-title mt-0 mb-4">Your listing is not live you must upload a Standard Grade Test certification!</h4>
                    <h4 v-else class="card-title mt-0 mb-4">Your listing is live!</h4>
                    <p><a href="/commodity/new" class="text-info">Create another commodity listing</a></p>
                    <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                  </card>

                </div>
                <div class="col d-none d-md-flex"></div>
              </div>
              <div v-else class="row">
                <div class="col-12 col-md-6">
                  <b-row v-if="photosFailed" class="my-4">
                    <b-col cols="12">
                      <b-alert variant="danger" show dismissible>
                        <strong>Error!</strong>Your listing must include at least 1 photo.
                      </b-alert>
                    </b-col>
                  </b-row>
                  <!-- <div class="row" v-if="photosFailed">
                    <div class="col-12"><div class="alert alert-danger">Your listing must include at least 1 photo.</div></div>
                  </div> -->
                  <div class="row">
                    <div class="col-12">
                      <b-aspect class="w-100" :aspect="'16:9'">
                        <CommodityImage
                          v-if="selectedImage"
                          v-bind:key="selectedImage.id+'large'"
                          :selectedFile="selectedImage"
                          type="large"
                        />
                      </b-aspect>
                    </div>
                  </div>
                  <div class="row no-gutters mt-2">
                    <div class="col-1 text-center pt-4"><a @click="$refs.thumbnailCarousel.prev()"><i class="text-secondary fa fa-chevron-left"></i></a></div>
                    <div class="col-10">
                      <b-carousel
                        class="mt-2 image-carousel"
                        ref="thumbnailCarousel"
                        :interval="0"
                      >
                      <b-carousel-slide v-for="(chunk,chunkIndex) in imageChunks" v-bind:key="chunkIndex">
                        <template #img>
                          <div class="row">
                            <CommodityImage
                              v-for="(image,index) in chunk"
                              :disabled="disableImageSelection"
                              :key="(image.name?image.id:'empty'+index)"
                              @select="selectImage"
                              :selectedFile="image"
                              type="thumbnail"
                            ></CommodityImage>
                          </div>
                        </template>
                      </b-carousel-slide>
                      </b-carousel>
                    </div>
                    <div class="col-1 text-center pt-4"><a @click="$refs.thumbnailCarousel.next()"><i class="text-secondary fa fa-chevron-right"></i></a></div>
                  </div>
                  <div class="row no-gutters mt-2">
                    <div class="col-12 text-center">
                      <a @click="showLearnMore=true" class="text-success"><b><i class="fa fa-info-circle mr-2" />Learn more about taking great photos</b></a>
                    </div>
                    <b-modal size="lg" ref="learn-more-modal" v-model="showLearnMore" hide-footer title="Taking Great Photos">
                      <div class="d-block text-center">
                        <h2 class="text-primary text-uppercase font-weight-bold">Setting the Scene</h2>
                        <h5>Grab a plain white piece of paper</h5>
                        <p class="bg-light p-4 rounded">A plain white background is best for most of your product shots. This lets your product really take the spotlight and removes any distractions. Also, using a standard white surface background allows for your product's colors to truly shine.</p>
                        <h5>Get your lighting right</h5>
                        <p class="bg-light p-4 rounded">Natural lighting is free, and great to work with for photography. Grab a table or stool and place it near a window or open doorway. If it's cloudy, it may be nice to photograph your products outside. It's also handy to tape your paper to your table or stool to make it easier to work with if you are photographing multiple products. Do not photograph your products under harsh sunlight, which will create hard shadows and affect the coloring of your final photograph. If it's a cloudless day, work indoors.</p>
                        <h5>Choosing Your Camera</h5>
                        <p class="bg-light p-4 rounded">New smartphones take great product photos, and are easy to use. Generally speaking, the newer and nicer the phone, the better. Use your own or borrow one from a friend, family member, or colleague.</p>
                        <h2 class="text-primary text-uppercase font-weight-bold">The Basics</h2>
                        <p class="bg-light p-4 rounded">Once you’ve got a phone with an appropriate camera, and a table set up near the window with a white piece of paper, the next step is to think about how to use it all well.</p>
                        <h5>Choose the highest quality/resolution setting you can</h5>
                        <p class="bg-light p-4 rounded">Unlike film, space here doesn’t cost you anything. Choose the largest file size and the highest resolution setting your phone allows. This will give you the best image possible to start with, and you can reduce file size when you edit.</p>
                        <h5>Clean your lens</h5>
                        <p class="bg-light p-4 rounded">Lenses are easy to carry everywhere in pockets and purses. Make sure you clean your camera lens well before your photo session. Dust, dirt and smudges on your lens will be super noticeable.</p>
                        <h5>Turn the flash off</h5>
                        <p class="bg-light p-4 rounded">The flash on your phone is…not so good. Get your lighting right and let your flashlight remain a flashlight, because it’s no good for photography.</p>
                        <h5>Don’t zoom in</h5>
                        <p class="bg-light p-4 rounded">Digital zoom is not great for picture quality, and it’s the only type of zoom you’ve got with a phone camera. Instead, bring the phone close to your products to take close up shots.</p>
                        <h5>Shoot multiple angles and details</h5>
                        <p class="bg-light p-4 rounded">Customers will want to see as much as they can before they make a commitment. As the price goes up, so does their need for knowledge. Make sure to provide lots of high-quality images (with the white background) of your most products to help make the sale.</p>
                        <h5>Don't overdo it.</h5>
                        <p class="bg-light p-4 rounded">Don't use your phone to edit images, if anything, stick to simple cropping. Less is more. If you are touching up the photos, adding filters, and over-editing, it makes your product look dishonest and can ruin the trust customers have in your products. Instead, focus on showcasing your products in a simple, direct way. High quality, honest images will impart the high-quality, honest brand you want for your products.</p>
                        <h2 class="text-primary text-uppercase font-weight-bold">Sample Photos</h2>
                        <div class="container">
                          <div class="row">
                            <div class="col-12 col-md-6"><b-img-lazy class="mt-4" thumbnail fluid src="https://firebasestorage.googleapis.com/v0/b/ngfconnect-live.appspot.com/o/img%2Fsample-1.jpg?alt=media" /></div>
                            <div class="col-12 col-md-6"><b-img-lazy class="mt-4" thumbnail fluid src="https://firebasestorage.googleapis.com/v0/b/ngfconnect-live.appspot.com/o/img%2Fsample-2.jpg?alt=media" /></div>
                          </div>
                          <div class="row">
                            <div class="col-12 col-md-6"><b-img-lazy class="mt-4" thumbnail fluid src="https://firebasestorage.googleapis.com/v0/b/ngfconnect-live.appspot.com/o/img%2Fsample-3.jpg?alt=media" /></div>
                            <div class="col-12 col-md-6"><b-img-lazy class="mt-4" thumbnail fluid src="https://firebasestorage.googleapis.com/v0/b/ngfconnect-live.appspot.com/o/img%2Fsample-4.jpg?alt=media" /></div>
                          </div>
                          <div class="row">
                            <div class="col-12 col-md-6"><b-img-lazy class="mt-4" thumbnail fluid src="https://firebasestorage.googleapis.com/v0/b/ngfconnect-live.appspot.com/o/img%2Fsample-5.jpg?alt=media" /></div>
                            <div class="col-12 col-md-6"><b-img-lazy class="mt-4" thumbnail fluid src="https://firebasestorage.googleapis.com/v0/b/ngfconnect-live.appspot.com/o/img%2Fsample-6.jpg?alt=media" /></div>
                          </div>
                          <div class="row">
                            <div class="col d-none d-md-flex"></div>
                            <div class="col-12 col-md-6"><b-img-lazy class="mt-4" thumbnail fluid src="https://firebasestorage.googleapis.com/v0/b/ngfconnect-live.appspot.com/o/img%2Fsample-7.jpg?alt=media" /></div>
                            <div class="col d-none d-md-flex"></div>
                          </div>
                        </div>
                      </div>
                    </b-modal>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <h3 class="m-0">General</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 pt-2">
                      <ValidationProvider
                        name="commodity.category"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <b-form-group
                          label="Category"
                          :invalid-feedback="'Category is required'"
                          :state="(failed?false:(passed?true:null))"
                        >
                          <b-form-select @change="categoryChange" placeholder="Choose category" v-model="commodity.category" :options="optionCategories" :state="(failed?false:(passed?true:null))"></b-form-select>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6 pt-2">
                      <ValidationProvider
                        name="commodity.year"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <b-form-group
                          label="Production year"
                          :invalid-feedback="'Year is required'"
                          :state="(failed?false:(passed?true:null))"
                        >
                          <b-form-select placeholder="Choose year" v-model="commodity.year" :options="sortedYears" :state="(failed?false:(passed?true:null))"></b-form-select>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 pt-2">
                      <ValidationProvider
                        name="commodity.month"
                        rules="required"
                        v-slot="{passed, failed}"
                      >
                        <b-form-group
                          label="Production Month"
                          :invalid-feedback="'Month is required'"
                          :state="(failed?false:(passed?true:null))"
                        >
                          <b-form-select
                            placeholder="choose Month"
                            v-model="commodity.month"
                            :options="generateMonths()"
                            :state="(failed?false:(passed?true:null))"
                          ></b-form-select>
                        </b-form-group>

                      </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6 pt-2">
                      <ValidationProvider
                        name="commodity.type"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <b-form-group
                          label="Product"
                          :invalid-feedback="'Product is required'"
                          :state="(failed?false:(passed?true:null))"
                        >
                          <b-form-select @change="typeChange" :disabled="optionTypes.length==0" placeholder="Choose product" v-model="commodity.type" :options="optionTypes" :state="(failed?false:(passed?true:null))"></b-form-select>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                   
                    <div class="col-12 col-md-6 pt-2">
                      <ValidationProvider
                        name="commodity.subtype"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <b-form-group
                          label="Type"
                          :invalid-feedback="'Type is required'"
                          :state="(failed?false:(passed?true:null))"
                        >
                          <b-form-select @change="subTypeChange" :disabled="optionSubTypes.length==0" placeholder="Choose type" v-model="commodity.subtype" :options="optionSubTypes" :state="(failed?false:(passed?true:null))"></b-form-select>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 pt-2">
                      <b-form-group
                        label="Variety"
                      >
                        <b-form-input placeholder="Enter variety" v-model="commodity.variety" />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 pt-2"><hr /></div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 pt-2">
                      <ValidationProvider
                        name="commodity.unit"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <b-form-group
                          label="Unit of Measure"
                          :invalid-feedback="'Unit is required'"
                          :state="(failed?false:(passed?true:null))"
                        >
                          <b-form-select placeholder="Choose units" v-model="commodity.unit" :options="optionUnits" :state="(failed?false:(passed?true:null))"></b-form-select>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6 pt-2">
                      <ValidationProvider name="commodity.expires" rules="required" v-slot="{ passed, failed }">
                        <b-form-group label="Expires On"
                          :invalid-feedback="'Expires is required'"
                          :state="(failed?false:(passed?true:null))"
                        >
                          <Datepicker v-model="commodity.expires"   :disabledDates="disabledExpiresDates" :state="(failed?false:(passed?true:null))"/>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 pt-2">
                      <ValidationProvider
                        name="commodity.total"
                        :rules="{required:true,decimal:true,minimum:{minimum:minimumQuantity}}"
                        v-slot="{ passed, failed, errors }"
                      >
                        <b-form-group
                          :label="'Total'+(selectedUnit?' in '+selectedUnit:'')"
                          :state="(failed?false:(passed?true:null))"
                        >
                          <b-form-input placeholder="Enter total" v-model="commodity.total" :state="(failed?false:(passed?true:null))"></b-form-input>
                          <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                              {{ errors[0] == "Minimum" ? "Total must meet the minimum of " + numeral(minimumQuantity).format((selectedUnit !== 'LBS') ? '0.00000' : '' ) +" " + selectedUnit + ((selectedUnit !== 'LBS') ? ' (as converted 1 lbs)' : '') : "Please enter a valid number." }}
                          </b-form-invalid-feedback>
                          <!-- <div class="invalid-feedback d-block" v-if="minimumFailed">Total must meet the minimum of {{numeral(minimumQuantity).format((selectedUnit !== "LBS") ? '0.00000' : '' )}} {{selectedUnit}} {{ (selectedUnit !== "LBS") ? "(as converted 1 lbs)" : "" }} </div> -->
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6 pt-2">
                      <ValidationProvider
                          name="commodity.priceOption"
                          rules="required"
                          v-slot="{passed, failed}"
                      > 
                          <b-form-group
                              label="Price Option"
                              :invalid-feedback="'Price Option is required'"
                              :state="(failed?false:(passed?true:null))"
                          >
                              <b-form-select placeholder="Choose Price Options" v-model="commodity.priceOption" @change="priceOptionChange" :options="optionPriceOption" :state="(failed?false:(passed?true:null))"></b-form-select>
                          </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6 pt-2">
                      <ValidationProvider
                        name="CurrencyPrice"
                        :rules="{ required : !disabledPriceField}"   
                        v-slot="{ passed, failed }"
                      >
                        <b-form-group
                          :label="(selectedPriceOption? selectedPriceOption : '') + ' Price'+(selectedUnit?' per '+selectedUnit:'')"
                          :invalid-feedback="'Please enter a valid number'"
                          :state="(failed?false:(passed?true:null))"
                        >
                          <b-form-input :disabled="disabledPriceField" placeholder="Enter price" ref="currencyInput" v-currency="priceFormatOptions" v-model="CurrencyPrice" :state="(failed?false:(passed?true:null))"></b-form-input>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div v-if="producer&&user.role.admin" class="row">
                    <div class="col-12 pt-2"><hr /></div>
                  </div>

                  <div v-if="producer&&user.role.admin" class="row">
                    <div class="col-12">
                      <h3 class="m-0">Farmer</h3>
                    </div>

                    <div v-if="producer.business.type=='producer'" class="col-12 col-md-6 pt-2">
                      <small>Name</small><br /><strong>{{producer.about.first}} {{producer.about.last}}</strong>
                    </div>
                    <div class="col-12 col-md-6 pt-2">
                      <small>Company</small><br /><strong v-if="producer.business.type=='admin'">AgHub</strong><strong v-else>{{producer.business.name}}</strong>
                    </div>
                    <div v-if="producer.business.type=='producer'" class="col-12 col-md-6 pt-2">
                      <small>Address</small><br />
                      <strong class="d-block">{{producer.business.address.address}}</strong>
                      <strong class="d-block">{{producer.business.address.city}}, {{producer.business.address.state}} {{producer.business.address.zip}}</strong>
                    </div>
                    <div v-if="producer.business.type=='producer'" class="col-12 col-md-6 pt-2">
                      <small>Phone</small><br />
                      <strong class="d-block">{{producer.about.phone}}</strong>
                    </div>
                    <div v-if="producer.business.type=='producer'" class="col-12 col-md-6 pt-2">
                      <small>Email</small><br />
                      <strong class="d-block">{{producer.about.email}}</strong>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 pt-2"><hr /></div>
                  </div>
                  <div class="row">
                    <div class="col-12 pt-2">
                      <h5>Details</h5>
                    </div>
                  </div>
                  <div class="col-12 pt-2 bg-white border rounded">
                    <b-form-group
                      id="fieldset-commodity-details"
                      description="Enter any important details for your crop."
                      label="Include more details"
                      >
                      <vue-editor v-model="commodity.details"></vue-editor>
                    </b-form-group>
                  </div>
                  <div class="row">
                    <div class="col-12 pt-4 text-center">
                      <p><a @click="showCreatingGreat=true" class="text-success font-weight-bold"><i class="fa fa-info-circle mr-2" />Learn more about creating great product listings</a></p>
                    </div>
                  </div>
                  <b-modal size="lg" ref="learn-more-modal" v-model="showCreatingGreat" hide-footer title="Creating Great Product Listings">
                    <CreatingGreatListings />
                  </b-modal>
                  <div class="row">
                    <div class="col-12 pt-2"><hr /></div>
                  </div>
                  <div class="row">
                    <div class="col-12 pt-2">
                      <h5>Location</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 pt-2">
                      <b-alert show variant="info">The requested address will be used for shipping purposes and the precise address will not be shared with the Buyer; however, the general address location will be shared.</b-alert>
                      <b-form-group
                        label="Where is your product located?"
                      >
                        <gmap-autocomplete
                          @place_changed="setPlace"
                          :types="['address']"
                          :enable-geolocation="true"
                        >
                        </gmap-autocomplete>
                        <div class="invalid-feedback d-block" v-if="positionFailed">Please set the location</div>
                      </b-form-group>
                      <b-button variant="info" @click="setPosition">Set Location</b-button>
                      <gmap-map
                        v-if="commodityPlace"
                        :center="commodityPlace.position"
                        :zoom="12"
                        class="mt-4"
                        style="width:100%;  height: 320px;"
                        :options="{streetViewControl: false}" 
                      >
                        <gmap-marker
                          :position="commodityPlace.position"
                        ></gmap-marker>
                      </gmap-map>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 pt-2"><hr /></div>
                  </div>
                  <div class="row">
                    <div class="col-12 pt-2"><h5>Certifications</h5></div>
                  </div>
                  <div class="row" v-if="!user.role.admin">
                    <div class="col-12 pt-2">
                      <!-- <b-button variant="info" @click="downloadGrainForm"><i class="fa fa-download"></i><strong>  Grain Inspection Grain Form</strong></b-button> -->
                      <b-button class="ml-2" v-b-modal="'my-modal'" variant="info"><i class="fa fa-question-circle"></i> Help</b-button>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-12 pt-2"><div :class="'alert '+(grainSampleMissing?'alert-danger':'alert-info')"><i :class="'fa fa-exclamation-'+(grainSampleMissing?'triangle':'circle')+' mr-2'"></i><strong>Standard Grade Test</strong> is required for all product listing to be visible.</div></div>
                  </div> -->
                  <div v-if="commodity.certifications.length > 0">
                    <EditCertification v-for="certification in commodity.certifications" :key="certification.id" :certification="certification" @cert-add="getAddCert" @cert-del="getDelCert"  @remove="certRemoved" />
                  </div>
                  <NewCertification :commodityID="commodity.id" :certificationIndex="commodity.certifications.length" @saved="certAdded" @cert-add="getAddCert" @cert-del="getDelCert" class="mt-4" />
                  <b-modal id="my-modal" size="lg" hide-footer title="Help Grain Inspection Grain Form">
                    <div class="d-block text-left">
                      <p class="bg-light p-4 rounded">If any questions arise, please reach out to the NGF team to ensure submission form accuracy</p>
                      <p class="bg-light p-4 rounded"><strong>NOTE: </strong> NGF Global is <strong>Applicants</strong> to cover up-front costs of sample submission which are later collected when the listing is successfully transacted</p>
                      <p class="bg-light p-4 rounded">Collect a composite sample representative of the entire listing (submit additional samples as needed: incorrectly represented listings' liability and associated costs are responsibility of seller).</p>
                      <p class="bg-light p-4 rounded">
                        <span class="text-bold">Date Sample Taken: </span>
                        The date the sample being submitted was collected
                      </p>
                      <p class="bg-light p-4 rounded">
                        <span class="text-bold">NGF Global Account ID:</span>
                        Your unique ID accessible on your Connect Dashboard (Profile tab on Connect Platform/Top left of screen in Connect Dashboard)
                      </p>
                      <p class="bg-light p-4 rounded">
                        <span class="text-bold">Applicant Name: </span>
                        The name of the profile the listing will be posted under
                      </p>
                      <p class="bg-light p-4 rounded">
                        <span class="text-bold">Applicant Address: </span>
                        Self-explanatory
                      </p>
                      <p class="bg-light p-4 rounded">
                        <span class="text-bold">Contact: </span>
                        Email and phone tied to your Connect profile
                      </p>
                      <p class="bg-light p-4 rounded">
                        <span class="text-bold">Commodity w/ variant:</span>
                        Example: Chickpeas, Kabuli OR Hay, Bluegrass straw
                      </p>
                      <p class="bg-light p-4 rounded">
                        <span class="text-bold">Service Requested: </span>
                        Will depend on commodity submitted/; all listings require a standard “full” grade but any services listed can be selected
                      </p>
                      <p class="bg-light p-4 rounded">
                        <span class="text-bold">Shipping Instructions:</span>
                        Shipping charges are paid by the seller. Send sample by desired shipping method.
                        <br/>
                        Depending on services requested grading completion time frame will vary. In general, expect 10-14 days from mailing date for a standard full grade.
                      </p>
                    </div>
                  </b-modal>
                  <div class="row mt-2">
                    <div class="col-12"><div class="alert alert-info"><i class="fa fa-exclamation-circle mr-2"></i>Farmer must be present, at shipping load-out, and provide all required equipment.</div></div>
                  </div>
                  <div v-if="error" class="row mt-2">
                    <div class="col-12"><div class="alert alert-danger"><i class="fa fa-exclamation-triangle mr-2"></i>One or more required fields are missing.</div></div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12"><div class="alert alert-primary"><i class="fa fa-balance-scale mr-2"></i>By proceeding, you acknowledge reading and agreeing to NGF-Global’s policies <a href="https://www.ngf-global.com/policy" target="_blank" class="text-white font-weight-bold"><u>https://www.ngf-global.com/policy</u></a></div></div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-6">
                      <b-button @click="backTo" variant="light">Cancel</b-button>
                    </div>
                    <div class="col-6 text-right">
                      <b-button :disabled="saving" @click="validate" variant="success"><i v-if="saving" class="fa fa-spinner mr-2"></i><i v-else class="fa fa-save mr-2"></i>Submit<i class="fa fa-arrow-right ml-2" /></b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
  </div>
 
</template>
<script>
import moment from 'moment';
import numeral from 'numeral';
import Datepicker from 'vuejs-datepicker';
import { VueEditor } from "vue2-editor";
import Vue from "vue";
import {mapState} from 'vuex'
import {bus} from '@/vuex/bus.js';
import firebase from 'firebase';
import {db} from '@/firebase';
import CreatingGreatListings from "@/views/Dashboard/Commodity/components/CreatingGreatListings.vue";
import NewCertification from "@/components/Certification/New.vue";
import EditCertification from "@/components/Certification/Edit.vue";
import CommodityImage from "./components/Image.vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { firestorage } from '@/firebase';
import * as VueGoogleMaps from "vue2-google-maps";
import * as geofire from 'geofire-common';
import { getValue } from "vue-currency-input";


extend("required", required);
extend("decimal",n=>!isNaN(parseFloat(n)) && isFinite(n));

extend("minimum", {
  params: ["minimum"],
  validate: (amount, {minimum})=>{
    return (parseFloat(amount)>=minimum);
  },
  message:'Minimum'
});

extend("minimumPrice", {
  params: ["minimumPrice"],
  validate: (value, {minimumPrice})=>{
    if(typeof value != 'number'){
        let tempPrice = value.replace('$', '');
        return (parseFloat(tempPrice)>=minimumPrice);
      }
  },
  message:'Minimum'
});
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FIREBASE_CONFIG_API_KEY,
    libraries: "places"
  }
});
Vue.prototype.numeral = numeral;
export default {
  components: {
    NewCertification,
    EditCertification,
    CommodityImage,
    Datepicker,
    VueEditor,
    CreatingGreatListings
  },
  watch:{
    CurrencyPrice(){
      this.commodity.price = getValue(this.$refs.currencyInput);
    },
  },
  computed:{
    ...mapState(['user']),
    minimumQuantity(){
      let min = 0;
      this.optionUnits.forEach((u)=>{
        if(u.value==this.commodity.unit) min = u.minimum;
      })
      return min;
    },
    selectedUnit(){
      let unit = '';
      this.optionUnits.forEach((u)=>{
        if(u.value==this.commodity.unit) unit = u.text;
      })
      return unit;
    },
    availableYears(){
      return [...Array(6).keys()].map(k=>Math.abs(k-moment().year()));
    },
    primaryPhotos(){
      return this.images.filter(i=>i.primary);
    },
    commodityPhotos(){
      return this.images.filter(i=>i.name);
    },
    emptyPhotos(){
      return this.images.filter(i=>!i.name);
    },
    imageChunks(){
      let vm = this;
      return [].concat.apply([],
        vm.images.map(function(elem, i) {
          return i % 5 ? [] : [vm.images.slice(i, i + 5)];
        })
      );
    },
    sortedYears: function(){
        return [...this.years].sort((a, b) => {
            return a - b;
        });
    },
    selectedPriceOption(){
        const vm = this;
        let priceOption = '';
        vm.optionPriceOption.forEach((p) => {
            if(p.value==vm.commodity.priceOption) priceOption = p.text;
        });
        return priceOption;
    },
  },
  data() {
    return {
      error:false,
      saving:false,
      saved:false,
      commodity:{
        id:null,
        type:null,
        subtype:null,
        category:null,
        year:null,
        month: null,
        variety:null,
        unit:null,
        priceOption: null,
        price:null,
        total:null,
        expires:null,
        details:null,
        certifications:[],
        location:null,
        active:false,
        is_newest_product: 1,
        is_delete: 0,
        slug: null,
      },
      CurrencyPrice: "0",
      commodityStatus: null,
      commodityPlace:null,
      certifications:[],
      optionUnits:[],
      optionTypes:[],
      optionCategories:[],
      optionSubTypes:[],
      images:[],
      years: [],
      disableImageSelection:false,
      selectedImage:null,
      location:null,
      place:null,
      positionFailed: null,
      minimumFailed: null,
      photosFailed: null,
      center:null,
      producer:null,
      showLearnMore:false,
      showHelpForm:false,
      showCreatingGreat:false,
      grainSampleMissing:null,
      idGrainSample: null,
      tempIdStandardGradeCert:null,
      certification_name:false,
      optionPriceOption: [],
      disabledPriceField: true,
      disabledExpiresDates: {
          to: new Date(Date.now() - 8640000)
      },
      priceFormatOptions: {
          locale:"en",
          currency: "USD",
          precision: 2,
          distractionFree: false,
          valueAsInteger: false,
          autoDecimalMode: true,
          valueRange: { min: 0 }
      },
      isSubscribe: false,
    };
  },
  methods: {

    generateMonths(){
      return Array.from({ length: 12 }, (_, i) => ({
        value: i + 1,
        text: new Date(0, i).toLocaleString("default", { month: "long" }),
      }));
    },
    async validate() {
      const vm = this;
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      vm.minimumFailed=false;
      vm.photosFailed = false;
      vm.error=false;
      vm.saving=true;

      
      let res = await vm.$refs.form.validate();

      if(!vm.commodityPlace) vm.positionFailed = true;
      if(vm.minimumQuantity>0&&parseFloat(vm.commodity.total)<vm.minimumQuantity) vm.minimumFailed = true;


      if ((vm.commodity.year < currentYear || 
            (vm.commodity.year <= currentYear && vm.commodity.month <= currentMonth)) &&
            vm.commodityPhotos.length === 0) {
          vm.photosFailed = true;
        }
     
      // let grainSample = vm.commodity.certifications.filter(c=>c.certification==vm.idGrainSample);
      // if(grainSample.length==0) vm.grainSamp
      // var isStanderCertification = vm.commodity.certifications.filter((t) => ((t.certification === vm.tempIdStandardGradeCert) && (t.file != undefined || t.url != undefined))).length;


      if (res && vm.commodityPlace && !vm.minimumFailed && !vm.photosFailed) {
        let data = { ...vm.commodity, active: true };

        if(data.certifications.length==0) {
          data.certification = firebase.firestore.FieldValue.delete();
          data.certifications = firebase.firestore.FieldValue.delete();
        }else{
          data.certification = data.certifications.map(c=>c.certification);
        }

        data.location=vm.commodityPlace.address;
        data.userID=vm.user.uid;        
        data.images = vm.images.length > 0 ? vm.images : firebase.firestore.FieldValue.delete();
        data.updated = firebase.firestore.FieldValue.serverTimestamp();
        data.created = firebase.firestore.FieldValue.serverTimestamp();

        try{    
            await db.collection('commodities').doc(vm.commodity.id).set(data,{merge:true});
            vm.commodity.active = data.active;
            await db.collection('commodity_location').doc(vm.commodity.id).set({...vm.commodityPlace});
            vm.saved=true;
        }catch(e){
          if(vm.images.length == 0){
            vm.images = [];
          }
          vm.error=e;
        }  finally {
            vm.saving = false;  // Always set saving to false
        }
      }else{
        vm.error=true;
        vm.saving=false;
      }
    },

    getAddCert: async function getAddCert(value){ 
      const vm = this;
      let tempCertificationArray = await vm.getUniqueArray(vm.commodity.certifications);
      vm.commodity.certifications = [];
      vm.commodity.certifications = tempCertificationArray;
      console.log(value);
      // vm.certification_name = (value === "Standard Grade Test" || value === vm.tempIdStandardGradeCert) ? vm : vm.certification_name;
      // vm.error = (value === "Standard Grade Test" || value === vm.tempIdStandardGradeCert) ? false : true;      
    },
    getDelCert: function getDelCert(value){ 
      // const vm = this;
      console.log(value);
      // vm.certification_name = (value === "Standard Grade Test" || value === vm.tempIdStandardGradeCert) ? vm.certification_name : false;
      // vm.error = (value === "Standard Grade Test" || value === vm.tempIdStandardGradeCert) ? vm.error : true;
    },
    certRemoved(id){
      const vm  = this;
      vm.commodity.certifications=vm.commodity.certifications.filter(c=>c.id!=id);
    },

    certAdded(cert, index, isCertificationNotDelete = true){
      const vm = this;
      if(cert.id !== null){
        if(isCertificationNotDelete){
          vm.commodity.certifications[index] = cert;
        } else{
            vm.commodity.certifications.splice(index, 1);
        }
      }
    },

    async downloadGrainForm(){
          let attachmentRef = await firestorage.ref('o/Grain Inspection Request Form.docx-2.pdf');
          attachmentRef.getDownloadURL().then((url) => {
              window.open(url,'_blank');
          });
    },

    async newImage(){
      const vm = this;
      let ref = await db.collection('commodity_images').doc();
      vm.images.push({id:ref.id,commodityID:vm.commodity.id,userID:vm.user.uid});
    },

    async updateCommodityStatus(commodityActiveStatus){
      const vm = this;
      let grainSample = vm.commodity.certifications.filter(c=>c.certification==vm.idGrainSample);
      if(grainSample.length==0) {
          vm.error=true;
          return;
      }
        vm.commodityStatus = (commodityActiveStatus == true) ? 'inactive' : 'active' ; 
        if(confirm('Are you sure you want to make this product ' + vm.commodityStatus + '?')){
            let commodityRef = await db.collection('commodities').doc(vm.commodity.id);
            commodityRef.update({active: !commodityActiveStatus }).then(() =>{
                vm.commodity.active = !commodityActiveStatus
            });
        }
    },

    async loadImages(){
      const vm = this;
      if(vm.emptyPhotos.length==0){
        await Promise.all(Array.from({ length: 5 }, () => vm.newImage()));
      }
      if(!vm.selectedImage && vm.images.length > 0) vm.selectedImage=vm.images[0];
    },

    selectImage(image){
        const vm = this;
        const selectedImage = vm.images.find(i => i.id === image.id) || vm.images[0];
        vm.selectedImage = selectedImage;
    },

    backTo(){
      bus.$emit('backToTop');
    },
  
    async categoryChange(){
      const vm = this;

      try {
        vm.optionTypes=[];
        vm.optionSubTypes=[];

        if(vm.commodity.category){
          const typesSnapshot = await db
            .collection('commodity_types')
            .where('category', '==', vm.commodity.category)
            .get();

            if (!typesSnapshot.empty) {
              vm.optionTypes = typesSnapshot.docs.map(doc => ({
                text: doc.data().name,
                value: doc.id,
              }));
            }
        }
      } catch (error) {
        console.error('Error fetching commodity types:', error);
      }
    },
    
    async typeChange(){
      const vm = this;
      try {
        vm.optionSubTypes=[];
        
        if(!vm.commodity.type) return;
        
        const subtypesSnapshot = await db
                    .collection('commodity_subtypes')
                    .where('type', '==', this.commodity.type)
                    .get();
          if (!subtypesSnapshot.empty) {
            this.optionSubTypes = subtypesSnapshot.docs.map(doc => ({
              text: doc.data().name,
              value: doc.id
            }));
          }
      } catch (error) {
        console.error('Error fetching commodity subtypes:', error);
      }
    },

    generateRandomNumber(){
      return Math.floor(Math.random() * (99 - 10 + 1) + 10) ;
    }, 

      async subTypeChange(){
        const vm = this;
        let tempSlug = null;

        const subtypeRef = await db.collection('commodity_subtypes').doc(vm.commodity.subtype).get();
        const subType = !subtypeRef.exists ? '' : subtypeRef.data().name;

        const typeRef = await db.collection('commodity_types').doc(vm.commodity.type).get();
        const type = !typeRef.exists ? '' : typeRef.data().name;

        const productRef = await db.collection('commodities')
                        .where('type', '==', vm.commodity.type)
                        .where('subtype', '==', vm.commodity.subtype)
                        .get();

        const totalExistingProduct = !productRef.empty ? productRef.docs.length + vm.generateRandomNumber() : 0;
        tempSlug = (subType + "-" + type + (totalExistingProduct ? "-" + totalExistingProduct : '')).trim();

        const productSlug = tempSlug.toLowerCase().replace(/\s+/g, '-').replace("/", "-");
        vm.commodity.slug = productSlug;
      },


    randomNumberGenerate(){
      return Math.random() * (120 - 100) + 100;
    }, 

    setPlace(place) {
      const vm = this;
        const generateRandomOffset = () => {
            return parseFloat("0.0000" + parseInt(this.randomNumberGenerate()));
        };

        const getAddressComponents = (components) => {
            return components
                .filter(ac => ac.types.includes('administrative_area_level_2') || ac.types.includes('administrative_area_level_1'))
                .map(ac => ac.long_name)
                .join(', ');
        };

        const randomOffset = generateRandomOffset();
        const address = getAddressComponents(place.address_components);

        vm.place = {
            position: {
                lat: place.geometry.location.lat() + randomOffset,
                lng: place.geometry.location.lng()
            },
            formatted_address: place.formatted_address,
            address: address,
            url: place.url,
            reference: place.reference
        };
    },

    setPosition() {
      const vm = this;
      if (vm.place) {
          const { lat, lng } = vm.place.position;
          const hash = geofire.geohashForLocation([lat, lng]);

          vm.commodityPlace = { ...vm.place, geohash: hash };
          vm.commodity.geohash = hash;
          vm.commodity.placePosition = { lat, lng };
          vm.positionFailed = null;
      } else {
          vm.positionFailed = true;
      }
    },

    priceOptionChange(){
        const vm = this;
        vm.disabledPriceField = false;
        if(vm.selectedPriceOption == "Make Offer"){
            vm.disabledPriceField = true;
            vm.CurrencyPrice = null;
            vm.bidRequest.price = 0;
        }
    },
    
    getUniqueArray(items) {
        return Array.from(new Map(items.map(item => [item.id, item])).values());
    },

    async getYears(){
        let refs = await db.collection('cropyear').orderBy('year', 'desc').get();
        refs.forEach(ref => {
            this.years.push(ref.data().year)
        });
    },

    redirectToSubscriptionPage(){
        const vm = this;
        vm.$router.replace("/profile")
    },


    async loadOptions(){
      const vm = this;
      const [categories, units] = await Promise.all([
        db.collection('commodity_categories').orderBy('name', 'asc').get(),
        db.collection('units').orderBy('name', 'asc').get(),
      ]);

      vm.optionCategories = categories.docs.map(c => ({
        text: c.data().name,
        value: c.id
      }));

      vm.optionUnits = units.docs.map(c => ({
        minimum: c.data().minimum,
        text: c.data().name,
        value: c.id
      }));
    },

    async loadPriceOption(){
      const vm = this;
      let priceOptions = await db.collection('bid_price_options').orderBy('name', 'asc').get();
      priceOptions.forEach(c=>{vm.optionPriceOption.push({text:c.data().name, value:c.id})});

    },
  },
  async created(){
    const vm = this;
    // let cRef = await db.collection('certifications').where('name','==','Standard Grade Test').get();
    // if(!cRef.empty) {
    //   vm.idGrainSample=cRef.docs[0].id;
    //   vm.tempIdStandardGradeCert = cRef.docs[0].id;
    // } 
 
    vm.isSubscribe = !vm.user.role.admin && vm.user?.subscription?.stripeSubscription?.status === 'active';

    if(vm.user.role.admin){
        vm.isSubscribe = true;
    }

    if(!vm.isSubscribe && !vm.user.role.admin){
        return;
    }

    let ref = db.collection('commodities').doc();
    vm.commodity.id=ref.id;
    vm.loadImages();

  },
  
  async mounted(){
    let vm = this; 
    await Promise.all([vm.getYears(), vm.loadOptions(), vm.loadPriceOption()])

    bus.$on('primaryImageChanged',(primaryImage)=>{
      vm.images.forEach((image,index)=>{
        if(image.id!=primaryImage.id&&image.primary){
          Vue.set(vm.images,index,{...image,primary:false});
        }
      });
    });

    bus.$on('processingImage',(yn)=>{vm.disableImageSelection=yn;})

    bus.$on('updateImage',(image)=>{
        const imageIndex = vm.images.findIndex(mapImage => mapImage.id === image.id);

        if (imageIndex !== -1) {
          if (vm.commodityPhotos.length === 0 && image) {
            image.primary = true;
          }

          Vue.set(vm.images, imageIndex, image);
          
          if (vm.primaryPhotos.length === 0 && vm.commodityPhotos.length > 0) {
            const primaryImageId = vm.commodityPhotos[0].id;
            const primaryImageIndex = vm.images.findIndex(newPrimaryImage => newPrimaryImage.id === primaryImageId);

            if (primaryImageIndex !== -1) {
              Vue.set(vm.images, primaryImageIndex, { ...vm.images[primaryImageIndex], primary: true });
            }
          }

          vm.selectedImage = image;
        }

        vm.loadImages();
    });
    bus.$emit('prepareTitle',null);
  },
  
  
  
};
</script>
<style>
.vdp-datepicker input[type="text"], .pac-target-input{
  background-color: transparent;
    border: 1px solid #9A9A9A;
    border-radius: 30px;
    width:100%;
    color: #2c2c2c;
    line-height: normal;
    height: auto;
    font-size: 0.8571em;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    padding: 10px 18px 10px 18px;
}
.image-carousel .col-thumbnail {
  flex: 0 0 20%;max-width: 20%;padding-bottom:1em !important;
}
.text-bold{
  font-weight: 700;
}
@media (max-width: 1200px) {
  .image-carousel .col-thumbnail {
    flex: 0 0 25%;max-width: 25%;padding-bottom:1em !important;
  }
}
@media (max-width: 992px) {
  .image-carousel .col-thumbnail {
    flex: 0 0 33%;max-width: 33%;padding-bottom:1em !important;
  }
}
@media (max-width: 768px) {
  .image-carousel .col-thumbnail {
    flex: 0 0 33%;max-width: 33%;padding-bottom:1em !important;
  }
}
@media (max-width: 576px) {
  .image-carousel .col-thumbnail {
    flex: 0 0 25%;max-width: 25%;padding-bottom:1em !important;
  }
}
@media (max-width: 400px) {
  .image-carousel .col-thumbnail {
    flex: 0 0 33%;max-width: 33%;padding-bottom:1em !important;
  }
}

</style>
