import Vue from 'vue'
import firebase from 'firebase';

import Router from 'vue-router';
Vue.use(Router);

import DashboardLayout from "@/layouts/DashboardLayout.vue";
import Profile from "@/views/Dashboard/Profile.vue";
import Account from "@/views/Dashboard/Account.vue";
import Marketplace from "@/views/Dashboard/Marketplace.vue";
import Orders from "@/views/Dashboard/Orders.vue";
import Inbox from "@/views/Dashboard/Inbox.vue";
import NewCommodity from "@/views/Dashboard/Commodity/New.vue";
import EditCommodity from "@/views/Dashboard/Commodity/Edit.vue";
import ViewCommodity from "@/views/Dashboard/Commodity/View.vue";
import ViewOrder from "@/views/Dashboard/Order/View.vue";
import Settings from "@/views/Dashboard/Settings.vue";
import AllMessages from "@/views/Dashboard/AllMessages.vue";
import Users from "@/views/Dashboard/Users.vue";
import UserView from "@/views/Dashboard/User/View.vue";
import SearchTransaction from "@/views/Dashboard/SearchTransaction.vue";
import SearchPurchaseOrder from "@/views/Dashboard/SearchPurchaseOrder.vue";
import ProductWishLists from "@/views/Dashboard/ProductWishLists.vue";
import MyProductsNegotiation from "@/views/Dashboard/MyProductsNegotiation.vue";
// import MyGrainCart from "@/views/Dashboard/MyGrainCart.vue";
import ProductNegotiation from "@/views/Dashboard/ProductsNegotiation/View.vue";
import GrainFeed from "@/views/Dashboard/GrainFeed.vue";
import Invoice from "@/views/Dashboard/Invoice.vue";

// Production Contract
import NewProductContract from "@/views/Dashboard/ProductionContract/New.vue";
import EditProductContract from "@/views/Dashboard/ProductionContract/Edit.vue";
import ProductionContract from "@/views/Dashboard/ProductionContract.vue";
import DetailsProductionContract from "@/views/Dashboard/ProductionContract/View.vue";
import BuyerProductContract from "@/views/Dashboard/ProductionContract/ProductionContractListingForBuyer.vue";
import viewProductionContractNegotiation from "@/views/Dashboard/ProductionContract/ProductionContractNegotiationView.vue";
import MyProductionContractList from "@/views/Dashboard/ProductionContract/MyProductionContractList.vue"
import ViewCompleteProductionContract from "@/views/Dashboard/ProductionContract/ViewCompleteProductionContract.vue";
//Bid Request
import NewBidRequest from "@/views/Dashboard/BidRequest/New.vue";
import EditBidRequest from "@/views/Dashboard/BidRequest/Edit.vue";
import BidRequests  from "@/views/Dashboard/BidRequests.vue";
import ViewBidRequest from "@/views/Dashboard/BidRequest/View.vue";
import viewBidRequestNegotiation from "@/views/Dashboard/BidRequest/BidRequestNegotiationView.vue";
import ViewCompleteBidRequest from "@/views/Dashboard/BidRequest/ViewCompleteBidRequest.vue";
import MyProductBidRequestList from "@/views/Dashboard/BidRequest/MyProductBidRequestList.vue"


//
import NegotiationItems from "@/views/Dashboard/NegotiationItems.vue";


// Permission Denied
import ViewPermissionDenied from "@/components/PermissionDenied/PermissionDenied.vue";
import notAccessible from "@/components/PermissionDenied/NotAccess.vue";
 
//AllDeletingListing
import AllDeletingListing from '../views/Dashboard/AllDeletingList.vue';

//Subscription page Details
import Subscriptions from "@/views/Subscriptions.vue"
import Checkout from "@/views/Checkout.vue"

let dashboardPages = {
  path: "/",
  component: DashboardLayout,
  name: "Dashboard",
  meta: {requiresAuth: true},
  children: [

    // Dashboard
    {
      path: "/",
      name: "Dashboard",
      component: Inbox,
      meta:{
        root:true
      }
    },

    // Product 

    {
      path: "/commodity/new",
      name: "New Product",
      component: NewCommodity,
      meta:{
          dynamicTitle:true
        }
    },

    {
      path: "/marketplace",
      name: "Marketplace",
      component: Marketplace,
      meta:{
        root:true
      }
    },

    {
      path: "/commodity/view/:id/:productNegotiation?",
      name: "View Commodity",
      component: ViewCommodity,
      meta:{
        dynamicTitle:true,
        root: true
      }
    },

    {
      path: "/commodity/edit/:id",
      name: "Edit Product",
      component: EditCommodity,
      meta:{
        dynamicTitle:true
      }
    },

    {
      path: "products/negotiation",
      name: "My Products Negotiations",
      component: MyProductsNegotiation,
      meta:{
        root:true
      }
    },

    {
      path: "product/negotiation/:id",
      name: "Product Negotiation",
      component: ProductNegotiation,
      meta:{
        dynamicTitle:true
      }
    },

    {
      path: "/orders",
      name: "Complete Product Order",
      component: Orders,
      meta:{
        root:true
      }
    },

    {
      path: "/order/view/:id",
      name: "View Order",
      component: ViewOrder,
      meta:{
        dynamicTitle:true
      }
    },

    // Production Contract

    {
      path: "/production/contract/new",
      name: "New Production Contract",
      component: NewProductContract,
    },

    {
      path: "/production/contract",
      name: "Production Contract",
      component: ProductionContract,
      meta:{
        root:true
      }
    },

    {
      path: "/production/contract/:id",
      name: "Production Contract Details",
      component: DetailsProductionContract,
      meta:{
        dynamicTitle:true,
        root:true
      }
    },

    {
      path: "/production/contract/edit/:id",
      name: "Edit Production Contract",
      component: EditProductContract,
      meta:{
        dynamicTitle:true
      }
    },

    {
      path: "/production/contract/current",
      name: "My Created Production Contract",
      component: BuyerProductContract,
    },

    {
      path: "/production/contract/negotiation/:id",
      name: "Production Contract Negotiation",
      component: viewProductionContractNegotiation,
      meta:{
        dynamicTitle:true
      }
    },

    
    {
      path: "/production/contract/complete/list",
      name: "My Production Contract List",
      component: MyProductionContractList,
      meta: {
        root: true
      }
    },

    {
      path: "/production/contract/complete/list/:id",
      name: "Completed Production Contract detail",
      component: ViewCompleteProductionContract,
      meta:{
        dynamicTitle:true
      }
    },

    // Bid Request
    {
      path: "/bid/request/new",
      name: "New Product Bid",
      component: NewBidRequest
    },

    {
      path: "/bid/request",
      name: "Bid Requests",
      component: BidRequests,
      meta:{
        root:true
      }
    },

    {
      path: "/bid/request/:id",
      name: "View Bid Request",
      component: ViewBidRequest,
      meta:{
        dynamicTitle:true,
        root:true
      }
    },

    {
      path: "/bid/request/edit/:id",
      name: "Edit Bid Request",
      component: EditBidRequest,
      meta:{
        dynamicTitle:true
      }
    },

    {
      path: "/bid/request/negotiation/:id",
      name: "Bid Request Negotiation",
      component: viewBidRequestNegotiation,
      meta:{
        dynamicTitle:true
      }
    },

    {
      path: "/bid/request/complete/list",
      name: "Complete Product Bid Request",
      component: MyProductBidRequestList,
      meta:{
        root:true
      }
    },

    {
      path: "/bid/request/complete/list/:id",
      name: "Completed Product Bid Request detail",
      component: ViewCompleteBidRequest,
      meta:{
        dynamicTitle:true
      }
    },

    // NegotiationItems

    {
      path: "/negotiation/items",
      name: "Negotiation Items",
      component: NegotiationItems,
      meta: {
        root: true
      }
    },
  

    // Search by buyer and order  
    {
      path: "/search/buyer/:id?",
      name: "Search Buyer",
      component: SearchTransaction,
    },
    {
      path: "/search/purchase/order/:id?",
      name: "Search Purchase Order",
      component: SearchPurchaseOrder,
    },

    // User 
    {
      path: "/profile",
      name: "Profile",
      component: Profile
    },
    {
      path: "/account",
      name: "Account",
      component: Account
    },
    
    {
      path: "/grainfeed",
      name: "GrainFeed",
      component: GrainFeed,
    },
   

    // setting
    {
      path: "/settings",
      name: "Settings",
      component: Settings
    },
   
   // Show all messages
    {
      path:"/all-messages",
      name: 'AllMessage',
      component: AllMessages 
    },

    //Users
    {
      path: "/users",
      name: "Users",
      component: Users,
      meta:{
        root: true,
      }
    },
    {
      path: "/user/view/:id",
      name: "User Details",
      component: UserView,
      meta:{
        dynamicTitle: true
      }
    },

    // Admin User Create
    {
      path: "/admin/create",
      name: "Create Admin User",
      component: AdminUserCreate
    },
    {
      path: "/admin/logs",
      name: "Logs",
      component: LogsOfList
    },
   
  
    // Disable URL
    {
      path: "/product/wish/list",
      name: "Product Wish Lists",
      component: ProductWishLists,
    },
  
  
    {
      path: "/invoice",
      name: "Invoice",
      component: Invoice,
    },
    

    //
    
    {
      path:"/all/deleting/listing",
      name: "Recover Deleted Items",
      component: AllDeletingListing
    },
  
    {
      path: "/subscriptions",
      name: "Subscriptions",
      component: Subscriptions
    },

    {
      path: "/checkout/:id",
      name: "Checkout",
      component: Checkout
    },
   
 
    // Permission Denied 
    {
      path: "/permission/denied/:by?",
      name: "Permission Denied",
      component: ViewPermissionDenied,
    },
    {
      path: "/not/access",
      name: "Permission Denied",
      component: notAccessible
    },
    {
      path:"/external-url/:externalUrl",
      name: "external-url",
    }
  ]
};

import AuthLayout from "@/layouts/AuthLayout.vue";
import AuthLogin from "@/views/Auth/Login.vue";
import AuthRegister from "@/views/Auth/Register.vue";
import ReActiveUser from "@/views/Auth/ReActiveUser.vue";
import NotFound from "@/views/NotFound.vue";
import AdminUserCreate from '../views/Dashboard/AdminUser/AdminUserCreate.vue';
import LogsOfList from '../views/Dashboard/AdminUser/LogsOfList.vue';

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  meta: {requiresAuth: false},
  children: [
    {
      path: "/login",
      name: "Login",
      component: AuthLogin
    },
    {
      path: "/register",
      name: "Register",
      component: AuthRegister
    },
    { 
      path: "/re-active/user",
      name: "Re-Active User",
      component: ReActiveUser
    },
    {
      path: "*",
      name: "NotFound",
      component: NotFound
    },
  ]
};

const router = new Router({
  mode: 'history',
  base: __dirname,
  routes: [
      dashboardPages,
      authPages
  ],
  scrollBehavior(){
    return {x:0,y:0};
  }
});

// Nav Guard
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) {
    if(to.path&&to.path!="/"){
      next({
          name:'Login',
          query: {
            redirect: to.path
          }
      });
    }else{
      next('login');
    }
  } else if(to.name == "external-url"){
    const externalUrl = to.params.externalUrl;
    window.open('//'+externalUrl, '_blank');
  } else if(to.name&&to.name=='verify'&&currentUser.emailVerified){
    next('/');
  } else{
      next();
  }

});

export default router;
