<template>
    <div>
        <div v-if="isMessageTimelineShow">
            <time-line>
                <time-line-item
                    :badge-type="variant(newMessage.type)"
                    :badge-icon="'fa ' + icon(newMessage.type)"
                    inverted
                >
                    <div slot="header" class="d-flex mt-2 mt-md-0 justify-content-between ">
                        <h5 v-if="!newMessage.type" class="m-0">Update Status</h5>
                        <b-button v-if="!newMessage.type&&user.role.admin&&! completeBidRequest.cancelled" variant="outline-danger" class="pull-right mt-0" size="sm" @click="showCancelConfirmation=true">Cancel Product Bid</b-button>
                        <b-badge v-else :variant="variant(newMessage.type)" class="text-capitalize">{{newMessage.type}}</b-badge>
                        <b-modal hide-footer v-model="showCancelConfirmation" id="modalCancelProductBit" title="Cancel Product Bid" size="md" variant="danger" :header-bg-variant="'danger'" :header-text-variant="'white'">
                            <p>
                                <strong>Are you sure you want to cancel this product bid request?</strong><br />
                                <small>Cancelling the product bid will allow other farmer to full fill the Acres attributed to this product bid.</small>
                            </p>
                            <p></p>
                            <b-form-group label="Reason for cancellation">
                                <b-form-textarea rows="5" placeholder="Reason" v-model="cancellationReason" />
                            </b-form-group>
                            <div class="w-100 text-right">
                                <b-button variant="secondary" size="sm" @click="showCancelConfirmation=false" :disabled="saving">
                                    Cancel
                                </b-button>
                                <b-button variant="danger" size="sm" class="ml-2" @click="cancelProductBidRequest" :disabled="saving">
                                    <i v-if="saving" class="fa fa-spinner mr-2"></i>Continue
                                </b-button>
                            </div>
                        </b-modal>
                    </div>
                    <div slot="content">
                        <template v-if="!newMessage.type">
                            <p class="text-muted mt-0">Send a message, provide a shipping update, provide a general update or alert of something important.</p>
                            <div class="row mt-4">
                                <div class="col-6 col-md-3 text-center">
                                    <b-button @click="clickOnNewMessageType('message')" variant="link">
                                        <div class="timeline-badge-choose bg-info"><i class="fa fa-comments"></i></div>
                                        <div class="mt-2"><strong class="text-dark">Message</strong></div>
                                    </b-button>
                                </div>
                                <div class="col-6 col-md-3 text-center">
                                <b-button @click="clickOnNewMessageType('shipping')" variant="link">
                                    <div class="timeline-badge-choose bg-warning"><i class="fa fa-truck"></i></div>
                                    <div class="mt-2"><strong class="text-dark">Shipping</strong></div>
                                </b-button>
                                </div>
                                <div class="col-6 col-md-3 text-center">
                                <b-button @click="clickOnNewMessageType('documents')" variant="link">
                                    <div class="timeline-badge-choose bg-success"><i class="fa fa-check"></i></div>
                                    <div class="mt-2"><strong class="text-dark">Documents</strong></div>
                                </b-button>
                                </div>
                                <div class="col-6 col-md-3 text-center">
                                <b-button @click="clickOnNewMessageType('alert')" variant="link">
                                    <div class="timeline-badge-choose bg-danger"><i class="fa fa-exclamation-triangle"></i></div>
                                    <div class="mt-2"><strong class="text-dark">Alert</strong></div>
                                </b-button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div v-if="newMessage.type !== 'documents'">
                                <div v-if="uploading" class="row mt-4 mb-4">
                                    <div class="col-12 text-center">
                                        <b-progress :value="progressUpload" variant="primary" striped :animated="true"></b-progress>
                                        <br />
                                        <small>Uploading...</small>
                                    </div>
                                </div>
                                <div v-if="fileErrors.length > 0" class="row mt-4 mb-4">
                                    <div tabindex="-1" class="d-block invalid-feedback" v-for="(error, index) in fileErrors" :key="index">
                                        {{ error }}
                                    </div>
                                </div>  
                                <ValidationObserver ref="form">
                                    <form @submit.prevent="validate">
                                        <div class="row">
                                            <div class="col-12 pt-2">
                                                <ValidationProvider name="newMessage.to" rules="required" v-slot="{ passed, failed }">
                                                    <b-form-group v-if="user.role.admin" label="Recipient" :invalid-feedback="'Who is this update intended for?'"
                                                        :state="(failed?false:(passed?true:null))">
                                                        <b-form-checkbox-group id="checkbox-recipient" v-model="newMessage.to" name="recipient">
                                                            <b-form-checkbox :value="completeBidRequest.buyerID">{{'User ID: '+completeBidRequest.buyerID.substr(0, 10) + `(${completeBidRequest.buyer.about.first + " " + completeBidRequest.buyer.about.last})`}}</b-form-checkbox>
                                                            <b-form-checkbox :value="completeBidRequest.producerID">{{'User ID: '+completeBidRequest.producerID.substr(0, 10) +  `(${completeBidRequest.producer.about.first + " " + completeBidRequest.producer.about.last})`}}</b-form-checkbox>
                                                        </b-form-checkbox-group>
                                                    </b-form-group>
                                                    <b-form-group v-else-if="completeBidRequest.BidRequest.userID !== user.uid" label="Recipient" :invalid-feedback="'Who is this update intended for?'"
                                                        :state="(failed?false:(passed?true:null))">
                                                        <b-form-checkbox-group id="checkbox-recipient" v-model="newMessage.to" name="recipient">
                                                            <b-form-checkbox :value="completeBidRequest.buyerID">{{'User ID: '+completeBidRequest.buyerID.substr(0, 10)}}</b-form-checkbox>
                                                            <b-form-checkbox :value="'admin'" disabled>AgHub</b-form-checkbox>
                                                        </b-form-checkbox-group>
                                                    </b-form-group>
                                                    <b-form-group v-else-if="completeBidRequest.BidRequest.userID === user.uid" label="Recipient" :invalid-feedback="'Who is this update intended for?'"
                                                        :state="(failed?false:(passed?true:null))">
                                                        <b-form-checkbox-group id="checkbox-recipient" v-model="newMessage.to" name="recipient">
                                                            <b-form-checkbox :value="completeBidRequest.producerID">{{'User ID: '+completeBidRequest.producerID.substr(0, 10)}}</b-form-checkbox>
                                                            <b-form-checkbox :value="'admin'" disabled>AgHub</b-form-checkbox>
                                                        </b-form-checkbox-group>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 pt-2">
                                                <ValidationProvider
                                                    name="newMessage.title"
                                                    rules="required"
                                                    v-slot="{ passed, failed }"
                                                >
                                                    <b-form-group
                                                    label="Title"
                                                    :invalid-feedback="'Title is required'"
                                                    :state="(failed?false:(passed?true:null))"
                                                    >
                                                    <b-form-input placeholder="Title" v-model="newMessage.title"  :state="(failed?false:(passed?true:null))"/>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 pt-2">
                                                <ValidationProvider
                                                    name="newMessage.text"
                                                    rules="required"
                                                    v-slot="{ passed, failed }"
                                                >
                                                    <b-form-group
                                                    label="Message"
                                                    :invalid-feedback="'Message is required'"
                                                    :state="(failed?false:(passed?true:null))"
                                                    >
                                                    <b-form-textarea rows="5" placeholder="Message" v-model="newMessage.text"  :state="(failed?false:(passed?true:null))"/>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div v-if="newMessage.type=='message'||newMessage.type=='shipping'">
                                            <div class="col-12 pt-2">
                                                <div class="col-md-5 text-start pt-2">
                                                    <a @click="selectFile" class="text-muted mt-2 add-pointer" title="Upload File" >
                                                        <i class="fa fa-upload mr-1"></i>Upload File
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="newMessageUploadedFile.length > 0" class="row mt-4 mb-4 list-group">
                                            <div v-for="(file, index) in newMessageUploadedFile" :key="index">
                                                <div class="col-12 text-start" style="word-break: break-all;">
                                                    <a :disabled="deleting" @click="deleteFile(file.data, index)" class="text-danger mr-2 add-pointer">
                                                        <span class="fa fa-times"></span>
                                                    </a>
                                                    <strong><em class="text-muted">{{ file.data.fileNameShow }}</em></strong>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </div>
                            <div v-else>
                            </div>
                        </template>
                    </div>
                    <div v-if="newMessage.type" slot="footer">
                        <div v-if="newMessage.error" class="row">
                            <div class="col-12">
                                <b-alert variant="danger">One or more required fields are missing.</b-alert>
                            </div>
                        </div>
                        <div class="row" v-if="newMessage.type !=='documents'">
                            <div class="col-6 text-center"><b-button block size="sm"  variant="secondary" @click="cancelMessage()" :disabled="saving">Cancel</b-button></div>
                            <div class="col-6 text-center"><b-button block size="sm" :variant="variant(newMessage.type)" @click="submit" :disabled="saving"><i v-if="saving" class="fa fa-spinner mr-2"></i>Submit</b-button></div>
                        </div>
                    </div>
                </time-line-item>
                <input class="d-none" id="upload" type="file"  name="upload" ref="uploadInput" accept="video/*, image/*,application/pdf, .webm,.mkv,.avi ,.flv,.mov,.doc, .docx,.txt" :multiple="true" @change="detectFiles($event)"/>
                <time-line-item
                    :badge-type="variant(m.type)"
                    :badge-icon="'fa '+icon(m.type)"
                    :inverted="(index % 2 == 1)"
                    v-for="(m, index) in timeline"
                    :key="m.id"
                >
                    <div slot="header" class="d-flex">
                        <span :class="'badge badge-' + (variant(m.type))">{{(m.type=='message'?m.from.toUpperCase():m.type.toUpperCase())}}</span>
                        <small class="w-100 text-muted text-right"><i class="ti-time"></i> {{formatDate(m.created)}}</small>
                    </div>
                    <div slot="content">
                        <div v-if="m.title"><strong>{{m.title}}</strong></div>
                        <div v-if="m.text" class="text-muted">{{m.text}}</div>
                        <div v-if="user.role.admin">
                            <div v-for="(file, index) in m.messageAttachment" :key="index">
                                <div class="col-12 text-start" style="word-break: break-all;">
                                    <a @click="viewFile(file)" class="text-info mr-2" title="view">
                                        <span class="fa fa-external-link text-black add-pointer"></span>
                                    </a>
                                    <strong>
                                    <em class="text-muted" v-if="file.fileNameShow">{{ file.fileNameShow }}</em>
                                    <em class="text-muted" v-else>{{ file.name }}</em>
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!user.role.admin">
                            <div v-for="(file, index) in m.messageAttachment" :key="index">
                                <div class="col-12 text-start" style="word-break: break-all;">
                                    <a @click="viewFile(file)" class="text-info mr-2" title="view">
                                        <span class="fa fa-external-link text-black add-pointer"></span>
                                    </a>
                                    <strong>
                                    <em class="text-muted" v-if="file.fileNameShow">{{ file.fileNameShow }}</em>
                                    <em class="text-muted" v-else>{{ file.name }}</em>
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </time-line-item>
            </time-line>
        </div>
        <div v-else class="mt-4">
            <b-card no-body class="overflow-hidden" style="max-width: 630px; float: right; border-radius: 0.1875rem">
                <b-row no-gutters>
                    <b-card-body title="Documents">
                        <div v-if="!isDocumentsLoading">
                            <div v-if="getAllAttachDocumentsFiles.length >= 1">
                                <div v-for="file in getAllAttachDocumentsFiles" :key="file.id">
                                    <div class="col-12 text-start" style="word-break: break-all;">
                                        <a @click="viewFile(file)" class="text-info mr-2" title="view">
                                            <span class="fa fa-external-link text-black add-pointer"></span>
                                        </a>
                                        <strong>
                                            <em class="text-muted" v-if="file.fileNameShow">{{ file.fileNameShow }}</em>
                                            <em class="text-muted" v-else>{{ file.name }}</em>
                                        </strong>
                                    </div>
                                </div>
                                <div class="col-12 text-center"><b-button block size="sm"  variant="secondary" @click="cancelMessage()" :disabled="saving">Back</b-button></div>
                            </div>
                            <div v-else>
                                <div>
                                    No Documents found
                                </div>
                                <div class="col-12 text-center"><b-button block size="sm"  variant="secondary" @click="cancelMessage()" :disabled="saving">Back</b-button></div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="mt-4">
                                Loading.......
                            </div>
                        </div>
                    </b-card-body>
                </b-row>
            </b-card>
        </div>
    </div>
</template>


<script>
import {mapState} from 'vuex'
import {db} from '@/firebase';
import { collectionData, docData } from 'rxfire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import { TimeLine, TimeLineItem } from "@/components";
import firebase from 'firebase';
import { firestorage } from '@/firebase';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import moment from 'moment';


export default {
    props: ['completeBidRequest'],
    computed:{
        ...mapState(['user'])
    },
    components: {
        TimeLine,
        TimeLineItem,
        ValidationObserver,
        ValidationProvider
    },
    data(){
        return {
            newMessage:{
                type:null,
                title:null,
                text:null,
                to:[]
            },
            timeline:[],
            getMessageAttachmentFiles: [],
            uploading: false,
            uploadMessageFile: '',
            newMessageUploadedFile: [],
            fileErrors: [],
            getAttachmentsError: [],
            getAllAttachDocumentsFiles: [],
            tempData: [],
            progressUpload: 0,
            cancellationReason:'',
            isMessageTimelineShow: true,
            showCancelConfirmation:false,
            saving:false,
            isDocumentsLoading: false,
            deleting: false,

        }
    },

    async created() {
        const vm = this;
        await vm.getMessages();
    },

    methods:{
        getMessages(){
            const vm = this;
            vm.newMessage={
                type:null,
                title:null,
                text:null,
                error:null,
                to:[]
            }
            if(!vm.user.role.admin) vm.newMessage.to=['admin'];
            vm.loading=true;
            let ref = db.collection('messages');
            ref = ref.where('completeBidRequestID','==', vm.completeBidRequest.id);
            ref = ref.where('to','==',(vm.user.role.admin?'admin':vm.user.uid)).orderBy('created','desc');
            collectionData(ref).pipe(
                switchMap(inbox=>{
                    if(inbox.length==0) return of([]);
                    return combineLatest(...inbox.map(m=>{
                        return docData(db.collection('users').doc(m.from)).pipe(
                        map(u=>{
                            let from = '';
                            if (u.business != undefined) {
                                from = (u.business.type == 'admin' ? 'AgHub' : (vm.user.role.admin ? `User ID: ${m.from.substr(0, 10)}` : (vm.user.uid == m.from ? 'Message' : `User ID: ${m.from.substr(0,10)}`)))
                                // from = (u.business.type == 'admin' ? 'NGF Global' : (vm.user.role.admin ? u.about.first + ' ' + u.about.last : (vm.user.uid == m.from ? 'me' : (u.business.type == "producer") ? 'Farmer' : u.business.type)))
                            }
                            return { ...m, from: from }
                        })
                        )
                    }))
                })
            ).subscribe(async(inbox)=>{
                vm.timeline=[];
                    inbox.map(async(m) =>{
                        let getAttachment = await vm.getAllMessageAttachments(m.id);
                        vm.timeline.push({...m, messageAttachment: getAttachment})
                    }
                );
                vm.loading=false;
            });
        },

        getAllMessageAttachments(messageId){
            let getAttachment = [];
            if(messageId != '' && messageId != undefined){
                let getAttachmentRef = db.collection('message_attachments');
                getAttachmentRef = getAttachmentRef.where('messageId','==',messageId);
                getAttachmentRef.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        getAttachment.push(doc.data())
                    });
                });
                return getAttachment;
            } else {
                return getAttachment;
            }
        },


        async submit(){
            const vm = this;
            vm.saving = true;            
            let res = await vm.$refs.form.validate();
            if(res){
                
                let promises = [];
                let to = [];

                if(vm.newMessage.to.length>0){
                    vm.newMessage.to.forEach(uid=>{
                        to.push(uid);
                    });
                    if(!to.includes('admin')) to.push('admin');
                    if(!vm.user.role.admin&&!to.includes(vm.user.uid)) to.push(vm.user.uid);
                } else {
                    to=[vm.completeBidRequest.producerID,vm.completeBidRequest.buyerID,'admin'];
                }

                to.forEach(to=>{
                    let ref = db.collection('messages').doc();
                    let data = {
                        ...vm.newMessage,
                        from:vm.user.uid,
                        to:to,
                        unread:true,
                        completeBidRequestID : vm.completeBidRequest.id,
                        bidRequestID: vm.completeBidRequest.bidRequestID,
                        id: ref.id,
                        is_hide: false,
                        is_restore: null,
                        updated:firebase.firestore.FieldValue.serverTimestamp(),
                        created:firebase.firestore.FieldValue.serverTimestamp()
                    }
                    vm.newUpdateMessage(data).then(async() => {
                        vm.newMessageAttachmentsInsert(data.id);
                        vm.sendMessageInbox(data);
                    });
                });

                await Promise.all(promises);

                vm.newMessage = {
                    type:null,
                    title:null,
                    text:null,
                    error:null,
                    to:[]
                };
                vm.newMessageUploadedFile = [];
                if(!vm.user.role.admin) vm.newMessage.to=['admin'];
            } else {
                vm.newMessage.error=true;
            }
            vm.saving = false;
        },

        async newMessageAttachmentsInsert(messageId){
            const vm = this;
            if(messageId != '' &&  vm.newMessageUploadedFile.length > 0){
                Array.from(Array(vm.newMessageUploadedFile.length).keys()).map((x) => {
                    let newMessageUploadedFile = {...vm.newMessageUploadedFile[x].data,messageId:messageId};
                    db.collection('message_attachments').add(newMessageUploadedFile).then(() => {});
                });
            }
        },

        async cancelProductBidRequest(){
            const vm = this;
            vm.saving = true;
            vm.completeBidRequest.cancelled = true;
            await vm.updateAmountRef();
            await db.collection('bid_request_complete').doc(vm.completeBidRequest.id).update({cancelled:true}).then(async() => {
                await vm.updateAmountRef(vm.completeBidRequest.bidRequestNegotiationId);
            });
            let to = [];
            to=[vm.completeBidRequest.producerID,vm.completeBidRequest.buyerID,'admin'];
            to.forEach(async(item) => {
                let data = {
                    type:'alert',
                    title:'Product Bid Request Cancelled',
                    text:this.cancellationReason,
                    to: item,
                    entity:'complete_bid_request_execute',
                    from:vm.user.uid,
                    unread:true,
                    is_restore: null,
                    completeBidRequestID: vm.completeBidRequest.id,
                    bidRequestID: vm.completeBidRequest.bidRequestID,
                    updated:firebase.firestore.FieldValue.serverTimestamp(),
                    created:firebase.firestore.FieldValue.serverTimestamp()
                }
                await vm.newUpdateMessage(data);
                await vm.sendMessageInbox(data);
            });
            vm.saving = false;
            vm.showCancelConfirmation = false;
        },

        async newUpdateMessage(message){
            let ref = db.collection('messages').doc(message.id);
            ref.set(message);
            return ref;
        },

        async detectFiles(e){
            const vm = this;
            let fileList = e.target.files;
            let fileAccept = ['application/pdf', 'video/quicktime','application/msword', 'video/x-flv','video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'video/x-matroska','video/x-msvideo','video/webm','application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']; 
            let  timestamp = Math.floor(Date.now() / 1000);
            if(fileList.length > 0){
                for(const file of fileList){
                    if(fileAccept.includes(file.type)){
                        timestamp += 100;
                        await vm.upload(file, timestamp);
                    } else {
                        let errorMessage = 'Please select valid document file. file will be accept this extension (.PDF, .TXT and .DOC/.DOCX.)';
                        vm.fileErrors.push(errorMessage);
                        return false;
                    }
                }
            }
        },

        async upload(file, currentTimeStamp){
            const vm = this;
            vm.uploading = true;
            vm.saving = true;
            vm.progressUpload = 0;
            let getFileName = file.name.split('.').slice(0, -1).join('.');
            let getFileExtension = file.name.split('.').pop();
            let fileName = this.user.uid + '_'+ currentTimeStamp  + '_' + getFileName + '.' + getFileExtension;
            let uploadMessageFile = firestorage.ref('message_attachments/' + vm.completeBidRequest.id +'/'+  vm.user.uid + '/' + fileName).put(file);
            await uploadMessageFile.on('state_changed', (sp) => {
                vm.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
            }, 
            null,
            async () => { 
                vm.progressUpload = 100;
                let data = await uploadMessageFile.snapshot.ref.getMetadata();
                for(let key in data){
                if(data[key] === undefined) delete data[key];
                }
                vm.uploading = false;
                vm.saving = false;
                data['fileNameShow'] = file.name;
                vm.newMessageUploadedFile.push({...vm.newMessageUploadedFile, data});
            });
        },



        async sendMessageInbox(data){
            let ref = await db.collection('inbox').doc();
            let inboxID = ref.id;
            let inboxData = {
                id: ref.id,
                bidRequestID: data.bidRequestID,
                completeBidRequestID: data.completeBidRequestID,
                entity: 'complete_bid_request_execute',
                unread: true,
                to: data.to,
                from: data.from,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                title: data.title,
                type: data.type,
                text: data.text,
                is_restore: null
            }
            await db.collection('inbox').doc(inboxID).set(inboxData);
        },

        async updateAmountRef(){
            const vm = this;
            await db.collection('bid_request_accepted_amounts')
                .where('bidRequestNegotiationID', '==', vm.completeBidRequest.bidRequestNegotiationId)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(async(doc) => {
                        await db.collection('bid_request_accepted_amounts').doc(doc.id).delete();
                    });
                });
            
            let acceptAmountRef = await  db.collection('bid_request_accepted_amounts')
                                          .where('bidRequestID', '==', vm.completeBidRequest.bidRequestID)
                                          .get();
            let acceptedAmount = acceptAmountRef.docs.filter((o) => !o.data().cancelled)
                                               .map((o) => {
                                                    return { amount: o.data().fullFillAmount };
                                                });
            let ref = await db.collection('bid_request')
                              .doc(vm.completeBidRequest.bidRequestID)
                              .get();
            
            let bidRequest = null;
            if(ref.exists) bidRequest = {...ref.data()};
            let total = parseInt(bidRequest.available_acres);
            acceptedAmount.forEach((o) => (total = total  - parseInt(o.amount)));
            await db.collection('bid_request')
                    .doc(vm.completeBidRequest.bidRequestID )
                    .update({accepted_full_fill_amount: total});

        },

        async getAllDocumentsFromMessage(){
            const vm = this;
            vm.getAllAttachDocumentsFiles = [];
            Array.from(Array(vm.timeline.length).keys()).map(x => {
                if(vm.timeline[x].messageAttachment.length >= 1){
                    vm.getAllAttachDocumentsFiles.push(...vm.timeline[x].messageAttachment)
                }
            })
            setTimeout(() => {
                vm.isDocumentsLoading = false;
            }, 3000);
        },

        async deleteFile(file, fileIndex){ 
            const vm = this;
            if(confirm("Are you sure you want to delete this file?")){
                vm.deleting = true;
                let storageRef = firestorage.ref(file.fullPath);
                await storageRef.delete();
                vm.newMessageUploadedFile.splice(fileIndex, 1);
                vm.deleting=false;
            }
        },

        cancelMessage(){
            const vm = this;
            vm.newMessage.type = null;
            vm.isMessageTimelineShow = true;

            vm.fileErrors = [];
            vm.getAttachmentsError = [];
            vm.newMessage = {
                type:null,
                title:null,
                text:null,
                error:null,
                to: []
            };
            if(!vm.user.role.admin){
                vm.newMessage.to = ['admin']
            } 
            if(vm.newMessageUploadedFile.length > 0){
                Array.from(Array(vm.newMessageUploadedFile.length).keys()).map((x) => {
                    let attachmentRef = firestorage.ref(vm.newMessageUploadedFile[x].data.fullPath);
                    attachmentRef.delete().then(() => {
                    });
                });
                vm.newMessageUploadedFile = [];
            }
        },

        async clickOnNewMessageType(messageType){
            const vm = this;
            vm.newMessage.type = messageType
            if(messageType === 'documents'){
                vm.isDocumentsLoading = true;
                vm.isMessageTimelineShow = false;
                await vm.getAllDocumentsFromMessage();
            }
        },

        formatDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },
        
        variant(type){
            return (type=='shipping'?'warning':(type=='update'?'success':(type=='alert'?'danger':'info')));
        },
        icon(type){
            return (type=='shipping'?'fa-truck':(type=='update'?'fa-check':(type=='alert'?'fa-exclamation-triangle':'fa-comments')));
        },

        selectFile () {
            const vm = this;
            vm.fileErrors = [];
            vm.$refs.uploadInput.click();
        },

        async viewFile(file){
            const vm = this;
            vm.getAttachmentsError = [];
            if(file != '' && file != undefined){
                let attachmentRef = await firestorage.ref(file.fullPath);
                attachmentRef.getDownloadURL().then((url) => {
                    window.open(url,'_blank');
                }).catch(() => {
                        let errorMessage = 'Please refresh the Page';
                        vm.getAttachmentsError.push({errorMessage, messageId: file.messageId});
                        setTimeout(() => {
                        vm.getAttachmentsError = [];
                        }, 5000); 
                        return false; 
                });
            }
        },


    }
}
</script>