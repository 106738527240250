<template>
    <div class="container">
        <div v-if="!isSubscribe">
            <b-container class="d-flex justify-content-center align-items-center  min-vh-50">            
                    <b-card
                        class="text-center shadow-lg membership-card"
                        header="Membership Required"
                        header-bg-variant="light"
                        style="max-width: 480px;"
                    >
                            <template #header>
                                <div class="membership-header membership-header-font-size">Membership Required</div>
                            </template>
                        <b-card-text class="membership-text mb-4">
                            Become a Member to enjoy these services.
                        </b-card-text>
                        <b-button 
                            variant="primary" 
                            class="membership-button" 
                            @click="redirectToSubscriptionPage"
                        >
                            Become a Member
                        </b-button>
                    </b-card>
            </b-container>
        </div>
        <div v-else>
            <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 class="card-title mb-4">
                    <b-spinner class="mr-2"></b-spinner> Loading...
                </h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
            </div>
            <div v-else class="row">
                <div class="d-block d-md-none col-12">
                    <b-button @click="backTo" variant="primary">
                        <i class="fa fa-chevron-left mr-2"></i> Back
                        </b-button>
                </div>          
                <div class="col-12">
                    <div class="row">
                        <div class="col-12  pt-2">
                            <div class="border-2 bg-white rounded p-4">
                                <div class="text-success">
                                    <h1 class="h3">
                                        <span>{{ showProductionContractSubtypeType }}</span>  
                                    </h1>
                                    <h5 class="h6">
                                        {{ productionContract.variety }}
                                    </h5>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-between border border-1 border-dark text-md-left mt-3">
                                    <span class="m-0 text-uppercase equal-width font-weight-bold d-block px-2 pt-2 pb-0 pb-md-2">Guaranteed Purchase  </span>
                                    <span class="border-1 border-dark equal-width border-left p-2"> {{ numeral(productionContract.total).format('0,000') }} {{productionContract.unit}} per acre</span>  
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                    <span class="m-0 text-uppercase equal-width font-weight-bold px-2 pt-2 pb-0 pb-md-2">{{ productionContract.priceOption }} Price (Per {{productionContract.unit}})   </span>
                                    <span class="border-1 border-dark equal-width border-left p-2">
                                        {{ productionContract.price !== null 
                                            ? (productionContract.price !== 0 
                                                ? (productionContract.price > 0 ? '$' : '-$') + numeral(Math.abs(productionContract.price)).format('0,000.00') 
                                                : '$0.00')
                                            : '-' }}
                                    </span>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                    <span class="m-0 text-uppercase equal-width  font-weight-bold px-2 pt-2 pb-0 pb-md-2">AoG (Act of God)  </span>
                                    <span class=" border-1 border-dark equal-width  border-left p-2"> {{ actOfGod }}</span>  
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                    <span class="m-0 text-uppercase equal-width  font-weight-bold px-2 pt-2 pb-0 pb-md-2">Production Year </span>
                                    <span class=" border-1 border-dark equal-width  border-left p-2"> {{ (productionContract.year != undefined) ? productionContract.year : "-"  }}</span>  
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                    <span class="m-0 text-uppercase equal-width font-weight-bold d-block px-2 pt-2 pb-0 pb-md-2">Production Month</span>
                                    <span class="border-1 border-dark equal-width border-left p-2"> {{ (productionContract.month != undefined) ? new Date(productionContract.month).toLocaleString("default", { month: "long" })  : "-"  }}</span>  
                                </div>

                                <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                    <span class="m-0 text-uppercase equal-width  font-weight-bold px-2 pt-2 pb-0 pb-md-2">Delivery Window  </span>
                                    <span class=" border-1 border-dark equal-width  border-left p-2"> {{moment(productionContract.startDate.toDate()).format('L')}} : {{moment(productionContract.endDate.toDate()).format('L')}}</span>  
                                </div>
                                <div v-if="user.role.admin || user.uid == productionContract.userID" class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                    <span class="m-0 text-uppercase equal-width  font-weight-bold px-2 pt-2 pb-0 pb-md-2">
                                        Expires by
                                    </span>
                                    <span class=" border-1 border-dark equal-width  border-left p-2">  
                                        {{ (productionContract.expires) ?  moment(productionContract.expires.toDate()).format("ll") : '-' }}
                                    </span>  
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                    <span class="m-0 text-uppercase equal-width  font-weight-bold px-2 pt-2 pb-0 pb-md-2">Total Number of Acres</span>
                                    <span class=" border-1 border-dark equal-width  border-left p-2"> {{ numeral(availableAcresTotal).format('0,000') }}</span>  
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                    <span class="m-0 text-uppercase equal-width  font-weight-bold px-2 pt-2 pb-0 pb-md-2">Details</span>
                                    <span class=" border-1 border-dark equal-width  border-left p-2" v-html="productionContract.details"></span>  
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-between border-right border-left border-bottom border-1 border-dark text-md-left" v-if="specifications.length > 0">
                                    <span class="m-0 text-uppercase equal-width font-weight-bold px-2 pt-2 pb-0 pb-md-2">Certifications</span>
                                    <span class="border-1 border-dark equal-width  border-left p-2">
                                        <div v-if="specifications.length > 0">
                                            <div v-for="specification in specifications" :key="specification.id">
                                                <span v-if="specification.url != undefined">
                                                    <a class="text-dark" @click="$router.push('/external-url/' +specification.url)" target="_blank" v-if="specification.file == undefined">
                                                        {{ specification.specification }}<i class="fa fa-check-circle text-info ml-2"></i>
                                                    </a>
                                                    <a class="text-dark" :href="specification.url" target="_blank" v-else>
                                                        {{ specification.specification }}<i class="fa fa-check-circle text-info ml-2"></i>
                                                    </a>
                                                </span>
                                                <span v-else>
                                                    {{ specification.specification }}<i class="fa fa-check-circle text-info ml-2"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <ValidationObserver ref="form" class="col">
                                    <form @submit.prevent="validate" v-if="user.uid !== productionContract.userID && !user.role.admin" >
                                        <div class="d-block d-md-flex text-center text-md-left" v-if="user.uid !== productionContract.userID && !user.role.admin" >
                                            <div class="row">
                                                <div class="col-12 pt-2">
                                                    <ValidationProvider
                                                        name="production_contract_negotiation.fullFillAmount"
                                                        :rules="{required:true,decimal:true,minimum:{minimum:10}, available: {total: totalAvailableAcres}}"
                                                        v-slot="{ passed, failed, errors }"
                                                    >
                                                        <b-form-group  :state="(failed?false:(passed?true:null))">
                                                            <div>
                                                                <legend class="col-form-label">How many acres can you commit?</legend>
                                                                <b-input-group>
                                                                    <b-form-input placeholder="Enter amount" v-model="production_contract_negotiation.fullFillAmount" :state="(failed?false:(passed?true:null))"></b-form-input>
                                                                    <b-input-group-append>
                                                                        <b-input-group-text>Acres</b-input-group-text>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                            </div>
                                                            <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                                                                    <!-- {{errors[0] == "Minimum" ? '' : errors[0]=='Maximum' ? 'Maximum' : 'Please enter a valid number.' }} -->
                                                                    {{ errors[0] == "Exceeds" ? 'The commit acres entered exceed the acres available.' : 'Please enter a valid number.' }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-block d-md-flex" v-if="user.uid !== productionContract.userID && !user.role.admin">
                                            <div class="flex-fill text-center text-md-left">
                                                <b-button variant="success" @click="backToProductionContractListing"><i class="fa fa-arrow-left mr-2"></i>Back</b-button>
                                            </div>
                                            <div class="flex-fill text-center text-md-right">
                                                <div>
                                                    <b-button @click="validate('accept')" :disabled="totalAvailableAcres == 0" variant="success">Accept Production Contract</b-button>
                                                    <!-- <b-button @click="validateStatus('decline')" variant="danger" class="ml-2">Decline</b-button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                    
                                <div class="d-block d-md-flex" v-if="(user.uid == productionContract.userID)">
                                    <div class="flex-fill text-center text-md-left">
                                        <b-button variant="success" @click="backToProductionContractListing"><i class="fa fa-arrow-left mr-2"></i>Back</b-button>
                                    </div>
                                    <div class="flex-fill text-center text-md-right">
                                        <div>
                                            <b-button variant="success" @click="editProductionContract(productionContract.id);">Edit</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <div v-if="!loading">
                <div>
                    <div class="row" v-if="!loading">
                        <div class="col-12 pt-2"><hr /></div>
                    </div>
                    <div class="row">
                        <div class="col-12 pt-2"><h5>Questions </h5></div>
                    </div>
                    <div v-if="(productionContract.userID !== user.uid && !user.role.admin) || (productionContract.questions.length == 0 && user.role.admin)">
                        <ProductionContractQuestions  id="question" 
                            :currentUserID="user.uid"
                            :productionContractID="productionContract.id"
                            :isPriorMessageAvailableOrNot= "(user.role.admin && productionContract.questions.length == 0)"
                            @reloadQuestions="reloadQuestions"   
                            :showSenderCheckbox = "false" 
                        />
                    </div>
                    <div v-else-if="productionContract.questions.length > 0" role="tablist">
                            <ProductionContractQuestions  id="question" 
                                v-if="showQuestionModule && user.role.admin"
                                :currentUserID="user.uid"
                                :productionContractID="productionContract.id"
                                :isPriorMessageAvailableOrNot= "true"
                                @reloadQuestions="reloadQuestions"    
                                :showSenderCheckbox = "false"
                            />
                            <b-card
                                no-body
                                class="mb-1"
                                v-for="question in getUniqueValue(productionContract.questions)"
                                :key="'cardQuestions' + question.id"
                            >
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block @click="clickQuestionMessage(question.id)" variant="info">
                                        {{  question.isFirstMessageAdmin ? "AgHub " : "User ID: " }}  {{ (question.isFirstMessageAdmin) ? '' : question.producerID.substr(0, 10).toUpperCase() }}
                                        <b-badge v-if="question.unread" variant="warning" class="ml-2">
                                            <strong>UNREAD</strong>
                                        </b-badge>
                                    </b-button>
                                </b-card-header>    
                                <b-collapse
                                    :id="'collapse' + question.id"
                                    :visible="selectedProducerQuestions == question.id"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body>
                                        <ProductionContractQuestions
                                            v-if="selectedProducerQuestions == question.id"
                                            :id="'questions' + question.id"
                                            :currentUserID="question.producerID"
                                            :productionContractID="productionContract.id"
                                            :isPriorMessageAvailableOrNot= "false"
                                            :showSenderCheckbox = "user.role.admin && !question.isFirstMessageAdmin"
                                        />
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                    </div>
                </div>
            </div>
            <div>
                <form @submit.prevent="confirmProductionNegotiation">
                    <b-modal ref="model" size="lg" 
                        title="Confirmation of Negotiation" centered  v-model="confirmationPopup" 
                        @show="resetConfirmationModel" 
                        @hide="resetConfirmationModel"
                    >
                    <div v-if="confirmNegotiationLoading" class="row mt-4">
                        <div class="col d-none d-md-flex"></div>
                        <div class="col-12 col-md-8 col-xl-6 pt-4">
                            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                            <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                            </card>
                        </div>
                        <div class="col d-none d-md-flex"></div>
                    </div>
                    <div v-else>
                        <p>
                            By entering your password, you acknowledge and consent to enter into a legally binding contract with the Buyer, for the execution of the production contract pursuant to the NGF Global <a href="https://www.ngf-global.com/user-agreement" target="_blank">User Agreement</a>
                        </p>
                        <p v-if="confirmationPopupError" class="alert alert-danger mb-4">{{confirmationPopupError}}</p>
                        <ValidationProvider
                            name="confirmationPassword"
                            rules="required|min:6"
                            v-slot="{ passed, failed, errors }"
                            >
                            <b-form-group
                                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                                :state="failed ? false : passed ? true : null"
                            >
                                <b-form-input
                                type="password"
                                placeholder="Please Enter Password"
                                v-model="confirmationPassword"
                                :class="confirmationPopupError ? 'is-invalid' : ''"
                                :state="failed ? false : passed ? true : null"
                                />
                            </b-form-group>
                            </ValidationProvider>
                    </div>
                    <template #modal-footer="{ok,cancel}">
                        <b-button size="xl" @click="cancel()">
                            Cancel
                        </b-button>
                        <b-button size="xl" variant="success" @click="confirmProductionNegotiation">
                            Continue
                        </b-button>
                    </template>
                    </b-modal>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { firestorage } from "@/firebase";
import { db } from "@/firebase";
import { mapState } from "vuex";
import { bus } from "@/vuex/bus.js";
import {auth} from '@/firebase';
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import firebase from 'firebase';
import ProductionContractQuestions from "./components/Questions.vue";


extend("required", required);
extend("decimal",n=>!isNaN(parseFloat(n)) && isFinite(n));

extend("available", {
  params: ["total"],
  validate: (amount, {total})=>{
    return (parseFloat(amount)<=total);
  },
  message:'Exceeds'
});

extend("minimum", {
  params: ["minimum"],
  validate: (amount, {minimum})=>{
    return (parseFloat(amount)>=minimum);
  },
  message:'Minimum'
});


export default {
    components:{
    ProductionContractQuestions,
    ValidationProvider
},
    data(){
        return {
            error:false,
            loading: true,
            productionContract: null,
            productionContractID: null,
            production_contract_negotiation: {
                fullFillAmount: 0,
            },
            specifications: [],
            confirmationPopup: false,
            confirmationPopupError :  null,
            confirmationPassword: null,
            confirmNegotiationLoading: false,
            selectedProducerQuestions: null,
            totalAvailableAcres: null,
            showQuestionModule: true,
            isSubscribe: false
        }
    },
    computed: {
    ...mapState(["user"]),
        availableAcresTotal() {
            return this.totalAvailableAcres;
        },
        showProductionContractSubtypeType: function(){
            const vm = this;
            return vm.productionContract.subtype +" - "+ vm.productionContract.type
        },
        actOfGod: function(){
            const vm = this;
            return (vm.productionContract.act_of_god == 'yes') ? 'Yes' : 'No';
        }
    },  
    methods: {
        editProductionContract(productionContractID){
            this.$router.replace("/production/contract/edit/" + productionContractID)
        },
        backTo() {
            bus.$emit("backToTop");
        },
        getUniqueValue(questions){
            return [
                ...new Map(
                questions.map((item) => [item['id'], item])
                ).values()
            ];
        },
        async getSpecification(c) {
            try {
                let url = ""
                if(c.file){
                   url = await firestorage.ref(c.file.fullPath).getDownloadURL();
                } else {
                   url = c.url;
                }
                let ref = await db.collection("specifications").doc(c.specification).get();
                return { ...c, url: url, specification: ref.data().name };
            } catch (e) {
                return null;
            }
        },
        backToProductionContractListing(){
            const vm = this
            bus.$emit('backToTop');
            // if(vm.productionContract.userID !== vm.user.uid){
            //     vm.$router.replace("/production/contract/current")
            // } else {
                vm.$router.replace("/production/contract/")
            // }
        },
        resetConfirmationModel(){
            const vm = this;
            vm.confirmationPopupError = null;
            vm.confirmationPassword = null;
        },
        async addProductionContractNegotiationRequest(item){
            let vm = this;
            [item.buyerID, vm.user.uid].forEach(async(to) => {
                let ref = await db.collection('inbox').doc();
                let inboxID = ref.id;
                let data = {
                    producerID: vm.user.uid,
                    productionContractID: item.productionContractID,
                    production_contract_negotiation_id: item.id,
                    entity: 'production_contract_negotiation',
                    from: vm.user.uid,
                    type: 'update',
                    title: 'REQUEST',
                    text: 'A User has requested a production contract negotiation',
                    to: to,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    unread: true,
                    id: inboxID,
                    is_restore: null
                };
                await db.collection('inbox').doc(inboxID).set(data);
            });
        },
        async confirmProductionNegotiation(){
            const vm = this;
            vm.confirmNegotiationLoading = true;
            let isAuth = await auth.signInWithEmailAndPassword(vm.user.about.email, vm.confirmationPassword).then(async() => {
                            return true;
                        }).catch(() => {
                            vm.confirmationPopupError = "This password is invalid."
                            vm.confirmNegotiationLoading = false;
                            setTimeout(() => { vm.confirmationPopupError = null; }, 4000);
                            return;
                        });
        
            if(isAuth){
                let production_contract = {};
                let ref = db.collection('production_contract_negotiation').doc();
                let id =  ref.id;
                production_contract.id = id;
                production_contract.productionContractID = vm.productionContract.id;
                production_contract.producerID = vm.user.uid;
                production_contract.buyerID= vm.productionContract.userID;
                production_contract.updated = firebase.firestore.FieldValue.serverTimestamp();
                production_contract.created = firebase.firestore.FieldValue.serverTimestamp();
                production_contract.fullFillAmount = parseInt(vm.production_contract_negotiation.fullFillAmount);
                production_contract.is_producer_verified = true;
                production_contract.is_restore = null;
                let data = {...production_contract};
                await db.collection('production_contract_negotiation').doc(id).set(data).then(() => {
                    setTimeout(() => { vm.$router.replace('/'); }, 3000);
                });
            }
        },
        clickQuestionMessage(questionId) {
            const vm = this;
            if (vm.selectedProducerQuestions == questionId) {
                return (vm.selectedProducerQuestions = null);
            }
            return (vm.selectedProducerQuestions = questionId);
        },

        async validate(status){
            const vm = this;
            let res = await vm.$refs.form.validate();
            if(res){
                if(status == "accept"){
                vm.confirmationPopup = true;
                } 
            } else {
                vm.error=true;
                vm.saving=false;
            }
        },
        checkIfProducerExist(items, producerID){
            return items.filter((i) => {
                return i.producerID.includes(producerID);
            });
        },
        async getProductionContractIDload(parametersID){
            let getProductionContractRef = await db.collection("production_contract").where('slug', '==', parametersID).get()
            if(!getProductionContractRef.empty){
                const data = getProductionContractRef.docs.map((productionContract) => {
                    return productionContract.data().id;
                });
                return data[0];
            } else {
                let getProductionContractRef = await db.collection("production_contract").where('id', '==', parametersID).get();
                if(!getProductionContractRef.empty){
                    const data = getProductionContractRef.docs.map((productionContract) => {
                        return productionContract.data().id;
                    });
                    return data[0];
                } else {
                    return null;
                }
            } 
        }, 
        redirectToSubscriptionPage(){
            const vm = this;
            vm.$router.replace("/profile")
        },
        async load(){
            let vm = this;
            let data = await vm.getProductionContractIDload(vm.$route.params.id);
            if (vm.$route.params.id) vm.productionContractID = data;
            let ref = await db.collection("production_contract").doc(this.productionContractID).get();
            if (ref.exists) {
                vm.productionContract = { ...ref.data()};

                ref = await db.collection("units").doc(vm.productionContract.unit).get();
                if (ref.exists) vm.productionContract = {...vm.productionContract, unit: ref.data().name}

                ref = await db.collection('price_options').doc(vm.productionContract.priceOption).get();
                if (ref.exists) vm.productionContract = {...vm.productionContract, priceOption: ref.data().name}

                ref = await db.collection("commodity_types").doc(vm.productionContract.type).get();
                if (ref.exists) vm.productionContract = { ...vm.productionContract, type: ref.data().name };

                ref = await db.collection("commodity_subtypes").doc(vm.productionContract.subtype).get();
                if (ref.exists) vm.productionContract = { ...vm.productionContract, subtype: ref.data().name };

                ref = await db.collection('commodity_categories').doc(vm.productionContract.category).get();
                if (ref.exists) vm.productionContract = {...vm.productionContract, category: ref.data().name};

                ref = db.collection("inbox");
                ref = ref.where("entity", "==", "productionContract");
                ref = ref.where("productionContractID", "==", vm.productionContractID);
                ref = ref.where("to",'==', vm.user.role.admin?vm.productionContract.userID : vm.user.uid)
                ref = ref.where("is_restore", '==', null);

                let docs = await ref.get();
                if (!docs.empty){
                    vm.productionContract.questions = docs.docs.map((d) => {
                        return { ...d.data(), id: d.id };
                    });
                } else {
                    vm.productionContract.questions = [];
                }
                
                var items = [];
                var lastProducerId = "";

                vm.productionContract.questions.forEach((item) => {
                    let checkIfProducer = vm.checkIfProducerExist(items, item.producerID);
                    if(lastProducerId !== item.producerID && checkIfProducer.length == 0 && (item.isProductionContractCreateByBuyer != true || item.isProductionContractCreateByBuyer == undefined) ){
                        items.push(item);
                    }
                    lastProducerId = item.producerID;
                    if(item.producerID == vm.user.uid && vm.user.role.admin){
                        vm.showQuestionModule = false;
                    }
                  
                });
                vm.productionContract.questions = items;
                
                let totalAvailableAcres = parseInt(vm.productionContract.available_acres);

                let acceptAmountRef = await db.collection("production_contract_accepted_amounts")
                        .where("productionContractID", "==", this.productionContractID).get();
                
                let acceptedAmount = acceptAmountRef.docs.map((o) => {
                    return { amount: o.data().fullFillAmount };
                });

                acceptedAmount.forEach((o) => (totalAvailableAcres = totalAvailableAcres - parseInt(o.amount)))

                vm.totalAvailableAcres = totalAvailableAcres;


                let promises = [];
                if (vm.productionContract.specifications) {
                    vm.productionContract.specifications.map((c) =>
                        promises.push(vm.getSpecification(c))
                    );
                }

                let specifications = await Promise.all(promises);
                specifications.filter((c) => c).map((c) => vm.specifications.push(c));

                bus.$emit('prepareTitle',{...vm.productionContract, isProductionContract:true});

            } 
            vm.loading = false;
        },
        reloadQuestions: function reloadQuestions(){ 
            const vm = this;
            vm.loading = true;
            vm.showQuestionModule = false;
            vm.productionContract = []
            vm.specification = null;
            vm.load();
        },
    },

    async mounted(){
        const vm = this;
        vm.isSubscribe = !vm.user.role.admin && vm.user?.subscription?.stripeSubscription?.status === 'active';
        if(vm.user.role.admin){
            vm.isSubscribe = true;
        }
        
        vm.load();
    }, 
}
</script>
<style>
    .h1, .h3 {
        margin-bottom: 0px;
    }

    .h6 {
        margin-bottom: 30px;
    }
    .equal-width{
        width: 50%;
    }
    @media screen and (max-width:767px) {
        .equal-width{
            width: 100%;
        }  
        .equal-width.border-left{
            border: 0 !important;
        } 
    }
</style>

