<template>
    <div class="container">
        <div class="row">
            <div class="col-12 pb-2">
                <div class="bg-white rounded pr-2 pr-md-4 pl-2 pl-md-4 pt-2 pb-2">
                    <div><small class="display-inline mr-2">Filters:</small></div>
                    <div class="d-block d-md-flex productionContract-filters">
                        <Multiselect v-model="selectedBidRequestCategoryType" track-by="id" label="name" placeholder="Category" :options="bidRequestFilters.category" :searchable="true" :allow-empty="true"  />
                        <Multiselect v-model="selectedBidRequestType" track-by="id" label="name" placeholder="Product" :options="bidRequestFilters.commodity" :searchable="true" :allow-empty="true" class="ml-0 ml-md-2" />
                        <Multiselect v-model="selectedBidRequestSubType" track-by="id" label="name" placeholder="Type" :options="bidRequestFilters.subtype" :searchable="true" :allow-empty="true"  class="ml-0 ml-md-2" />
                        <Multiselect v-model="selectedBidRequestSpecification" track-by="id" label="name" placeholder="Specification" :options="bidRequestFilters.specification" :searchable="true" :allow-empty="true"  class="ml-0 ml-md-2" />
                        <Multiselect v-if="user.role.admin" v-model="selectedStatus" track-by="id" label="name" placeholder="Active" :options="[{id:'yes','name':'Yes'},{id:'no','name':'No'}]" :searchable="true" :allow-empty="true" class="ml-0 ml-md-2" />
                        <b-button @click="clearFilters" :disabled="!hasFilters" variant="primary" class="mt-2 mt-md-0 ml-0 ml-md-2">CLEAR</b-button>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" v-show="!user.role.Buyer">
                <div class="bg-white rounded d-md-flex pl-2 pt-2 pb-1">
                    <div class="col-md-7">
                        <div>
                            <div class="filterOption">
                                <b-form-group v-slot="{ ariaDescribedby }">
                                    <b-form-radio-group
                                        id="btn-radios-1"
                                        v-model="filterOption"
                                        :options="filterOptions"
                                        :aria-describedby="ariaDescribedby"
                                        @change="changeFilterOption"
                                        name="radios-btn-default"
                                        buttons
                                    ></b-form-radio-group>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row" v-if="!filterOption">
                            <div class="col-md-6 pb-2">
                                <gmap-autocomplete 
                                    @place_changed="setStartPlace"
                                    placeholder="Please enter the pickup point"
                                    :enable-geolocation="true"
                                    :value="startPlace"
                                    id="toAddress"
                                    :required="true"
                                    :select-first-on-enter="true"
                                >
                                </gmap-autocomplete>
                            </div>
                            <div class="col-md-6">
                                <gmap-autocomplete @place_changed="setDestinationPlace"
                                    placeholder="Please enter the destination point"
                                    id="fromAddress"
                                    :value="destinationPlace"
                                    :enable-geolocation="true"
                                    :required="true"
                                    :select-first-on-enter="true"
                                >
                                </gmap-autocomplete>
                            </div>
                            <div class="invalid-feedback d-block pl-3" v-if="positionFailed">Please select the location pickup point and destination point.</div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-6 pb-2">
                                <gmap-autocomplete @place_changed="setPlaceSpecificLocation"
                                    placeholder="Enter location"
                                    id="locationAddress"
                                    :value="specificPlace"
                                    :enable-geolocation="true"
                                    :required="true"
                                    :select-first-on-enter="true"
                                ></gmap-autocomplete>
                            </div>
                            <div class="invalid-feedback d-block pl-3" v-if="positionFailed">Please select the location</div>
                        </div>
                        <div class="pt-2 d-flex flex-column">
                            <div class="mt-1 mb-2"> 
                                <ValidationProvider
                                    name="searchRadiusMiles"
                                    :rules="{required:true, decimal:0,minimum:10, maximum:maximumLimitOfMiles}"
                                    v-slot="{ passed, failed, errors }"
                                >
                                    <b-form-group
                                        :state="(failed?false:(passed?true:null))"  
                                        id="input-group-1"
                                        label="Range with search miles:"
                                        label-for="input-card" 
                                        label-class="font-weight-bold text-dark"
                                    >
                                        <b-form-input v-model.number="searchRadiusMiles" id="searchMilesRange" min="0" max="100" placeholder="Enter miles" :state="(failed?false:(passed?true:null))" style="max-width:100px;" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                                    {{ errors[0]=='Minimum'?'Enter minimum 10 miles': errors[0]=='Maximum'?'Enter maximum ' + maximumLimitOfMiles + ' miles':'Enter valid miles'}}
                                    </b-form-invalid-feedback>
                                </ValidationProvider>
                                <div class="invalid-feedback d-block pl-3" v-if="maximumLimitOfMilesFailed">Enter valid miles</div>
                            </div>
                            <!-- <label for="range-1">Range with min and max search miles</label> -->
                        </div>
                    </div>
                    <div class="col-md-5  d-flex flex-column justify-content-between">
                        <div class="d-flex justify-content-md-end">
                            <b-button variant="info" @click="searchWithLocation" class="mt-2 ml-0 ml-md-2">Search Location</b-button>
                            <b-button variant="warning" @click="clearLocationFilters" class="mt-2 ml-0 ml-md-2">Clear</b-button>
                        </div>
                        <div class="mapOptions d-flex justify-content-md-end" >
                            <div class="mr-2"  v-if="!viewOption && user.role.admin">
                                <b-button @click="deleteSelectBitRequest" variant="danger">
                                    Delete
                                </b-button>
                            </div>
                            <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                id="btn-radios-1"
                                v-model="viewOption"
                                :options="options"
                                :aria-describedby="ariaDescribedby"
                                name="radios-btn-default"
                                buttons />
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div v-if="loading||data.length==0" class="row mt-0 mt-md-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 v-if="loading" class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                <h4 v-else class="card-title mb-4">No Product BID Requests Found</h4>
                <p>Please change your filters.</p>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else>
            <div v-if="!viewOption" class="row">
                <div v-for="bidRequest in getUniqueValueFromArray(data)" :key="bidRequest.id" class="col-12 col-md-6 col-lg-4">
                    <b-card  class="card-commodity border fixed-card-body" @click="viewBidRequest(bidRequest.id)">
                        <template #header>
                            <h5 class="text-primary mt-0 mb-0 font-weight-bold d-flex">
                                <span class="w-100">{{bidRequest.subtype}} {{ bidRequest.type}}</span>
                                <span v-on:click.stop>
                                    <b-checkbox size="is-large" :disabled="!isSubscribe" :value="bidRequest" v-model="selectDeleteProductBitRequest" v-on:click.stop></b-checkbox>
                                </span>
                            </h5>
                        </template>
                        <span v-if="bidRequest.location" class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Location</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{bidRequest.location}}</strong></span>
                        <span class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4"> {{ bidRequest.priceOption ? bidRequest.priceOption : "" }} Price (Per {{bidRequest.unit}})</span>
                            <strong class="d-block d-md-inline flex-fill text-left text-md-right" v-if="bidRequest.price != null">${{numeral(bidRequest.price).format('0,000.00')}}</strong>
                            <strong class="d-block d-md-inline flex-fill text-left text-md-right" v-else>-</strong>
                        </span>
                        <span class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Delivery Window</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{moment(bidRequest.startDate.toDate()).format('ll')}} to {{moment(bidRequest.endDate.toDate()).format('ll')}}</strong></span>
                        <span class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-mutes flex-fill pr-4">Variety</span><strong class="d-block d-md-inline flex-fill text-left text-md-right"> {{ bidRequest.variety }} </strong></span>
                        <div :class="user.role.admin || bidRequest.userID == user.uid ? 'buttonMargin' : ''">
                            <b-button @click="shareBidRequestURL(bidRequest)" variant="info" size="sm" block class="mt-2 fixed-share-bottom" :class="[!user.role.admin && user.uid != bidRequest.userID ? 'mb-0': '']" v-on:click.stop>Share</b-button>
                            <b-button :disabled="!isSubscribe" @click="$router.push('/bid/request/edit/'+ bidRequest.id)" variant="primary" v-if="user.role.admin||user.uid==bidRequest.userID" size="sm" block class="mt-4 fixed-bottom" v-on:click.stop>Edit</b-button>
                            <b-button :disabled="deleteButton" @click="deleteBidRequest(bidRequest)" variant="danger" v-if="user.role.admin" size="sm" class="mt-4 fixed-delete-bottom" v-on:click.stop>Delete</b-button>
                        </div>
                    </b-card>
                </div>
            </div>
            <div class="col-12 bidRequestShowMap p-0 mt-2" v-show="viewOption">
                <div class="p-2 bg-white"  style="background: #fff;">
                    <div v-if="data.length > 0">
                        <gmap-map :zoom ="zoom" :options="{gestureHandling: 'greedy', streetViewControl: false}" :center="(bidRequestPlace.length > 0) ? bidRequestPlace[0].position : data[0].placePosition" style="width:100%; height: 520px;" v-if="data[0].placePosition != undefined" ref="bidRequestMap" class="mt-0">
                            <gmap-cluster  :minimumClusterSize="4" :maxZoom = "5" :gridSize="90" :zoomOnClick="true"  :enableRetinaIcons="true">
                                <gmap-marker
                                    v-for="bidRequest in getUniqueValueFromArray(data)"
                                    :key="bidRequest.id"
                                    :position="bidRequest.bidRequestLocation.position"
                                    :clickable="true"
                                    @click="openBidRequest(bidRequest.id)"
                                    @mouseover = "statusText = bidRequest.id"
                                    @mouseout="statusText = null"
                                >
                                    <gmap-circle
                                        :center = "filterOption ? bidRequestPlace[0].position : routesCenterPoints"
                                        :radius=" filterOption ? radiusMeter : routesPointRadius + radiusMeter"
                                        ref="circle"
                                        :options="{strokeColor: '#000000', strokeOpacity: 0.3, strokeWeight: 1, fillColor:'#FF6600', fillOpacity:0.10}"
                                        v-if="bidRequestPlace.length > 0"
                                    >
                                    </gmap-circle>
                                    <gmap-polyline
                                        :path.sync="routesPolylinePath"
                                        :editable = "false"
                                        ref="polyline"
                                        v-if="!filterOption"
                                        :options="{strokeColor: '#1967D2', strokeOpacity: 0.7}"
                                    >
                                    </gmap-polyline>
                                    <gmap-info-window
                                        :closeclick="true"
                                        @closeclick="openBidRequest(null)"
                                        :opened="openBidRequestId === bidRequest.id"
                                    >
                                    
                                        <h5 class="text-primary mt-0 mb-0 font-weight-bold d-flex"><span class="w-100">{{bidRequest.subtype}} {{bidRequest.type}}</span></h5>
                                        <span v-if="bidRequest.location" class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Location</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{bidRequest.location}}</strong></span>
                                        <span class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">{{ bidRequest.priceOption ? bidRequest.priceOption : "" }} Price (Per {{bidRequest.unit}})</span>
                                            <strong class="d-block d-md-inline flex-fill text-left text-md-right" v-if="bidRequest.price != null" >${{numeral(bidRequest.price).format('0,000.00')}}</strong> 
                                            <strong class="d-block d-md-inline flex-fill text-left text-md-right" v-else >-</strong> 
                                        </span>
                                        <span class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Delivery Window</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{moment(bidRequest.startDate.toDate()).format('ll')}} to {{moment(bidRequest.endDate.toDate()).format('ll')}}</strong></span>
                                        <b-button variant="info" @click="viewBidRequest(bidRequest.id)">View</b-button>
                                    </gmap-info-window>

                                </gmap-marker>
                            </gmap-cluster>
                        </gmap-map>
                    </div>
                    <div v-else>
                        No Results 
                    </div>
                </div>
            </div>
        </div>
        <div>
            <b-modal
                id="subscribe-modal"
                title="Membership Required"
                visible
                hide-footer
                @hidden="modalVisible = false"
                v-model="subscribeOnModelVisible"
                centered
                aria-labelledby="membership-required-title" 
            >
                <template #modal-header class="align-items-center">
                    <h5 id="membership-required-title" class="modal-title ">
                        <i class="fa fa-lock me-2"></i> Membership Required
                    </h5>
                    <b-button variant="link" size="sm" @click="subscribeOnModelVisible = false" aria-label="Close"  class="p-0 text-dark">
                        <i class="fa fa-times"></i>
                    </b-button>
                </template>

                <div class="text-center py-3">
                    <p class="mb-4 text-left">
                        You need an active membership to access this feature. Please become a member to continue.
                    </p>
                    <b-button
                        variant="primary"
                        size="lg"
                        @click="redirectToSubscription"
                        class="px-5"
                        aria-label="Become a Member"
                    >
                        <i class="bi bi-person-plus me-2"></i> Become a Member
                    </b-button>
                </div>
            </b-modal>
        </div>
        <div>
            <b-modal ref="model" centered v-model="copyLinkPopup">
                <template>
                <span><p class="h5 font-bold mb-0">Shareable link has been copied.</p></span>
                </template>
                <template #modal-footer="{ok,cancel}">
                <b-button size="xl" variant="success"  @click="cancel()">
                    Close
                </b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import store from '@/vuex/store.js';
import {db} from '@/firebase';
import {bus} from '@/vuex/bus.js';
import { docData } from 'rxfire/firestore';
import { of, from, combineLatest, forkJoin } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {mapState} from 'vuex'
import Multiselect from 'vue-multiselect'
import moment from 'moment';
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import * as geofire from 'geofire-common'; // this use for geo queries
import { gmapApi } from "vue2-google-maps";
import { Clipboard } from "v-clipboard"
import firebase from 'firebase';

Vue.prototype.moment = moment;
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FIREBASE_CONFIG_GOOGLE_API_KEY,
    libraries: "places"
  }
});


export default {

    computed: {
        ...mapState(['user','bidRequestFilters']),
        hasFilters(){
            const vm = this;
            return vm.selectedBidRequestCategoryType || vm.selectedBidRequestType || (vm.user.role.admin&&vm.selectedStatus) || vm.selectedBidRequestSubType || vm.selectedProductionContractSpecification;
        },
        google: gmapApi,
    },
    components: {
        Multiselect,
        GmapCluster
    },
    data() {
        return {
            data: [],
            bidRequestPlace: [],
            getRouteAllCoordinate: [],
            routesPolylinePath: [],
            routesCenterPoints: '',
            routesPointRadius:  '',
            loading: true,
            mounting: false,
            place: [],
            filterOption: true,
            specificPlace: '',
            startPlace: '',
            destinationPlace: '',
            positionFailed: null,
            searchRadiusMiles: 50,
            viewOption: true,
            zoom: 12,
            filterOptions: [
                { text: 'Location', value: true },
                { text: 'Along Route', value: false}
            ],
            options: [
                { text: 'Map', value: true, icon: "fa fa-map"},
                { text: 'List', value: false, icon: "fa fa-list"}
            ],
            selectedBidRequestCategoryType:null,
            selectedBidRequestType:null,
            selectedBidRequestSubType:null,
            selectedBidRequestSpecification:null,
            maximumLimitOfMiles: null,
            maximumLimitOfMilesFailed: null, 
            selectedStatus:{id:'yes',name:'Yes'},
            openBidRequestId: null,
            copyLinkPopup: false,
            deleteButton: false,
            selectDeleteProductBitRequest: [],
            isSubscribe: false,
            subscribeOnModelVisible: false,
        }
    },

    watch: {
        selectedStatus(){
            const vm = this;
            if(!vm.mounting){
                (vm.bidRequestPlace.length > 0) ? vm.searchWithLocation() : vm.loadBidRequest();
            } 
        },

        async selectedBidRequestCategoryType(){
            const vm = this;
            localStorage.selectedBidRequestCategoryType=JSON.stringify(vm.selectedBidRequestCategoryType);
            if(!vm.mounting){
                await vm.loadTypes();
                (vm.bidRequestPlace.length > 0) ? vm.searchWithLocation() : vm.loadBidRequest();
            }
        },
        async selectedBidRequestType(){
            const vm = this;
            localStorage.selectedBidRequestType=JSON.stringify(vm.selectedBidRequestType);
            if(!vm.mounting) {
                await vm.loadSubTypes();
                (vm.bidRequestPlace.length > 0) ? vm.searchWithLocation() : vm.loadBidRequest();
            } 
        },
        selectedBidRequestSubType(){
            const vm = this;
            localStorage.selectedBidRequestSubType=JSON.stringify(vm.selectedBidRequestSubType);
            if(!vm.mounting) {
                (vm.bidRequestPlace.length > 0) ? vm.searchWithLocation() : vm.loadBidRequest();
            }    
        },
        selectedBidRequestSpecification(){
            const vm = this;
            localStorage.selectedBidRequestSpecification=JSON.stringify(vm.selectedBidRequestSpecification);
            if(!vm.mounting) {
                (vm.bidRequestPlace.length > 0) ? vm.searchWithLocation() : vm.loadBidRequest();
            } 
        },  

    },

    mounted() {
        const vm = this;

        vm.isSubscribe = !vm.user.role.admin && vm.user?.subscription?.stripeSubscription?.status === 'active';
        if(vm.user.role.admin){
            vm.isSubscribe = true;
        }
        
        if(store.state.limitOfMiles != undefined){
           vm.maximumLimitOfMiles =  JSON.parse(JSON.stringify(store.state.limitOfMiles.limitMiles));
        } else {
            vm.maximumLimitOfMiles = 100;
        }
         vm.load();
    },

    methods: {
        async load(){
            const vm = this;
            vm.mounting=true;
            const selectedCategoryType =  localStorage.getItem('selectedBidRequestCategoryType');
            if(localStorage.selectedBidRequestCategoryType){
                vm.selectedBidRequestCategoryType=JSON.parse(selectedCategoryType);
                await vm.loadTypes();
            }

            const selectedBidRequestType = localStorage.getItem('selectedBidRequestType');
            if(localStorage.selectedBidRequestType){
                vm.selectedCommodityType = JSON.parse(selectedBidRequestType);
                await vm.loadSubTypes();
            }

            vm.selectedBidRequestSubType = JSON.parse(localStorage.getItem('selectedBidRequestSubType') || null);
            vm.selectedBidRequestSpecification = JSON.parse(localStorage.getItem('selectedBidRequestSpecification') || null);

            await vm.loadBidRequest();
            bus.$emit('prepareTitle',null);
            vm.mounting=false;
        },

        async loadBidRequest() {
            const vm = this;
            vm.loading = true;
            vm.data = [];

            let ref = db.collection('bid_request');
            ref = ref.where('is_delete', '==', 0);

            if (!vm.user.role.admin) {
                ref = ref.where('active', '==', true);
            }

            if (vm.selectedStatus != null) {
                ref = ref.where('active', '==', (vm.selectedStatus.id == 'yes'));
            }

            if (vm.selectedBidRequestCategoryType != null) {
                ref = ref.where('category', '==', vm.selectedBidRequestCategoryType.id);
            }

            if (vm.selectedBidRequestType != null) {
                ref = ref.where('type', '==', vm.selectedBidRequestType.id);
            }

            if (vm.selectedBidRequestSubType != null) {
                ref = ref.where('subtype', '==', vm.selectedBidRequestSubType.id);
            }

            if (vm.selectedBidRequestSpecification != null) {
                ref = ref.where('specification', 'array-contains', vm.selectedBidRequestSpecification.id);
            }

            const bidRequests$ = from(ref.get()).pipe(
                switchMap((querySnapshot) => {
                    if (querySnapshot.empty) return of([]);

                    const bidRequestDocs = querySnapshot.docs.map(doc => doc.data());
                    return forkJoin(
                        bidRequestDocs.map(async (docData) => {
                            const unitDoc = await db.collection('units').doc(docData.unit).get();
                            const typeDoc = await db.collection('commodity_types').doc(docData.type).get();
                            const subtypeDoc = await db.collection('commodity_subtypes').doc(docData.subtype).get();
                            const locationDoc = await db.collection('bid_request_location').doc(docData.id).get();
                            const priceOptionDoc = await db.collection('bid_price_options').doc(docData.priceOption).get();

                            const unit = unitDoc.exists ? unitDoc.data() : {};
                            const type = typeDoc.exists ? typeDoc.data() : {};
                            const subtype = subtypeDoc.exists ? subtypeDoc.data() : {};
                            const location = locationDoc.exists ? locationDoc.data() : {};
                            const priceOption = priceOptionDoc.exists ? priceOptionDoc.data() : {};

                            return {
                                ...docData,
                                unit: unit.name,
                                unitId: docData.unit,
                                type: type.name,
                                typeId: docData.type,
                                subtype: subtype.name,
                                subtypeId: docData.subtype,
                                bidRequestLocation: location,
                                priceOption: priceOption.name
                            };
                        })
                    );
                })
            );

            bidRequests$.subscribe((data) => {
                vm.data = data;
                vm.loading = false;

                if (vm.data.length > 0) {
                    vm.setMapsZoomLevel();
                }
            });
        },


        // async loadBidRequest(){
        //     const vm = this;
        //     vm.loading=true;
        //     vm.data = [];
        //     let ref = db.collection('bid_request');
        //     ref = ref.where('is_delete', '==', 0);
        //     if(!vm.user.role.admin){
        //         ref = ref.where('active', '==', true)
        //     }

        //     if(vm.selectedStatus != null){
        //         ref = ref.where('active','==',(vm.selectedStatus.id=='yes'?true:false));
        //     }

        //     if(vm.selectedBidRequestCategoryType != null){
        //         ref = ref.where('category','==',vm.selectedBidRequestCategoryType.id);
        //     }

        //     if(vm.selectedBidRequestType != null){
        //         ref = ref.where('type','==',vm.selectedBidRequestType.id);
        //     }
        //     if(vm.selectedBidRequestSubType != null){
        //         ref = ref.where('subtype','==',vm.selectedBidRequestSubType.id);
        //     }
        //     if(vm.selectedBidRequestSpecification != null){
        //         ref = ref.where('specification','array-contains',vm.selectedBidRequestSpecification.id);
        //     }

        //     let ref$ =  from(ref.get()).pipe(
        //         switchMap((bidRequests)=>{
        //             if(bidRequests.empty) return of([]);
        //             return combineLatest(...bidRequests.docs.map(c=>{
        //             return docData(db.collection('units').doc(c.data().unit)).pipe(
        //                     map((t)=>{
        //                         return {...c.data(),unit:t.name, unitId: c.unit}
        //                     })
        //                 )
        //             }))
        //         })
        //     ).pipe(
        //         switchMap(bidRequests=>{
        //             if(bidRequests.length==0) return of([]);
        //             return combineLatest(...bidRequests.map(c=>{
        //                 return docData(db.collection('commodity_types').doc(c.type)).pipe(
        //                     map(t=>{
        //                         return {...c,type:t.name, typeId: c.type}
        //                     })
        //                 )
        //             }))
        //         })
        //     ).pipe(
        //         switchMap(bidRequests=>{
        //             if(bidRequests.length==0) return of([]);
        //             return combineLatest(...bidRequests.map(c=>{
        //                 return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
        //                 map(t=>{
        //                     return {...c,subtype:t.name, subtypeId: c.subtype}
        //                 })
        //                 )
        //             }))
        //         })
        //     ).pipe(
        //         switchMap(bidRequests=>{
        //           if(bidRequests.length==0) return of([]);
        //             return combineLatest(...bidRequests.map(c=> {
        //                 return docData(db.collection('bid_request_location').doc(c.id)).pipe(
        //                     map(l => {
        //                         return {...c, bidRequestLocation: l}
        //                     })
        //                 )
        //             }))
        //         })
        //     ).pipe(
        //         switchMap(bidRequests => {
        //             if(bidRequests.length==0) return of([]);
        //                 return combineLatest(...bidRequests.map(c=>{
        //                     return docData(db.collection('bid_price_options').doc(c.priceOption)).pipe(
        //                         map(p => {
        //                             return {...c, priceOption: p.name}
        //                         })
        //                     )
        //                 }));
        //         })
        //     )
        //     ref$.subscribe((data) => {
        //         Array.from(Array(data.length).keys()).map(async(x) => {                    
        //             vm.data.push(data[x]);
        //         });
        //         vm.loading = false;    
        //         // if(vm.user.role.producer || vm.user.role.admin){
        //             if(vm.data.length > 0){
        //                 vm.setMapsZoomLevel();
        //             }
        //         // }
        //     });
        // },


        async deleteSelectBitRequest(){
            const vm = this;
            if(vm.selectDeleteProductBitRequest.length == 0){
                return;
            } else if (confirm('Are you sure you want to delete this Product Bit Requests?')){
                vm.loading = true;
                if(vm.selectDeleteProductBitRequest.length > 0){
                    Array.from(Array(vm.selectDeleteProductBitRequest.length).keys()).map(async(x) =>{
                        let ref = await db.collection('bid_request').doc(vm.selectDeleteProductBitRequest[x].id);
                        ref.update({is_delete: 1, deleted: firebase.firestore.FieldValue.serverTimestamp()}).then(() => {
                            vm.loading = false;
                            vm.data = [];
                            vm.loadBidRequest();
                        })
                    })
                }
            }
        },

        
        redirectToSubscription(){
            const vm = this;
            vm.$router.replace("/profile")
        },


        async searchWithLocation(){
            const vm = this;
            vm.loading = true;
            vm.data = [];
            if(vm.bidRequestPlace.length == 0){
                vm.bidRequestPlace = vm.place;
                vm.place = []; 
            }

            if((vm.destinationPlace != "") && (vm.startPlace != "") || (vm.specificPlace != "") && (vm.searchRadiusMiles <= vm.maximumLimitOfMiles)){
                const radiusInM = (vm.searchRadiusMiles * 1609.34);
                vm.radiusMeter = radiusInM;
                vm.positionFailed=null;
                vm.maximumLimitOfMilesFailed = null;
                const promises = [];
                
                if(vm.filterOption == false){
                    vm.routesCenterPoints = "";
                    vm.routesPolylinePath = [];
                    await vm.getAllPossibleCoordinates();
                }
                if(vm.getRouteAllCoordinate.length != 0 && vm.filterOption == false){
                    vm.getRouteAllCoordinate.forEach(async(route) => {
                        const center = [route.lat, route.lng];   
                        const bounds= geofire.geohashQueryBounds(center, radiusInM); 
                        for(const b of bounds){
                            var q = db.collection('bid_request_location');
                            q = q.orderBy('geohash').startAt(b[0]).endAt(b[1])
                            promises.push(q.get());
                        }
                       await vm.getBidRequestData(promises, center);
                    });
                } else {
                    vm.bidRequestPlace.forEach(async(place) => {
                        const center = [place.position.lat, place.position.lng];    
                        const bounds= geofire.geohashQueryBounds(center, radiusInM);
                        for(const b of bounds){
                            const q = db.collection('bid_request_location').orderBy('geohash').startAt(b[0]).endAt(b[1]);
                            promises.push(q.get());
                        }
                       await vm.getBidRequestData(promises, center);
                    });
                }

                vm.getRouteAllCoordinate = [];
                if(vm.data.length <= 0 ){
                    setTimeout(() => {
                        vm.loading = false;
                        if(vm.data.length > 0){
                            vm.setMapsZoomLevel(); 
                        }                                                     
                    }, 4000);
                }
                if(vm.data.length == 0){
                    setTimeout(() => {
                        vm.loading = false;   
                    }, 4000);
                }
            } else {
                if(!(vm.searchRadiusMiles <= vm.maximumLimitOfMiles)){
                    vm.maximumLimitOfMilesFailed = true
                }
                vm.positionFailed = true
                vm.loading = false
            }
            console.log('end');
        },

        getBidRequestData(promises, center){
            const vm = this;
            const radiusInM = (vm.searchRadiusMiles * 1609.34);
            vm.radiusMeter = radiusInM
            Promise.all(promises).then((snapshots) => {
                const matchingDocs =[];
                for (const snap of snapshots) {
                    for(const doc of snap.docs) {
                        const distanceInKm = geofire.distanceBetween([doc.get('position.lat'), doc.get('position.lng')], center);
                        const distanceInM = (distanceInKm) * 1000;
                        if (distanceInM <= radiusInM) {
                            matchingDocs.push(doc);
                        }
                    }
                }
                return matchingDocs
            }).then(async(matchingDocs) => {
                for(const doc of matchingDocs) {
                    const bidRequestID = doc.id;
                    let ref = db.collection('bid_request').where('id', '==', bidRequestID);
                    // ref = ref.where('is_delete', '==', 0)
                    if(!vm.user.role.admin){
                        ref = ref.where('active', '==', true)
                    }

                    if(vm.selectedStatus != null){
                        ref = ref.where('active','==',(vm.selectedStatus.id=='yes'?true:false));
                    }

                    if(vm.selectedBidRequestCategoryType != null){
                        ref = ref.where('category','==',vm.selectedBidRequestCategoryType.id);
                    }

                    if(vm.selectedBidRequestType != null){
                        ref = ref.where('type','==',vm.selectedBidRequestType.id);
                    }
                    if(vm.selectedBidRequestSubType != null){
                        ref = ref.where('subtype','==',vm.selectedBidRequestSubType.id);
                    }
                    if(vm.selectedBidRequestSpecification != null){
                        ref = ref.where('specification','array-contains',vm.selectedBidRequestSpecification.id);
                    }

                    let ref$ =  from(ref.get()).pipe(
                        switchMap((bidRequests)=>{
                            if(bidRequests.empty) return of([]);
                            return combineLatest(...bidRequests.docs.map(c=>{
                            return docData(db.collection('units').doc(c.data().unit)).pipe(
                                    map((t)=>{
                                        return {...c.data(),unit:t.name, unitId: c.unit}
                                    })
                                )
                            }))
                        })
                    ).pipe(
                        switchMap(bidRequests=>{
                            if(bidRequests.length==0) return of([]);
                            return combineLatest(...bidRequests.map(c=>{
                                return docData(db.collection('commodity_types').doc(c.type)).pipe(
                                    map(t=>{
                                        return {...c,type:t.name, typeId: c.type}
                                    })
                                )
                            }))
                        })
                    ).pipe(
                        switchMap(bidRequests=>{
                            if(bidRequests.length==0) return of([]);
                            return combineLatest(...bidRequests.map(c=>{
                                return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
                                map(t=>{
                                    return {...c,subtype:t.name, subtypeId: c.subtype}
                                })
                                )
                            }))
                        })
                    ).pipe(
                        switchMap(bidRequests=>{
                        if(bidRequests.length==0) return of([]);
                            return combineLatest(...bidRequests.map(c=> {
                                return docData(db.collection('bid_request_location').doc(c.id)).pipe(
                                    map(l => {
                                        return {...c, bidRequestLocation: l}
                                    })
                                )
                            }))
                        })
                    ).pipe(
                        switchMap(bidRequests => {
                            if(bidRequests.length==0) return of([]);
                                return combineLatest(...bidRequests.map(c=>{
                                    return docData(db.collection('price_options').doc(c.priceOption)).pipe(
                                        map(p => {
                                            return {...c, priceOption: p.name}
                                        })
                                    )
                                }));
                        })
                    )
                    ref$.subscribe((data) => {
                        Array.from(Array(data.length).keys()).map(async(x) => {                    
                            vm.data.push(data[x]);
                        });
                        vm.loading = false;    
                        console.log(data);
                        if(vm.data.length > 0){
                            vm.setMapsZoomLevel();
                        }
                    })
                }
            })

        },

        async getAllPossibleCoordinates(){
            try {
                const vm = this;
                vm.getRouteAllCoordinate = [];
                const directionService = new vm.google.maps.DirectionsService();
                const request = {
                    origin: vm.startPlace,
                    destination: vm.destinationPlace,
                    travelMode: 'DRIVING',
                }
                from(directionService.route(request)).pipe(
                    map((response) => {
                        const getDirectionData = JSON.parse(JSON.stringify(response));
                        const route = getDirectionData.routes[0].legs[0];
                        const routeDistance = route.distance.value;
                        const startLocation = route.start_location;
                        const endLocation = route.end_location;
              
                        const centerPoint = {
                            lat: (startLocation.lat + endLocation.lat) / 2,
                            lng: (startLocation.lng + endLocation.lng) / 2,
                        };

                        let routesPolylinePath = [];
                        response.routes[0].legs.forEach((leg) => {
                            leg.steps.forEach((step) => {
                                const nextSegment = step.path;
                                    if (nextSegment.length !== 2) {
                                        nextSegment.forEach((segment) => {
                                        routesPolylinePath.push(segment);
                                        });
                                    }
                            });
                        });
                        console.log(centerPoint);
                        vm.routesCenterPoints = centerPoint;
                        vm.routesPointRadius = routeDistance / 2;

                        let allCoordinates = [];
                        response.routes.forEach((route) => {
                            route.legs.forEach((leg) => {
                                leg.steps.forEach((step) => {
                                    if (step.start_location) {
                                    allCoordinates.push(step.start_location);
                                    }
                                });
                            });
                        });
                        return { routesPolylinePath, allCoordinates };
                    }),
                    catchError((error) => {
                        console.error('Error in getting route:', error.message);
                        return [];
                    })
                ).subscribe({
                    next: ({ routesPolylinePath, allCoordinates }) => {
                        vm.routesPolylinePath = routesPolylinePath;
                        vm.getRouteAllCoordinate = allCoordinates;
                    },
                    error: (err) => {
                        console.error('Stream Error:', err);
                    },
                    complete: () => {
                        console.log('Route calculation complete');
                    }
                })
                // await directionService.route(request).then((response) =>{
                //     const getDirectionData = JSON.parse(JSON.stringify(response));
                //     const route =  getDirectionData.routes[0].legs[0];
                //     const routeDistance = route.distance.value;
                //     const startLocation = route.start_location;
                //     const endLocation = route.end_location;
                //     const centerPoint  = {
                //         lat : (startLocation.lat + endLocation.lat) / 2,
                //         lng : (startLocation.lng + endLocation.lng) / 2, 
                //     } // calculate center point between alone routes

                //     var legs = response.routes[0].legs;
                //     for (var i = 0; i < legs.length; i++) {
                //         var steps = legs[i].steps;
                //         for (var j = 0; j < steps.length; j++) {
                //         var nextSegment = steps[j].path;
                //         if(nextSegment.length != 2){
                //             for (var k = 0; k < nextSegment.length; k++) {
                //             vm.routesPolylinePath.push(nextSegment[k]);
                //             }
                //         }
                //         }
                //     } // draw polyline between routes

                //     vm.routesCenterPoints = centerPoint;
                //     vm.routesPointRadius = (routeDistance / 2);
                //     getDirectionData.routes.forEach((route) => {
                //         route.legs.forEach((leg) => {
                //             leg.steps.forEach((step) => {
                //                 if(step.start_location){
                //                     vm.getRouteAllCoordinate.push(step.start_location);
                //                 }
                //             });
                //         });
                //     });
                // });
            } catch (error) {
                console.log(error.message);
            }
        },

        changeFilterOption(){
            const vm = this;
            vm.bidRequestPlace = [];
            vm.searchRadiusMiles = 50;
            vm.place = [];
            vm.startPlace = '';
            vm.destinationPlace = '';
            vm.specificPlace = '';
            vm.positionFailed = null;
            vm.loadBidRequest();
            vm.routesPolylinePath = [];
            vm.routesCenterPoints = "";
            vm.routesPointRadius = "";
        },

        clearLocationFilters(){
            const vm = this;
            vm.bidRequestPlace = [];
            vm.searchRadiusMiles = 50;
            vm.specificPlace = '';
            vm.startPlace = '';
            vm.destinationPlace = '';
            vm.data = [];
            vm.place = [];
            vm.getRouteAllCoordinate = [];
            vm.positionFailed=null;
            vm.loadBidRequest();
            vm.routesPolylinePath = [];
            vm.routesCenterPoints = "";
            vm.routesPointRadius = "";
        },

        clearFilters(){
            const vm = this;
            vm.selectedBidRequestCategoryType = null,
            vm.selectedBidRequestType = null,
            vm.selectedBidRequestCategoryType = null,
            vm.selectedBidRequestSpecification = null,
            vm.loading = true;
            vm.data = [];
            vm.loadBidRequest();
            if(vm.user.role.admin) vm.selectedStatus=null;
        },

        setStartPlace(startPlace) {
            console.log(startPlace);
            const vm = this;
            vm.getRouteAllCoordinate =[];
            vm.startPlace = startPlace.formatted_address
            var data = {
                position:{
                    lat: startPlace.geometry.location.lat(),
                    lng: startPlace.geometry.location.lng()
                },
            };
            vm.place.push(data);
        },

        setDestinationPlace(destinationPlace){
            const vm = this;
            vm.getRouteAllCoordinate =[];
            vm.destinationPlace = destinationPlace.formatted_address
            var data = {
                position:{
                    lat: destinationPlace.geometry.location.lat(),
                    lng: destinationPlace.geometry.location.lng()
                },
            };
            vm.place.push(data);
        },

        setPlaceSpecificLocation(specificPlace){
            const vm = this;
            if(vm.bidRequestPlace.length != 0){vm.bidRequestPlace = []}
            vm.specificPlace = specificPlace.formatted_address;
            var data = {
                position:{
                    lat: specificPlace.geometry.location.lat(),
                    lng: specificPlace.geometry.location.lng()
                },
            };
            vm.place.push(data);
        },

        async loadTypes(){
            const vm = this;
            let filters = store.state.bidRequestFilters;
            filters['commodity'] = [];
            vm.selectedBidRequestType=null;
            if(vm.selectedBidRequestCategoryType){
                let ref = await db.collection('commodity_types').where('category','==',vm.selectedBidRequestCategoryType.id).orderBy('name','asc').get();
                ref.forEach(t=>filters['commodity'].push({...t.data(),type:'commodity',id:t.id,checked:false,hidden:false}));
            }
            store.commit('updateBidRequestFilters',filters);
        },

        async loadSubTypes(){
            let vm = this;
            let filters = store.state.bidRequestFilters;
            filters['subtype']=[];
            vm.selectedBidRequestSubType=null;
            if(vm.selectedBidRequestType){
                let ref = await db.collection('commodity_subtypes').where('type','==',vm.selectedBidRequestType.id).orderBy('name','asc').get();
                ref.forEach(t=>filters['subtype'].push({...t.data(),type:'subtype',id:t.id,checked:false,hidden:false}));
            }
            store.commit('updateBidRequestFilters',filters);
        },

        openBidRequest(bidRequestID){
            const vm = this;
            vm.openBidRequestId = bidRequestID
        },

        async setMapsZoomLevel(){
            const vm = this;

            const bounds = new vm.google.maps.LatLngBounds();

            vm.data.forEach(d => {
                if (d.placePosition !== undefined) {
                    bounds.extend(d.placePosition);
                }
            });

            const zoomLevel$ = of(vm.data.length).pipe(
                map((length) => {
                    if (length > 10) return 4;     // Fewer items, zoom in
                    if (length >= 6) return 6;      // Moderate number of items
                    if (length >= 2) return 8;      // More items, zoom out
                    return 10;                       // Default zoom for more than 1 item
                })
            );

            zoomLevel$.subscribe((zoomLevel) => {
                vm.zoom = zoomLevel;
            });

            const mapRef = vm.$refs["bidRequestMap"];
            if (mapRef) {
                mapRef.fitBounds(bounds);
            }
        },

        // async setMapsZoomLevel(){
        //     let vm = this  
        //         var bounds = new vm.google.maps.LatLngBounds();
        //         for(let d of vm.data){
        //             if(d.placePosition != undefined){
        //                 bounds.extend(d.placePosition);
        //             }
        //         }
        //         setTimeout(function(){
        //             for(let ref in vm.$refs){
        //                 if(ref == "bidRequestMap"){
        //                     vm.$refs[ref].fitBounds(bounds);
        //                 }
        //             } 
        //         }, 1000)
        //     if(vm.data.length > 1){
        //         vm.zoom = 10;
        //     } else if (vm.data.length >= 6 && vm.data.length <= 2){
        //         vm.zoom = 8;
        //     } else if (vm.data.length >= 10 && vm.data.length <= 6){
        //         vm.zoom = 6;
        //     } else {
        //         vm.zoom = 4;
        //     }
        // },

        getUniqueValueFromArray(items){      
            return [
                ...new Map(
                    items.map((item) => [item['id'], item])
                ).values()
            ]
        },

        viewBidRequest (bidRequestID){
            const vm = this;

            vm.subscribeOnModelVisible = false;
            if(!vm.isSubscribe){
                vm.subscribeOnModelVisible = true;
                return;
            }     

            vm.$router.replace("/bid/request/" + bidRequestID)    
        },

        async shareBidRequestURL(bidRequest){
            const vm = this;

            vm.subscribeOnModelVisible = false;
            if(!vm.isSubscribe){
                vm.subscribeOnModelVisible = true;
                return;
            }
            const URL = `${window.location.origin}/bid/request/${bidRequest.slug ?? bidRequest.id}`;

            Clipboard.copy(URL).then(() => {
                vm.copyLinkPopup = true;
            });
        },

        async deleteBidRequest(bidRequest){
            const vm = this;
            if(confirm('Are you sure you want to delete this Production contract?')){
                vm.loading=true;
                vm.deleteButton = true;
                let ref = await db.collection('bid_request').doc(bidRequest.id);
                ref.update({is_delete: 1, deleted: firebase.firestore.FieldValue.serverTimestamp()}).then(() => {
                vm.deleteButton = false;
                vm.data = [];
                setTimeout(() => {
                    vm.loadBidRequest();
                }, 7000);
                });
            }
        },
    },                                  

}

</script>

<style>
.table-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card.card-commodity {
  height: calc(100% - 20px);
}
.card-commodity .card-header {
    background-color: unset !important;
}
.fixed-bottom {
  position: absolute;
  bottom: 40px;
  margin: 15px;
  width: calc(100% - 30px );
}
.fixed-delete-bottom {
  position: absolute;
  bottom: 10px;
  margin: 0px;
  width: calc(100% - 30px );
}
.fixed-card-body {
  padding-bottom: 100px
}

.card-img-top{
    width: 100%;
    height: 10vw;
    object-fit: cover;
}

@media (max-width:1620px){
  .card-img-top {
    height: 18vw;
}
}

@media (max-width:1024px){
  .card-img-top {
    height: 16vw;
}
}
@media (max-width:767px){
  .card-img-top {
    height: unset;
}
}
.text-color-red {
  color:red;
  font-size: 15px;
}
.buttonMargin{ 
    margin-top: 30px !important;
}
vdp-datepicker input[type="text"], .pac-target-input{
    border: 1px solid #a8a8a8 !important  ;
    border-radius: 3px !important;
    
}
.mapOptions .btn-secondary:not(:disabled):not(.disabled).active{
 background : #7ec313;
}
.filterOption .btn-secondary:not(:disabled):not(.disabled).active{
  background : #7ec313;
}

.productionContractShowMap .gm-style-iw-d{
  max-height: 354px !important;
}
.productionContractShowMap .gm-style .gm-style-iw-c{
  padding: 16px;
  max-height: 364px !important;
}
.productionContractShowMap  .gm-ui-hover-effect{
    top: -3px !important;
    right: -3px !important;
}
.productionContractShowMap  .gm-ui-hover-effect:focus {
    outline: 0px dotted;
    outline: 0x auto -webkit-focus-ring-color;
}


@media (max-width: 767px) {
  .productionContract-filters div {
      margin-bottom: 10px;
  } 
}


</style>