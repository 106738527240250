<template>
    <div class="card card-plain border-dotted">
      <div class="card-header text-black">Add Required Certification</div>
      <div class="card-body">
        <b-alert
          variant="danger"
          dismissible
          fade
          :show="error != null"
          @dismissed="error=null"
        >
          {{ error }}
        </b-alert>

        <div class="row mb-4">
          <div class="col">
            <label>Specification Type</label>
            <b-form-select v-model="specification.specification" :options="optionsSpecifications" @change="onChangesCertification"></b-form-select>
          </div>
        </div>
        <div v-if="uploading" class="row mt-4 mb-4">
          <div class="col-12 text-center"><b-progress :value="progressUpload" variant="primary" striped :animated="true"></b-progress><br /><small>Uploading...</small></div>
        </div>
        <div v-else-if="specification.file" class="row mt-4 mb-4">
          <div class="col-12 text-center"><a :disabled="deleting" @click="deleteFile" class="text-danger mr-2"><span :class="'fa '+(deleting?'fa-spinner':'fa-times')"></span></a><strong><em class="text-muted">{{specification.file.name}}</em></strong></div>
        </div>
        <div v-else class="row mt-4 mb-4">
          <div class="col-12 col-md-5"><label>Add Specification</label><b-input :disabled="formFieldsDisabled"  v-model="specificationURL" placeholder="Enter URL" /></div>
          <div class="col-12 col-md-2 text-center pt-4">or</div>
          <div class="col-12 col-md-5 text-center pt-4"><a :disabled="formFieldsDisabled" @click="selectFile" class="text-muted mt-2" :class="{'add-pointer': !formFieldsDisabled, 'remove-pointer': formFieldsDisabled}"><i class="fa fa-upload mr-1"></i>Upload Document</a></div>
        </div>
        <div class="row mt-4 mb-4">
          <div class="col-12">
              <label>Additional Information</label>
              <b-form-textarea
                class="form-controller"
                v-model="moreInformation"
                placeholder="Other"
                rows="5"
                :disabled="formFieldsDisabled"
              >
                {{ specification.more_information }}
              </b-form-textarea>
          </div>
        </div>
        <div class="row col-12">
          <div class="">
            <b-link :disabled="formFieldsDisabled" @click="addMore" size="sm" class="btn btn-info">+ Add</b-link>
          </div>
          <!-- <div class="ml-4">
            <b-link @click="addCertification" size="sm" class="btn btn-success">Save</b-link>
          </div> -->
        </div>
        <!-- <div class="row mt-4 mb-4">
          <div class="col-12"><b-button @click="addSpecification" :disabled="!specification.specification" size="sm" variant="primary" block>Add Certification</b-button></div>
        </div> -->
        <input class="d-none" id="upload"
                  type="file"
                  name="upload"
                  ref="uploadInput"
                  accept="image/*, application/pdf, .doc, .docx,.txt"
                  :multiple="false"
                  @change="detectFiles($event)" />
      </div>
    </div>
  </template>
  <script>
  import {mapState} from 'vuex'
  import {db} from '@/firebase';
  import {firestorage} from '@/firebase';
  export default {
    props: ['productionContractID', 'certificationIndex'],
    computed:{
      ...mapState(['user']),
    },
    data() {
      return {
        error:null,
        uploading:false,
        deleting:false,
        progressUpload: 0,
        uploadTask:'',
        tempCertificateID: null,
        formFieldsDisabled: true,
        specificationURL: null,
        moreInformation: null,
        clickAddMore: false,
        specification:{
          specification:null,
          url:null,
          file:null,
          more_information:null
        },
        optionsSpecifications:[{value:null,text:'--select one--'}]
      }
    },
    components: {},
    async mounted(){
      let vm = this;
      let specifications = await db.collection('specifications').orderBy('name','asc').get();
      specifications.forEach(c=>{vm.optionsSpecifications.push({value:c.id,text:c.data().name})});
    },
    methods:{

      async addMore(){
          const vm = this;
          vm.specification.url = vm.specificationURL;
          vm.specification.more_information = vm.moreInformation;
          await vm.saveCertificationDetails();
          await vm.$emit('cert-add', vm.specification.specification)
          vm.tempCertificateID = null;
          vm.formFieldsDisabled = true;
          vm.clickAddMore = true;
          vm.specificationURL = null;
          vm.moreInformation = null;
          vm.specification={
            specification:null,
            url:null,
            file:null,
            more_information:null
          };
          vm.error = null;
      },

      async saveCertificationDetails(){
        const vm = this;
        let data = {...vm.specification,productionContractID:vm.productionContractID,userID:vm.user.uid};
          if(!data.url) delete data.url;
          if(!data.file) delete data.file;
          if(vm.tempCertificateID == null){
            let ref = await db.collection('production_contract_certifications').doc();
            vm.tempCertificateID = ref.id;
            data.id = ref.id
            await db.collection('production_contract_certifications').doc(ref.id).set(data)
            vm.$emit('saved',{...data, id: ref.id}, vm.certificationIndex, true);
          } else {
            await db.collection('production_contract_certifications').doc(vm.tempCertificateID).set(data)
            vm.$emit('saved',{...data, id: vm.tempCertificateID}, vm.certificationIndex, true);
          }
      },
      async onChangesCertification(){
          const vm = this;
          vm.clickAddMore = false;
          if(vm.specification.specification !== null){
            vm.formFieldsDisabled = false;
            vm.saveCertificationDetails();
          } else {
            vm.formFieldsDisabled = true;
            vm.specification={
              specification:null,
              url:null,
              file:null,
              more_information:null
            };
            if(vm.tempCertificateID != null){
              let data = null;
              vm.$emit('saved',{...data, id: vm.tempCertificateID}, vm.certificationIndex, false);
              await db.collection('production_contract_certifications').doc(vm.tempCertificateID).delete();
              vm.saveCertificationDetails();
            }
          }
      },
      async deleteFile(){
        const vm = this;
        vm.deleting=true;
        vm.$emit('cert-del', vm.specification.specification)
        let storageRef = firestorage.ref(vm.specification.file.fullPath);
        await storageRef.delete();
        vm.specification.file=null;
        vm.deleting=false;
      },
      upload (file) {
        this.uploading = true;
        this.uploadTask = firestorage.ref('production_contract_cc/' + this.productionContractID + '/' + file.name).put(file)
      },
      selectFile () {
        if(!this.formFieldsDisabled){
          this.$refs.uploadInput.click(); 
        } else {
          return;
        }
      },
      detectFiles (e) {
        let fileList = e.target.files || e.dataTransfer.files;
        Array.from(Array(fileList.length).keys()).map(x => {
          this.upload(fileList[x])
        })
      },
    },
    watch: {
      specificationURL(){
        const vm = this;
        if(vm.clickAddMore){
          return;
        }

        if(vm.specificationURL !== "" && vm.specification.specification !== null){
          vm.specification.url = vm.specificationURL;
          vm.saveCertificationDetails();
        } else {
          if(vm.specification.specification === null){
            vm.specification.url = "";
            vm.saveCertificationDetails();
            vm.error = "Please select Certification Type."
            setTimeout(() => {
                vm.error = null;
            }, 5000);
          }
          return;
        }
      },
      moreInformation(){
        const vm = this;
        if(vm.clickAddMore){
          return;
        }
        if(vm.moreInformation !== "" && vm.specification.certification !== null){
          vm.specification.more_information = vm.moreInformation;
          vm.saveCertificationDetails();
        } else {
          if(vm.specification.certification === null){
            vm.specification.more_information = "";
            vm.saveCertificationDetails();
            vm.error = "Please select certification type."
            setTimeout(() => {
                vm.error = null;
            }, 5000);
          }
          return;
        }
      },
      selectedCert: function (selection) {
        console.log(selection)
      },
      uploadTask: function () {
        let vm = this;
        vm.uploadTask.on('state_changed', sp => {
          vm.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
        },
        null,
        async () => {
          let data = await vm.uploadTask.snapshot.ref.getMetadata();
          for(let key in data){
            if(data[key] === undefined) delete data[key];
          }
          vm.specification.file=data;
          vm.uploading=false;
          await this.saveCertificationDetails();
        })
      }
    }
  };
  </script>
<style>
    .add-pointer {
      cursor: pointer;
    }
    .remove-pointer {
      cursor: not-allowed;
    }
</style>
  