<template>
  <div class="wizard-container">
    <div class="card card-wizard active mb-0" data-color="primary">
      <form @submit.prevent>
        <div class="card-header text-center bg-primary">
          <slot name="header">
            <h3 class="card-title">{{ title }}</h3>
            <h3 class="description">{{ subTitle }}</h3>
          </slot>
          <div class="wizard-navigation">
            <ul class="nav nav-pills" role="tablist">
              <li
                v-for="(tab, index) in tabs"
                :key="tab.title"
                role="tab"
                :tabindex="tab.checked ? 0 : ''"
                :id="`step-${tab.tabId}`"
                :aria-controls="tab.tabId"
                :aria-disabled="tab.active"
                :aria-selected="tab.active"
                :ref="`tab-${index}`"
                class="nav-item wizard-tab-link"
                :style="linkWidth"
              >
                <a class="nav-link text-white font-weight-bold" @click="navigateToTab(index)"
                  :class="[
                    { 'disabled-wizard-link': !tab.checked },
                    { active: tab.active },
                    { checked: tab.checked }
                  ]"
                  data-toggle="tab"
                >
                  <tab-item-content :tab="tab"></tab-item-content>
                </a>
              </li>
            </ul>
            <div
              class="moving-tab"
              :class="{ 'error-link': activeTab.hasError }"
              v-if="activeTab"
              style="transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1); width: 100%;"
              :style="movingTabStyles"
            >
              <tab-item-content
                :tab="activeTab"
                :moving-tab="true"
              ></tab-item-content>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <slot :activeIndex="activeTabIndex" :activeTab="activeTab"> </slot>
          </div>
        </div>

        <div class="card-footer">
          <slot name="footer" :next-tab="nextTab" :prev-tab="prevTab">
            <div class="pull-right">
              <n-button
                v-if="activeTabIndex < tabCount - 1"
                wide
                @click.native="nextTab"
                class="btn-next btn-primary font-weight-bold default-button"
                :disabled="processing"
              >
                <i v-if="processing" class="fa fa-spinner mr-2 default-button"></i>{{ nextButtonText }}
              </n-button>
              <n-button
                v-else
                wide
                class="btn-primary font-weight-bold"
                @click.native="nextTab"
                :disabled="processing"
              >
                <i v-if="processing" class="fa fa-spinner mr-2"></i>{{finishButtonText}}
              </n-button>
            </div>

            <div class="pull-left">
              <n-button
                v-if="activeTabIndex > 0"
                wide
                @click.native="prevTab"
                class="btn-previous btn-secondary text-dark font-weight-bold"
                :disabled="processing"
              >
                <i v-if="processing" class="fa fa-spinner mr-2"></i>{{ prevButtonText }}
              </n-button>
            </div>
            <div class="clearfix"></div>
          </slot>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { throttle } from "./throttle";
import {mapState} from 'vuex'
export default {
  name: "simple-wizard",
  props: {
    startIndex: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: "Title"
    },
    subTitle: {
      type: String,
      default: "Subtitle"
    },
    prevButtonText: {
      type: String,
      default: "Previous"
    },
    nextButtonText: {
      type: String,
      default: "Next"
    },
    finishButtonText: {
      type: String,
      default: "Finish"
    },
    vertical: {
      type: Boolean
    }
  },
  components: {
    TabItemContent: {
      props: ["tab", "movingTab"],
      render(h) {
        return h("span", [this.tab.$slots.label || this.tab.label]);
      }
    }
  },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab
    };
  },
  data() {
    return {
      tabs: [],
      activeTabIndex: 0,
      tabLinkWidth: 0,
      tabLinkHeight: 50,
      processing:false
    };
  },
  computed: {
    ...mapState(['user']),
    tabCount() {
      return this.tabs.length;
    },
    linkWidth() {
      let width = 100;
      if (this.tabCount > 0) {
        width = 100 / this.tabCount;
      }
      if (this.vertical) {
        width = 100;
      }
      return { width: `${width}%` };
    },
    activeTab() {
      return this.tabs[this.activeTabIndex];
    },
    movingTabStyles() {
      let translateXValue = this.tabLinkWidth * this.activeTabIndex;
      let translateYValue = 0;
      if (this.vertical) {
        translateYValue = this.tabLinkHeight * this.activeTabIndex;
        translateXValue = 0;
      }
      let styles = {
        transform: `translate3d(${translateXValue}px, ${translateYValue}px, 0px)`
      };
      if (this.tabLinkWidth !== 0) {
        styles.width = `${this.tabLinkWidth}px`;
      }
      return styles;
    }
  },
  methods: {
    addTab(tab) {
      const index = this.$slots.default.indexOf(tab.$vnode);
      let tabTitle = tab.title || "";
      tab.tabId = `${tabTitle.replace(/ /g, "")}${index}`;
      if (!this.activeTab && index === 0) {
        tab.active = true;
        tab.checked = true;
      }
      if (this.activeTab === tab.name) {
        tab.active = true;
        tab.checked = true;
      }
      this.tabs.splice(index, 0, tab);
    },
    removeTab(tab) {
      const tabs = this.tabs;
      const index = tabs.indexOf(tab);
      if (index > -1) {
        tabs.splice(index, 1);
      }
    },
    validate(tab) {
      let tabToValidate = tab || this.activeTab;
      let beforeChange = tabToValidate.beforeChange;
      this.processing=true;
      if (beforeChange) {
        return Promise.resolve(beforeChange())
          .then(res => {
            this.activeTab.hasError = res ? false : true;
            this.processing=false;
            return res;
          })
          .catch(() => {
            this.processing=false;
            this.activeTab.hasError = true;
          });
      } else {
        this.processing=false;
        return Promise.resolve(true).then(() => {
          this.processing=false;
        });
      }
    },
    async nextTab() {
      let isValid = await this.validate();
      if (isValid && this.activeTabIndex < this.tabCount - 1) {
        this.activeTabIndex++;
      }
      return isValid;
    },
    prevTab() {
      this.processing=true;
      this.activeTabIndex--;
      this.processing=false;
    },
    async navigateToTab(index) {
      if (this.tabs[index].checked) {
        // recursively validate each tab
        if (index > this.activeTabIndex) {
          let valid = await this.nextTab();
          if (valid) {
            this.navigateToTab(index);
          }
        } else {
          this.activeTabIndex = index;
        }
      }
    },
    onResize() {
      let tabLinks = document.getElementsByClassName("wizard-tab-link");
      if (tabLinks.length > 0 && this.tabCount > 0) {
        let { clientWidth, clientHeight } = tabLinks[0];
        this.tabLinkWidth = clientWidth;
        this.tabLinkHeight = clientHeight;
      }
    }
  },
  mounted() {
    this.activeTabIndex = this.startIndex;
    this.$nextTick(() => {
      const activeTab = this.tabs[this.activeTabIndex];
      if(activeTab){
        activeTab.active = true;
        activeTab.checked = true;
      }
      this.onResize();
    });
    
    if(this.user.about){
      setTimeout(() => {
        this.nextButtonText='Save';
      }, 1000)
    }


    window.addEventListener(
      "resize",
      () => {
        throttle(this.onResize, 40);
      },
      false
    );
  },
  watch: {
    activeTabIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        let oldTab = this.tabs[oldValue];
        let newTab = this.tabs[newValue];
        oldTab.active = false;
        newTab.active = true;

        if (!newTab.checked) {
          newTab.checked = true;
        }
        this.$emit("tab-change", oldTab, newTab);
        this.$emit("update:startIndex", newValue);
      }
    }
  }
};
</script>
<style lang="scss">
.tab-content {
  display: flex; 
  .tab-pane {
    display: block;
    animation: fadeIn 0.5s;
    width: 100%;
  }
}

.wizard-navigation .nav-link {
  &.active,
  &.checked {
    cursor: pointer;
  }
}

.disabled-wizard-link {
  cursor: not-allowed;
}
</style>
